export interface TeamFilterProps {
    teamName?: string; //*
    teamLocation?: string; //*
    managerName?: string; //*
    managerEmail?: string; //*
    managerPhone?: string; //*
    managerPassword?: string;
    region?: string;
    district?: string;
    county?: string;
    subCounty?: string;
    parish?: string;
    acknowledgementForm?: any;
  }
  
  const defaultTeamFilterProps: TeamFilterProps = {
    teamName: "", // Default teamName value
    teamLocation: "", 
    managerName: "",
    managerEmail: "",
    managerPhone: "",
    managerPassword: "",
    region: "",
    district: "",
    county: "",
    subCounty: "",
    parish: "",
    acknowledgementForm: null
  };
  
  export class TeamFilter {
    teamName?: string;
    teamLocation?: string;
    managerName?: string;
    managerEmail?: string;
    managerPhone?: string;
    managerPassword?: string;
    region?: string;
    district?: string;
    county?: string;
    subCounty?: string;
    parish?: string;
    acknowledgementForm?: any;
  
    constructor(
      data: TeamFilterProps = defaultTeamFilterProps
    ) {
      this.teamName = data.teamName || "";
      this.teamLocation = data.teamLocation || "";
      this.managerName = data.managerName || "";
      this.managerEmail = data.managerEmail || "";
      this.managerPhone = data.managerPhone || "";
      this.managerPassword = data.managerPassword || "";
      this.region = data.region || "";
      this.district = data.district || "";
      this.county = data.county || "";
      this.subCounty = data.subCounty || "";
      this.parish = data.parish || "";
      this.acknowledgementForm = data.acknowledgementForm || null;
    }
  }