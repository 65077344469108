import CartonDetailsLayout from "component/carton/details";
import { RouteConstant } from "navigation/constant";
import { Navigate, useParams } from "react-router-dom";

const CartonDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.ALL_CARTONS} />;

    return (
        <>
            <CartonDetailsLayout id={params.id} />
        </>
    )
}

export default CartonDetails;