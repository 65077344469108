import { SimSwapDetailsLayout } from "component/sim-swap/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const SimSwapDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.SIMSWAP} />;

    return (
        <MainContainer>
            <SimSwapDetailsLayout id={params.id} />
        </MainContainer>
    );
}