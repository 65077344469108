import { Box, Card, Typography } from "@mui/material";
import { HandlerDetailsInterface } from "_interfaces/handler";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { useNavigate } from "react-router-dom";
import UserActivation from "component/_common/userActivation";

interface Props {
  DATA: HandlerDetailsInterface,
  UserId: string | null,
}

const HandlerDetailsTable: React.FC<Props> = ({ DATA, UserId }) => {
  const navigate = useNavigate();

  const rows: {
    field: keyof NonNullable<HandlerDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "name", headerName: "Handler Name" },
      { field: "email", headerName: "Email" },
      { field: "phone", headerName: "Phone" },
      { field: "role", headerName: "Role" },
      { field: "msisdn", headerName: "MSISDN" },
      {
        field: "agentName", headerName: "Agent Name", renderCell: (v: string, row?: HandlerDetailsInterface) => <Box
          sx={{ textDecoration: "underline", color: "#0808ce", cursor: "pointer" }}
          onClick={() => {
            DATA?.agentId &&
              navigate(
                `${RouteConstant.AGENTS}/${DATA?.agentId}`
              )
          }} className="tw-capitalize">{v}</Box>
      },
      { field: "date", headerName: "Time", renderCell: (v: string) => moment(v).format("MMM D YYYY h:mm A"), },
      { field: "isActive", headerName: "Status", renderCell: (v: string) => (<p>{v ? "Active" : "InActive"}</p>), },
      { field: "isApprove", headerName: "Approve Status", renderCell: (v: string) => (<p>{v ? "Approve" : "NotApproves"}</p>), },
    ];

  return (
    <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box className="tw-flex tw-justify-between tw-items-center">
        <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
          {"Handler Details"}
        </Typography>
        {UserId && <UserActivation ID={UserId} />}
      </Box>
      <DetailsBox
        data={DATA}
        rows={rows}
      />
    </Card>
  )
}

export default HandlerDetailsTable;
