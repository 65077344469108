import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import {
  ALLOWED_COUPON_APPLY_ON,
  ALLOWED_COUPON_CATEGORY,
  ALLOWED_COUPON_DISCOUNT_TYPE,
  CouponCreateProps,
  CouponUpdateProps,
} from "_interfaces/functions/http-requests/coupon";
import { CouponModel } from "_models/data/coupon/data.coupon.model";
import { CouponErrorModel } from "_models/error/coupon/error.coupon.model";
import axios from "axios";
import DatePicker from "component/_common/forms/date";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import {
  filterNonNullValues,
  generateAutocompleteOptions,
} from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import {
  CreateCoupon,
  GetCouponById,
  UpdateCoupon,
} from "functions/http-requests/coupon";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validateCoupon } from "./formValidator";

interface InputListProps extends FormInputProps {
  name:
    | "category"
    | "applyOn"
    | "couponCode"
    | "validFrom"
    | "validUntil"
    | "maxUse"
    | "discountType"
    | "discountValue"
    | "minPurchaseAmount"
    | "maxDiscountAmount"
    | "isOpen"
    | "info"
    | "title";
}

interface Props {
  editId?: string | null;
  handleRefresh: () => void;
  onClose: () => void;
}

const CouponForm: React.FC<Props> = ({ editId, handleRefresh, onClose }) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<CouponModel>(new CouponModel());
  const [errors, setErrors] = useState<CouponErrorModel>(
    new CouponErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: name === "couponCode" ? value?.toUpperCase() : value,
      }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: CouponCreateProps) => {
    Dispatch(toggleLoading(true));
    CreateCoupon({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA, PARAMS }: CouponUpdateProps) => {
    Dispatch(toggleLoading(true));
    UpdateCoupon({ DATA, PARAMS })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateCoupon(state, !!editId);

    if (ValidateStep?.valid) {
      let DATA: CouponCreateProps["DATA"] = {
        category: state?.category || undefined,
        applyOn: state?.applyOn || undefined,
        couponCode: state?.couponCode || undefined,
        validFrom: state?.validFrom
          ? moment(state.validFrom).format("YYYY/MM/DD")
          : undefined,
        validUntil: state?.validUntil
          ? moment(state.validUntil).format("YYYY/MM/DD")
          : undefined,
        maxUse: state?.maxUse ? Number(state.maxUse) : undefined,
        discountType: state?.discountType || undefined,
        discountValue: state?.discountValue
          ? Number(state.discountValue)
          : undefined,
        minPurchaseAmount: state?.minPurchaseAmount
          ? Number(state.minPurchaseAmount)
          : undefined,
        maxDiscountAmount: state?.maxDiscountAmount
          ? Number(state.maxDiscountAmount)
          : undefined,
        isOpen: state?.isOpen || false,
        info: state?.info || undefined,
        title: state?.title || undefined,
      };

      DATA = filterNonNullValues(DATA);
      if (editId) {
        handleUpdate({
          DATA: { ...DATA, status: state?.status },
          PARAMS: {
            id: editId,
          },
        });
      } else handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetCouponById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;

          setState({
            category: DATA?.category || "",
            applyOn: DATA?.applyOn || "",
            couponCode: DATA?.couponCode?.toUpperCase() || "",
            validFrom: DATA?.validFrom || null,
            validUntil: DATA?.validUntil || null,
            maxUse: DATA?.maxUse || "",
            discountType: DATA?.discountType || "",
            discountValue: DATA?.discountValue || "",
            minPurchaseAmount: DATA?.minPurchaseAmount || "",
            maxDiscountAmount: DATA?.maxDiscountAmount || "",
            isOpen: DATA?.isOpen || false,
            info: DATA?.info || "",
            title: DATA?.title || "",
            status: DATA?.status || "",
          });
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new CouponModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "title",
        label: "Title",
        placeholder: "Enter title",
        grid: 12,
      },
      {
        type: "text",
        name: "info",
        label: "Info",
        placeholder: "Enter Info",
        multiline: true,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(ALLOWED_COUPON_CATEGORY),
        multiple: false,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "applyOn",
        label: "Apply On",
        placeholder: "Select Apply On",
        options: generateAutocompleteOptions(ALLOWED_COUPON_APPLY_ON),
        multiple: false,
        grid: 6,
      },
      {
        type: "text",
        name: "couponCode",
        label: "Coupon Code",
        placeholder: "Enter Coupon Code",
        grid: 6,
        disabled: !!editId,
      },
      {
        type: "number",
        name: "maxUse",
        label: "Max Use",
        placeholder: "Enter Max Use",
        grid: 6,
      },
      {
        type: "date",
        name: "validFrom",
        label: "Valid From",
        placeholder: "Enter Valid From",
        grid: 6,
      },
      {
        type: "date",
        name: "validUntil",
        label: "Valid Until",
        placeholder: "Enter Valid Until",
        grid: 6,
      },

      {
        type: "autoComplete",
        name: "discountType",
        label: "Discount Type",
        placeholder: "Select Discount Type",
        options: generateAutocompleteOptions(ALLOWED_COUPON_DISCOUNT_TYPE),
        multiple: false,
        grid: 6,
      },
      {
        type: "number",
        name: "discountValue",
        label: "Discount Value",
        placeholder: "Enter Discount Value",
        grid: 6,
      },
      {
        type: "number",
        name: "minPurchaseAmount",
        label: "Min Purchase Amount",
        placeholder: "Enter Min Purchase Amount",
        grid: 6,
      },
      {
        type: "number",
        name: "maxDiscountAmount",
        label: "Max Discount Amount",
        placeholder: "Enter Max Discount Amount",
        grid: 6,
      },
      {
        type: "checkbox",
        name: "isOpen",
        label: "Open for All",
        grid: 6,
      },
    ],
    [
      editId,
      ALLOWED_COUPON_CATEGORY,
      ALLOWED_COUPON_APPLY_ON,
      ALLOWED_COUPON_DISCOUNT_TYPE,
    ]
  );

  return (
    <>
      <Box component="form" id="crete_edit_coupon_form" onSubmit={handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                multiline,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "text" || type === "email" || type === "number" ? (
                    <Grid item xs={12} sm={6} md={grid || 12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof CouponModel]}
                        errorText={errors[name as keyof CouponErrorModel]}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                        InputProps={InputProps}
                        multiline={multiline}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12} sm={6} md={grid || 12}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options.length &&
                          state?.[name as keyof CouponModel]
                        }
                        errorText={errors[name as keyof CouponErrorModel]}
                        onFocus={handleFocus}
                        multiple={multiple}
                      />
                    </Grid>
                  ) : type === "checkbox" ? (
                    <Grid item xs={12} sm={6} md={grid || 12}>
                      <InputCheckbox
                        name={name}
                        label={label}
                        value={state?.[name as keyof CouponModel] as boolean}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : type === "date" ? (
                    <Grid item xs={12} md={grid || 6}>
                      <DatePicker
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        value={state[name as keyof CouponModel]}
                        errorText={errors[name as keyof CouponErrorModel]}
                        onChange={(value) => {
                          setState((prev) => ({
                            ...prev,
                            [name]: value,
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            [name]: "",
                          }));
                        }}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New Coupon"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CouponForm;
