import Visibility from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import InfluencerTableInterface from "_interfaces/influencer/influencerTable";
import { toTitleCase } from "functions/helper";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React from "react";
import { useNavigate } from "react-router-dom";

const InfluencerTable: React.FC<InfluencerTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.value}</Box>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => toTitleCase(params.value),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "category",
      headerName: "category",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => toTitleCase(params.value),
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <IsActiveCell status={params?.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(`${RouteConstant.INFLUENCER}/${params?.row?.id}`)
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="View Details">
                  <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <CustomDataGrid
        loading={loading}
        columns={columns}
        rows={data || []}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </>
  );
};

export default InfluencerTable;
