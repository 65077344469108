import { HTTP } from "functions/http";

import {
  AppliedCouponListProps,
  GetAllInfluencerProps,
  InfluencerCreateProps,
  InfluencerGenerateCouponLinkProps,
  InfluencerUpdateProps,
} from "_interfaces/functions/http-requests/influencer";
import {
  CREATE_INFLUENCER,
  GET_ALL_INFLUENCER,
  GET_APPLIED_COUPON_LIST_BY_INFLUENCERID,
  GET_INFLUENCER_BY_ID,
  INFLUENCER_DELETE_COUPON_LINK,
  INFLUENCER_GENERATE_COUPON_LINK,
  UPDATE_INFLUENCER,
} from "config/endpoint";

export const CreateInfluencer = ({ DATA }: InfluencerCreateProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_INFLUENCER,
    Data: DATA,
  });
};

export const UpdateInfluencer = ({ DATA, PARAMS }: InfluencerUpdateProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_INFLUENCER(PARAMS.id),
    Data: DATA,
  });
};

export const GetAllInfluencer = ({ DATA }: GetAllInfluencerProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_INFLUENCER,
    Data: DATA,
  });
};

export const GetInfluencerById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_INFLUENCER_BY_ID(ID),
  });
};

export const InfluencerGenerateCouponLink = ({
  DATA,
  PARAMS,
}: InfluencerGenerateCouponLinkProps) => {
  return HTTP({
    Method: "POST",
    Url: INFLUENCER_GENERATE_COUPON_LINK(PARAMS?.id),
    Data: DATA,
  });
};

export const InfluencerDeleteCouponLink = (ID: string) => {
  return HTTP({
    Method: "POST",
    Url: INFLUENCER_DELETE_COUPON_LINK(ID),
  });
};

export const GetAppliedCouponListInfluencerById = (ID: string, DATA: AppliedCouponListProps["DATA"]) => {
  return HTTP({
    Method: "POST",
    Url: GET_APPLIED_COUPON_LIST_BY_INFLUENCERID(ID),
    Data: DATA
  });
};
