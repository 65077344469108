import RemoveIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import useCopyToClipboard from "functions/hooks/useCopyToClipboard";
import { HTTP_ERROR } from "functions/http";
import { InfluencerDeleteCouponLink } from "functions/http-requests/influencer";
import CustomDataGrid from "parts/customDataGrid";
import DeleteDialog from "parts/customDialog/deleteDialog";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import InfluencerDetailsInterface from "_interfaces/influencer/details";

interface Props {
  data: InfluencerDetailsInterface["couponLinks"];
  handleRefresh: () => void;
}
const InfluencerCouponLinksTable: React.FC<Props> = ({
  data,
  handleRefresh,
}) => {
  const Dispatch = useDispatch();
  const { copied, copy } = useCopyToClipboard();

  const [deleteId, setDeleteId] = useState<string>("");

  const handleCloseDeleteDialog = () => setDeleteId("");

  const handleDelete = () => {
    if (deleteId) {
      Dispatch(toggleLoading(true));
      InfluencerDeleteCouponLink(deleteId)
        .then((res) => {
          const data = res?.data;
          Dispatch(setAlert({ type: data?.level, message: data?.message }));
          if (data?.statusCode === AppStatusCode.api_success) {
            handleCloseDeleteDialog();
            handleRefresh();
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    }
  };

  const columns: GridColDef[] = [
    {
      field: "linkID",
      headerName: "Link ID",
      // flex: 1,
      minWidth: 150,
    },
    {
      field: "link",
      headerName: "Link",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() => {
                  if (params?.row?.id && params?.row?.link) {
                    copy(params.row.link);
                  }
                }}
              >
                <Tooltip title="Copy">
                  <ContentCopyIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  if (params?.row?.id) {
                    setDeleteId(params?.row?.id);
                  }
                }}
              >
                <Tooltip title="View Details">
                  <RemoveIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Card
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: 3,
        }}
      >
        <Box className="tw-flex tw-justify-between tw-items-center">
          <Typography
            color="text.primary"
            className="tw-text-[18px]"
            sx={{ fontWeight: "bold" }}
          >
            {"Coupon Links"}
          </Typography>
        </Box>
        <CustomDataGrid
          columns={columns}
          rows={data || []}
          paginationMode="client"
          hideFooter
        />
      </Card>

      <DeleteDialog
        open={!!deleteId}
        onClose={handleCloseDeleteDialog}
        content="Coupon link will be deleted permanently!"
        onDelete={handleDelete}
      />
    </>
  );
};

export default InfluencerCouponLinksTable;
