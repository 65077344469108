import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import {
  ALLOWED_COUPON_APPLY_ON,
  ALLOWED_COUPON_CATEGORY,
  ALLOWED_COUPON_DISCOUNT_TYPE,
  ALLOWED_COUPON_STATUS,
} from "_interfaces/functions/http-requests/coupon";
import { CouponFilterModel } from "_models/data/coupon/data.coupon.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { generateAutocompleteOptions } from "functions/helper";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";

interface InputListProps extends FormInputProps {
  name: "status" | "category" | "applyOn" | "discountType" | "maxUse";
}
interface Props extends SideDrawerProps {
  filters: CouponFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<CouponFilterModel>>;
}

const CouponFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters((prev) => ({ ...prev, [name]: checked }));
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions(ALLOWED_COUPON_STATUS),
        grid: 12,
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(ALLOWED_COUPON_CATEGORY),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "applyOn",
        label: "Apply On",
        placeholder: "Select Apply On",
        options: generateAutocompleteOptions(ALLOWED_COUPON_APPLY_ON),
        multiple: false,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "discountType",
        label: "Discount Type",
        placeholder: "Select Discount Type",
        options: generateAutocompleteOptions(ALLOWED_COUPON_DISCOUNT_TYPE),
        multiple: false,
        grid: 12,
      },
      {
        type: "text",
        name: "maxUse",
        label: "Max Use",
        placeholder: "Max Use",
        grid: 12,
      },
    ],
    []
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_coupon">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, grid, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={"off"}
                          value={filters[name as keyof CouponFilterModel]}
                          onChange={handleChange}
                        />
                      </Grid>
                    ) : type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[name as keyof CouponFilterModel]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default CouponFilter;
