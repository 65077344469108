import {
  AgentCreateProps,
  AgentGetAllByDistributorProps,
  AgentGetAllProps,
  AgentUpdateProps,
  AgentUploadDocsProps,
  GetAllHandlersByAgentProps,
} from "_interfaces/functions/http-requests/agents";
import {
  AGENT_APPROVE,
  AGENT_CREATE,
  AGENT_GET_ALL_BY_DISTRIBUTOR,
  AGENT_GET_BY_ID,
  AGENT_GET_BY_MSISDN,
  AGENT_GET_BY_USER_ID,
  AGENT_UPDATE,
  AGENT_UPLOAD_DOCS,
  COUNT_BY_AGENT,
  GET_ALL_AGENT,
  GET_HANDLER_BY_AGENT,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const AgentGetAllByDistributor = ({
  DATA,
}: AgentGetAllByDistributorProps) => {
  return HTTP({
    Method: "Post",
    Url: AGENT_GET_ALL_BY_DISTRIBUTOR,
    Data: DATA,
  });
};

export const AgentGetById = (ID: string) => {
  return HTTP({
    Method: "Get",
    Url: AGENT_GET_BY_ID(ID),
  });
};

export const AgentGetByUserId = (ID: string) => {
  return HTTP({
    Method: "Get",
    Url: AGENT_GET_BY_USER_ID(ID),
  });
};

export const AgentGetByMSISDN = (MSISDN: string) => {
  return HTTP({
    Method: "Get",
    Url: AGENT_GET_BY_MSISDN(MSISDN),
  });
};

export const AgentCreate = ({ DATA }: AgentCreateProps) => {
  return HTTP({
    Method: "POST",
    Url: AGENT_CREATE,
    Data: DATA,
  });
};

export const AgentUpdate = ({ DATA, ID }: AgentUpdateProps) => {
  return HTTP({
    Method: "POST",
    Url: AGENT_UPDATE(ID),
    Data: DATA,
  });
};

export const AgentUploadDocs = ({ DATA, ID }: AgentUploadDocsProps) => {
  return HTTP({
    Method: "POST",
    Url: AGENT_UPLOAD_DOCS(ID),
    Data: DATA,
    isFormData: true,
  });
};

export const AgentApprove = (ID: string) => {
  return HTTP({
    Method: "Put",
    Url: AGENT_APPROVE(ID),
  });
};

export const GetAllAgent = ({ DATA }: AgentGetAllProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_AGENT,
    Data: DATA,
  });
};
export const GetAllAgentList = () => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_AGENT,
  });
};

export const CountByAgent = (ID: string) => {
  return HTTP({
    Method: "Get",
    Url: COUNT_BY_AGENT(ID),
  });
};
export const GetAllHandlersByAgent = ({DATA}:GetAllHandlersByAgentProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_HANDLER_BY_AGENT,
    Data: DATA,
  });
};