import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  FormInputProps,
} from "_interfaces/_common/forms";
import axios from "axios";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import {
  filterNonNullValues,
} from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { UpdatePhoneBookingProps } from "_interfaces/functions/http-requests/bookings";
import { GetPhoneBookingDetails, UpdatePhoneBooking } from "functions/http-requests/bookings";
import { validatePhoneBooking } from "./formValidator";
import { UpdateBookingModel } from "_models/data/phoneBooking/data.phone-booking.model";
import { UpdateBookingErrorModel } from "_models/error/phoneBooking/phoneBookingErrorModel";

interface InputListProps extends FormInputProps {
  name:
  | "email"
  | "name"
  | "nationalIdIdNumber"
  | "passportNumber"
  | "refugeeDocumentNumber";
}

interface Props {
  editId?: string | null;
  handleRefresh: () => void;
  onClose: () => void;
}

const PhoneBookingForm: React.FC<Props> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<UpdateBookingModel>(new UpdateBookingModel());
  const [errors, setErrors] = useState<UpdateBookingErrorModel>(
    new UpdateBookingErrorModel()
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
          (e: { value: string | number; id: string | number }) =>
            e?.value || e?.id
        )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleUpdate = ({ DATA }: UpdatePhoneBookingProps) => {
    if (!editId) return
    Dispatch(toggleLoading(true));
    UpdatePhoneBooking(editId, DATA)
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validatePhoneBooking(state, !!editId);

    if (ValidateStep?.valid) {
      let DATA: UpdatePhoneBookingProps["DATA"] = {
        email: state?.email || undefined,
        name: state?.name || undefined,
        nationalIdIdNumber: state?.nationalIdIdNumber || undefined,
        passportNumber: state.passportNumber || undefined,
        refugeeDocumentNumber: state?.refugeeDocumentNumber || undefined,
      };
      DATA = filterNonNullValues(DATA);
      if (editId) {
        handleUpdate({
          DATA: DATA
        });
      };
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetPhoneBookingDetails(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;
          setState({
            email: DATA?.email || "",
            name: DATA?.name || "",
            nationalIdIdNumber: DATA?.nationalIdIdNumber || null,
            passportNumber: DATA?.passportNumber || null,
            refugeeDocumentNumber: DATA?.refugeeDocumentNumber || null
          });
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new UpdateBookingModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);


  const inputList: any[] = useMemo(
    () => [
      {
        type: "text",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        enabled: true,
        grid: 6,
      },
      {
        type: "email",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "nationalIdIdNumber",
        label: "National Id",
        placeholder: "Enter National Id",
        enabled: state?.nationalIdIdNumber !== null ? true : false,
        grid: 6,
      },
      {
        type: "text",
        name: "passportNumber",
        label: "Passport Number",
        placeholder: "Enter Passport Number",
        enabled: state?.passportNumber !== null ? true : false,
        grid: 6,
      },
      {
        type: "text",
        name: "refugeeDocumentNumber",
        label: "Refugee Document Number",
        placeholder: "Enter Refugee Document Number",
        enabled: state?.refugeeDocumentNumber !== null ? true : false,
        grid: 6,
      },
    ],
    [
      editId, state
    ]
  );

  return (
    <>
      <Box
        component="form"
        id="crete_edit_influencer_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                multiline,
                enabled,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                      type === "email" ||
                      type === "number" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof UpdateBookingModel]}
                          errorText={errors[name as keyof UpdateBookingErrorModel]}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          InputProps={InputProps}
                          multiline={multiline}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof UpdateBookingModel]
                          }
                          errorText={errors[name as keyof UpdateBookingErrorModel]}
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {"Save Changes"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PhoneBookingForm;
