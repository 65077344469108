import { WalletTransactionFilterProps } from "_interfaces/functions/http-requests/wallet-transaction";
import { WalletPaymentFilterProps } from "_interfaces/functions/http-requests/walletPayments";
import { WALLET_PAYMENT_DETAILS, WALLET_PAYMENT_LIST, WALLET_TRANSACTION_DETAILS, WALLET_TRANSACTION_LIST } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetWalletTransactionList = ({ DATA }: WalletTransactionFilterProps) => {
    return HTTP({
        Method: "POST",
        Url: WALLET_TRANSACTION_LIST,
        Data: DATA
    });
};
export const GetWalletTransactionDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: WALLET_TRANSACTION_DETAILS(ID),
    });
};