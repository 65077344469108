export type CouponCategoryProps = "influencer" | "admin";
export type CouponApplyOnProps = "simBooking" | "recharge";
export type CouponDiscountTypeProps = "percentage" | "fixedAmount";
export type CouponStatusProps = "active" | "paused";

export const ALLOWED_COUPON_CATEGORY = ["influencer", "admin"];
export const ALLOWED_COUPON_APPLY_ON = ["simBooking", "recharge"];
export const ALLOWED_COUPON_DISCOUNT_TYPE = ["percentage", "fixedAmount"];
export const ALLOWED_COUPON_STATUS = ["active", "paused"];

export type CouponCreateProps = {
  DATA: {
    category?: CouponCategoryProps; //*
    applyOn?: CouponApplyOnProps; //*
    couponCode?: string; //min 4 max 10 Alpha numeric uppercase
    validFrom?: string; //*"YYYY/MM/DD"
    validUntil?: string; //*"YYYY/MM/DD"
    maxUse?: number; //
    discountType?: CouponDiscountTypeProps; // *
    discountValue?: number; // *
    minPurchaseAmount?: number;
    maxDiscountAmount?: number;
    isOpen?: boolean;
    info?: string;
    title?: string;
  };
};

export type CouponUpdateProps = {
  DATA: CouponCreateProps["DATA"] & {
    status?: string;
  };
  PARAMS: {
    id: string;
  };
};

export type GetAllCouponProps = {
  DATA: {
    searchKey?: string;
    status?: CouponStatusProps;
    pageSize: number;
    pageNumber: number;
    category?: CouponCategoryProps;
    applyOn?: CouponApplyOnProps;
    discountType?: CouponDiscountTypeProps;
    maxUse?: number;
  };
};
