export type PackageDataProps = {
  isUnlimited?: boolean;
  quantity?: string | null;
  dataUnit?: string | null;
};
export type CreatePackageBundleProps = {
  DATA: {
    packageName?: string;
    isValidityUnlimited?: boolean;
    validity?: string;
    dataVolume?: PackageDataProps;
    dataPerDay?: PackageDataProps;
    allNetMinutes?: string;
    onNetMinutes?: string;
    offNetMinutes?: string;
    allNetSMS?: string;
    onNetSMS?: string;
    offNetSMS?: string;
    internationalCallMinutes?: string;
    packageFor?: PackageForProps;
    packageID?: string;
    retailPrice?: string;
    description?: string;
    category?: PackageCategoryProps;
    discountFor?: PackageDiscountForProps;
    discountPercent?: string;
    packageType?: PackageTypeProps[];
    vat?: string;
  };
};

export type PackageForProps = "normal" | "premium" | "tourist";
export type PackageCategoryProps = "normal" | "silver" | "gold" | "bronze";
export type PackageDiscountForProps = "agent" | "whole_seller";
export type PackageTypeProps = "data" | "call" | "sms" | "combo";

export const ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor = [
  "normal",
  "premium",
  "tourist",
];

export const ALLOWED_PACKAGE_BUNDLE_FIELDS_category = [
  "normal",
  "bronze",
  "silver",
  "gold",
];

export const ALLOWED_PACKAGE_BUNDLE_FIELDS_discountFor = [
  "agent",
  "whole_seller",
];

export const ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType = [
  "data",
  "call",
  "sms",
  "combo",
];

export type UpdatePackageBundleProps = {
  DATA: CreatePackageBundleProps["DATA"] & {
    id?: string;
    isActive?: boolean;
  };
};

export type GetPackageBundleProps = {
  DATA: {
    searchKey?: string;
    isActive?: boolean;
    pageSize: number;
    pageNumber: number;
    category?: PackageCategoryProps;
    packageType?: PackageTypeProps;
    packageFor?: PackageForProps;
    packageValidity?: "7" | "30" | "365";
  };
};
