import { TransactionDetailsLayout } from "component/transactions/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const TransactionDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.TRANSACTIONS} />;

    return (
        <MainContainer>
            <TransactionDetailsLayout id={params.id} />
        </MainContainer>
    );
}