import { GridPaginationModel } from "@mui/x-data-grid";
import PhoneBookingTableInterface from "_interfaces/functions/http-requests/bookings";
import { RechargeFilterProps } from "_interfaces/functions/http-requests/recharge";
import { RechargeFilterModel } from "_models/data/recharge/data.recharge-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetRechargeList } from "functions/http-requests/recharge";
import { useRef, useState, useEffect } from "react";
import RechargeTable from "./rechargeTable";
import { Grid, Box } from "@mui/material";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import RechargeTableInterface from "_interfaces/recharge";
import RechargeFilter from "./rechargeFilter";

export const RechargeLayout = () => {
    const isInitialRender = useRef(true);
    const [searchKey, setSearchKey] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const handleClearSearch = () => setSearchKey("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<RechargeTableInterface["data"]>(
        []
    );
    const [filters, setFilters] = useState<RechargeFilterModel>(
        new RechargeFilterModel()
    );
    const [count, setCount] = useState<number>(0);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: RechargeFilterProps["DATA"] = {
                searchKey: searchKey,
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                status: filters.status || "",
                rechargeType: filters.rechargeType || "",
                rechargeBy: filters.rechargeBy || '',
                fromDate: filters.fromDate,
                toDate: filters.toDate
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetRechargeList({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            MSISDN: item?.MSISDN,
                            amount: item?.amount,
                            time: item?.time,
                            isRechargeDone: item?.isRechargeDone,
                            status: item?.status,
                            rechargeBy: item?.rechargeBy,
                            rechargeType: item?.rechargeType,
                            passportNumber: item?.passportNumber,
                            createdAt: item?.createdAt,
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [refresh, paginationModel, filters, searchKey]);
    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                <Box sx={{ flex: "1 1 auto" }}>
                    <SectionSearch
                        name="search_regional_distributor"
                        value={searchKey}
                        onChange={handleSearch}
                        onClear={handleClearSearch}
                        onSubmit={handleSubmitSearch}
                    />
                </Box>
                <FilterButton onClick={() => setOpenFilter(true)} />
            </Grid>
        </Grid>
        <RechargeTable
            data={data}
            loading={dataLoading}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
        />
        <RechargeFilter
            open={openFilter}
            onClose={toggleFilter}
            filters={filters}
            setFilters={setFilters}
        />
    </>
    )
}