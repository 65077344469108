interface Props {
  id?: string;
  promotionType?: string;
  promotionConsumer?: string;
  amount?: string;
  promotionApplyOn?: string;
  rechargeType?: string;
  rechargeDataUnit?: string;
  buildBundleData_onNetVoice?: string;
  buildBundleData_offNetVoice?: string;
  buildBundleData_dataMb?: string;
  buildBundleData_smsOffNet?: string;
  buildBundleData_intervalId?: string;
  maxUse?: string;
  showInPage?: string;
  UI?: string;
}

export class PromotionErrorModel {
  id?: string;
  promotionType?: string;
  promotionConsumer?: string;
  amount?: string;
  promotionApplyOn?: string;
  rechargeType?: string;
  rechargeDataUnit?: string;
  buildBundleData_onNetVoice?: string;
  buildBundleData_offNetVoice?: string;
  buildBundleData_dataMb?: string;
  buildBundleData_smsOffNet?: string;
  buildBundleData_intervalId?: string;
  maxUse?: string;
  showInPage?: string;
  UI?: string;

  constructor(data: Props = {}) {
    this.id = data.id || "";
    this.promotionType = data.promotionType || "";
    this.promotionConsumer = data.promotionConsumer || "";
    this.amount = data.amount || "";
    this.promotionApplyOn = data.promotionApplyOn || "";
    this.rechargeType = data.rechargeType || "";
    this.rechargeDataUnit = data.rechargeDataUnit || "";
    this.buildBundleData_onNetVoice = data.buildBundleData_onNetVoice || "";
    this.buildBundleData_offNetVoice = data.buildBundleData_offNetVoice || "";
    this.buildBundleData_dataMb = data.buildBundleData_dataMb || "";
    this.buildBundleData_smsOffNet = data.buildBundleData_smsOffNet || "";
    this.buildBundleData_intervalId = data.buildBundleData_intervalId || "";
    this.maxUse = data.maxUse || "";
    this.showInPage = data.showInPage || "";
    this.UI = data.UI || "";
  }
}
