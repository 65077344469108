import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";
import { BuildBundleInterface, PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { AirtelPayload, MomoPayload, PaymentDetailsInterface } from "_interfaces/payment";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import { SimSwapDetailsInterface, SimSwapDocInterface } from "_interfaces/sim-swap";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import { GetSimSwapDoc } from "functions/http-requests/sim-swap";
import { AppStatusCode } from "config/appStatusCode";
import axios from "axios";
import { setAlert } from "state/reducers/alert";
import { HTTP_ERROR } from "functions/http";
import CustomerDocs from "component/customer-management/details/customerDocs";

interface Props {
  data: SimSwapDetailsInterface | null;
}

const SimSwapDetailsTable: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const getStatusColor = (v: string) => {
    if (v === 'pending') return 'orange';
    if (v === 'success') return 'green  ';
    if (v === 'rejected') return 'red';
    return 'green';
  }
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [openCustomerDoc, setOpenCustomerDoc] = useState<boolean>(false);
  const toggleCustomerDoc = () => {
    setOpenCustomerDoc(!openCustomerDoc);
  };
  const [simSwapDocs, setSimSwapDocs] = useState<SimSwapDocInterface>();

  let handleFetchCustomerDoc = () => {
    Dispatch(toggleLoading(true));
    GetSimSwapDoc(data?.id as string)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA = data?.data;

          // CITIZEN
          const policeLetter = DATA?.policeLetter || null;
          const customerPhoto =
            DATA?.customerPhoto || null;

          setSimSwapDocs((prev) => ({
            ...prev,
            policeLetter,
            customerPhoto
          }));
        } else {
          // setState(null);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        }
      })
      .finally(() => {
        setIsLoaded(true);
        Dispatch(toggleLoading(false));
      });
  };

  const rows: {
    field: keyof NonNullable<
      SimSwapDetailsInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "MSISDN", headerName: "MSISDN", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      {
        field: "customer", headerName: "Customer",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.CUSTOMER_MANAGEMENT}/${data?.customer}`)} > View Customer Details</Button >
      },
      { field: "oldICCID", headerName: "Old ICCID" },
      { field: "ICCID", headerName: "ICCID" },
      {
        field: "status", headerName: "Status",
        renderCell: (v: string) => <Box className='tw-capitalize' sx={{ color: getStatusColor(v) }}>{v}</Box>
      },
      {
        field: "isNiraVerified", headerName: "Nira Varified Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v ? "Verified" : "Not Verified"}</Box>
      },
      {
        field: "isUCCVerified", headerName: "UCC Varified Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v ? "Verified" : "Not Verified"}</Box>
      },
      {
        field: "SIM",
        headerName: "SIM",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.SIM_MANAGEMENT}/${data?.SIM}`)} > View SIM Details</Button >
      },
      {
        field: "swapKyc",
        headerName: "Swap KYC",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.KYC}/${data?.swapKyc}`)} > View Swap KYC Details</Button >
      },
    ];

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => {
          handleFetchCustomerDoc();
          toggleCustomerDoc();
        }}
      >
        View Documents
      </Button>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
      <CustomDrawer
        open={openCustomerDoc}
        onClose={toggleCustomerDoc}
        title="Customer Documents"
      >
        {simSwapDocs ? (
          <CustomerDocs
            onClose={toggleCustomerDoc}
            data={simSwapDocs}
          />
        ) : (
          <></>
        )}
      </CustomDrawer>
    </Grid>
  );
};

export default SimSwapDetailsTable;
