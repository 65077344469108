import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { alpha, useTheme } from "@mui/material/styles";
import {
  renderUpdatedInvalidStringErrorText,
} from "functions/validators";
import { useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import SearchIcon from "component/_common/icons/search";

interface Props {
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onSubmit?: () => void;
  specialCharsAllowed?: boolean;
}

const SectionSearch: React.FC<Props> = ({
  name,
  value,
  placeholder,
  onChange,
  onClear,
  onSubmit,
  specialCharsAllowed = false,
}) => {
  const { palette } = useTheme();

  const [error, setError] = useState<string>("");

  const checkValidString = (Str: string): boolean => {
    let reg: RegExp = /^[$&`]+$/;
    return reg.test(Str);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;


    if (!specialCharsAllowed) {
      if (value && checkValidString(value)) {
        return setError(renderUpdatedInvalidStringErrorText());
      } else {
        setError("");
      }
    }

    if (onChange) onChange(e);
  };

  const handleFocus = () => {
    setError("");
  };

  const handleBlur = () => {
    if (!specialCharsAllowed) {
      if (value && checkValidString(value)) {
        return setError(renderUpdatedInvalidStringErrorText());
      } else {
        setError("");
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!value) {
      return setError("Please enter search key!");
    }

    handleBlur();

    if (onSubmit) onSubmit();
  };

  return (
    <>
      <Box className="tw-relative tw-rounded-[3rem] tw-overflow-hidden">
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            id={name}
            fullWidth
            variant="outlined"
            type="text"
            name={name || ""}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder || "Search"}
            autoComplete="off"
            error={error ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="tw-mr-[42px]">
                  {value ? (
                    <IconButton color="error" onClick={onClear} edge="end">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              ),
            }}
            sx={{
              ".MuiInputBase-root.MuiOutlinedInput-root": {
                padding: "8px 15px",

                ".MuiInputBase-input": {
                  fontSize: "14px",
                  padding: 0,
                },
                ".MuiOutlinedInput-notchedOutline": {
                  background: alpha(palette.secondary.main, 0.04),
                  borderRadius: "3rem",
                },
                "&:not(.Mui-disabled)": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: palette.primary.main,
                  },
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "1px",
                },
              },
            }}
          />

          <Button
            variant="contained"
            disableElevation
            aria-label="Search"
            type="submit"
            className="tw-absolute tw-top-[0] tw-right-[0] tw-min-w-[42px] tw-w-[42px] tw-h-[100%] tw-p-0 tw-rounded-none"
            sx={{
              background: "transparent",
              "&:hover, :focus": {
                background: "transparent",
              },
            }}
          >
            <SearchIcon color={palette.primary.main} width={18} />
          </Button>
        </Box>
      </Box>
      {error ? (
        <FormHelperText>
          <Box
            component="span"
            color="error.main"
            pl={2}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </FormHelperText>
      ) : (
        <></>
      )}
    </>
  );
};

export default SectionSearch;
