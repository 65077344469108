import { GetThirdPartyApiAlertsProps } from "_interfaces/log_alerts";
import { GET_THIRD_PARTY_API_ALERT_DETAILS, GET_THIRD_PARTY_API_ALERTS } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetThirdPartyApiAlerts = ({ DATA }: GetThirdPartyApiAlertsProps) => {
    return HTTP({
        Method: "POST",
        Url: GET_THIRD_PARTY_API_ALERTS,
        Data: DATA,
    });
};
export const GetThirdPartyApiAlertDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: GET_THIRD_PARTY_API_ALERT_DETAILS(ID)
    });
};
