import { COUNTRY_CODE } from "config/endpoint";
import { RouteConstant } from "navigation/constant";
import { AutoCompleteOptionsProps } from "_interfaces/_common/forms";

export const getClientTimeZone = (): string | null => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone) return timeZone;
    else return null;
  } catch (error) {
    return null;
  }
};

export const Channel = new BroadcastChannel("logout");

export const ClearDataOnLogout = () => {
  localStorage.removeItem("token");
  window.location.href = RouteConstant.LOG_IN;
};

export const getLocation = (): Promise<GeolocationPosition> => {
  return new Promise((res, rej) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res, rej);
    } else {
      return rej;
    }
  });
};

export const generateMSISDN = (str: string) => {
  if (!str) return "";
  return `${COUNTRY_CODE}${str}`;
};

export const filterNonNullValues = (obj: any): any => {
  const filteredObj: any = {};
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ""
    ) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
};

export const sumCountSuccessAndReject = (data: object[]): number => {
  let sumSuccess = 0;
  let sumReject = 0;

  data.forEach((entry: any) => {
    sumSuccess += entry.countSuccess;
    sumReject += entry.countReject;
  });

  return sumSuccess + sumReject;
};

export const camelCaseToFormattedString = (camelCase?: string): string => {
  if (!camelCase) return "";
  return camelCase
    .toString()
    .replace(/([A-Z])/g, " $1")
    .toLowerCase();
};

export const toTitleCase = (str: string): string => {
  if (!str) return "";
  return (
    str
      .toString()
      // Insert spaces before uppercase letters (for camelCase)
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // Replace underscores and hyphens with spaces
      .replace(/[_-]/g, " ")
      // Convert each word to title case
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
      )
  );
};

// Convert enum or record keys to an array of strings
const getEnumKeys = <T>(enumObj: T): string[] => {
  if (enumObj) {
    return Object.keys(enumObj).filter((key) => isNaN(Number(key)));
  }
  return [];
};

// Generate autocomplete options with consistent string values
export const generateAutocompleteOptions = (
  values: string[] | Record<string, string | number>
): AutoCompleteOptionsProps[] => {
  const keys = Array.isArray(values) ? values : getEnumKeys(values); // Extract keys for enums or objects

  return keys.map((key) => {
    const rawValue = Array.isArray(values)
      ? key
      : values[key as keyof typeof values]; // Get the value from record or use the key directly

    const value = String(rawValue); // Ensure all values are strings

    return {
      id: value, // Stringified ID
      value: value, // Stringified value
      title: toTitleCase(key), // Convert key to title case for display
      isActive: true,
    };
  });
};
