import { InfluencerGenerateCouponLinkModel } from "_models/data/influencer/data.influencer-generate-coupon-link.model";
import moment from "moment";

export const validategenerateInfluencerCouponLink = (
  DATA: InfluencerGenerateCouponLinkModel
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  const today = moment().startOf("day");

  if (DATA?.expiryDate) {
    const expiryDate = moment(DATA.expiryDate).startOf("day");

    if (!expiryDate.isAfter(today)) {
      valid = false;
      errors.push({
        name: "expiryDate",
        error: "Expiry Date must be a future date.",
      });
    }
  }

  return { valid, errors };
};
