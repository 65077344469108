import { SimSwapFilterProps } from "_interfaces/functions/http-requests/sim-swap";
import { GET_SIM_SWAP_DOC, SIM_SWAP_DETAILS, SIM_SWAP_LIST } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetSimSwapList = ({ DATA }: SimSwapFilterProps,) => {
    return HTTP({
        Method: "POST",
        Url: SIM_SWAP_LIST,
        Data: DATA
    });
};
export const GetSimSwapDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: SIM_SWAP_DETAILS(ID),
    });
};
export const GetSimSwapDoc = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: GET_SIM_SWAP_DOC(ID),
    });
};