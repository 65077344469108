import LogAlertsLayout from "component/log_alerts";

const LogAlerts = () => {
    return (
        <>
            <LogAlertsLayout />
        </>
    )
}

export default LogAlerts;