import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { InfluencerGenerateCouponLinkProps } from "_interfaces/functions/http-requests/influencer";
import { InfluencerGenerateCouponLinkModel } from "_models/data/influencer/data.influencer-generate-coupon-link.model";
import { InfluencerGenerateCouponLinkErrorModel } from "_models/error/influencer/error.influencer-generate-coupon-link.model";
import axios from "axios";
import DatePicker from "component/_common/forms/date";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { InfluencerGenerateCouponLink } from "functions/http-requests/influencer";
import { Fragment, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validategenerateInfluencerCouponLink } from "./formValidator";
import useCopyToClipboard from "functions/hooks/useCopyToClipboard";

interface InputListProps extends FormInputProps {
  name: "expiryDate";
}

interface Props {
  influencerId?: string | null;
  onClose: () => void;
  handleRefresh: () => void;
}

const GenerateCouponLinkForm: React.FC<Props> = ({
  influencerId,
  onClose,
  handleRefresh,
}) => {
  const Dispatch = useDispatch();

  const { copied, copy } = useCopyToClipboard();

  const [state, setState] = useState<InfluencerGenerateCouponLinkModel>(
    new InfluencerGenerateCouponLinkModel()
  );
  const [errors, setErrors] = useState<InfluencerGenerateCouponLinkErrorModel>(
    new InfluencerGenerateCouponLinkErrorModel()
  );

  const handleCreate = ({
    DATA,
    PARAMS,
  }: InfluencerGenerateCouponLinkProps) => {
    Dispatch(toggleLoading(true));
    InfluencerGenerateCouponLink({ DATA, PARAMS })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          if (data?.data?.link) {
            setState((prev) => ({
              ...prev,
              expiryDate: null,
              couponLink: data?.data?.link,
            }));
          } else {
            onClose();
          }
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validategenerateInfluencerCouponLink(state);

    if (ValidateStep?.valid) {
      let DATA: InfluencerGenerateCouponLinkProps["DATA"] = {
        expiryDate: state?.expiryDate || undefined,
      };
      DATA = filterNonNullValues(DATA);
      if (influencerId) {
        handleCreate({
          DATA,
          PARAMS: {
            id: influencerId,
          },
        });
      }
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "date",
        name: "expiryDate",
        label: "Expiry Date",
        placeholder: "Enter Expiry Date",
        grid: 12,
      },
    ],
    []
  );

  return (
    <>
      {state?.couponLink ? (
        <>
          <Box>Coupon Link:</Box>
          <Box fontSize={12} color="gray" sx={{ wordBreak: "break-all" }}>
            {state.couponLink}
          </Box>
          <Box pt={3} display="flex" justifyContent="flex-end" gap={2}>
            <Button onClick={onClose} color="inherit">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (state?.couponLink) {
                  copy(state.couponLink);
                }
              }}
            >
              Copy
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            component="form"
            id="generate_influencer_coupon_link_form"
            onSubmit={handleSubmit}
          >
            <Grid container rowSpacing={2} columnSpacing={2}>
              {inputList.map(
                ({ type, name, label, placeholder, grid }, index) => (
                  <Fragment key={index}>
                    <>
                      {type === "date" ? (
                        <Grid item xs={12} md={grid || 6}>
                          <DatePicker
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            value={
                              state[
                                name as keyof InfluencerGenerateCouponLinkModel
                              ]
                            }
                            errorText={
                              errors[
                                name as keyof InfluencerGenerateCouponLinkErrorModel
                              ]
                            }
                            onChange={(value: any) => {
                              setState((prev) => ({
                                ...prev,
                                [name]: value,
                              }));
                              setErrors((prev) => ({
                                ...prev,
                                [name]: "",
                              }));
                            }}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  </Fragment>
                )
              )}

              <Grid item xs={12}>
                <Box pt={5} display="flex" justifyContent="flex-end" gap={2}>
                  <Button onClick={onClose} color="inherit">
                    Cancel
                  </Button>

                  <Button type="submit" variant="contained" disableElevation>
                    Generate Link
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default GenerateCouponLinkForm;
