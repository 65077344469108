import { PhoneBookingDetailsLayout } from "component/phoneBooking/details";
import { RouteConstant } from "navigation/constant";
import { useParams, Navigate } from "react-router-dom";

export const PhoneBookingDetails = () => {
    const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.BOOKINGS} />;
    return (
        <>
            <PhoneBookingDetailsLayout id={params.id} />
        </>
    )
}