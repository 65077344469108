import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CartonListByDistributor } from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { useNavigate } from "react-router-dom";

interface Props {
    DATA: CartonListByDistributor[]
    loading: boolean,
    count?: number,
}

const AllCartonsTable: React.FC<Props> = ({ DATA, loading }) => {
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: "firstICCID",
            headerName: "First ICCID",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "lastICCID",
            headerName: "Last ICCID",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "distributor",
            headerName: "Distributor",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "cartonNo",
            headerName: "Carton No.",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "batchNo",
            headerName: "Batch No.",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "date",
            headerName: "Created On",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => moment(params.value).format("Do MMM, YYYY"),
            renderCell: (params) => (
                <Box className="tw-capitalize">{params?.formattedValue}</Box>
            ),
        },
        {
            field: "createdAt",
            headerName: "Action",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title="View Details">
                    <VisibilityIcon color="inherit" fontSize="small" sx={{ cursor: "pointer" }} onClick={() => navigate(`${RouteConstant.CARTON_DETAILS}/${params.id}`)} />
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            <CustomDataGrid
                loading={loading}
                columns={columns}
                rows={DATA || []}
                hideFooter={true}
            />
        </>
    )
}

export default AllCartonsTable;