import { InfluencerModel } from "_models/data/influencer/data.influencer.model";
import { CreateTeamModel } from "_models/data/team/data.createteam.model";
import { isValidEmail, isValidPhone } from "functions/validators";

export const validateTeam = (
  DATA: CreateTeamModel,
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.teamName?.trim()) {
    valid = false;
    errors.push({
      name: "teamName",
      error: "Team name is required.",
    });
  }

  if (!DATA?.email?.trim()) {
    valid = false;
    errors.push({
      name: "managerEmail",
      error: "Manager email is required.",
    });
  }
  if (!DATA?.password?.trim()) {
    valid = false;
    errors.push({
      name: "managerPassword",
      error: "Manager password is required.",
    });
  }
  if (!DATA?.managerPhone?.trim()) {
    valid = false;
    errors.push({
      name: "managerPhone",
      error: "Manager phone is required.",
    });
  }
  if(!DATA.acknowledgementForm){
    valid = false;
    errors.push({
      name: "acknowledgementForm",
      error: "Acknowledgement file is required.",
    });
  }
  if (DATA?.email?.trim() && !isValidEmail(DATA.email)) {
    valid = false;
    errors.push({
      name: "managerEmail",
      error: "Invalid email.",
    });
  }


  if (DATA?.managerPhone?.trim() && !isValidPhone(DATA.managerPhone)) {
    valid = false;
    errors.push({
      name: "managerPhone",
      error: "Invalid phone.",
    });
  }

  if (!DATA?.teamLocation?.trim()) {
    valid = false;
    errors.push({
      name: "teamLocation",
      error: "Team location is required.",
    });
  }

  if (!DATA?.managerName?.trim()) {
    valid = false;
    errors.push({
      name: "managerName",
      error: "Manager name is required.",
    });
  }


  return { valid, errors };
};
