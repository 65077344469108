import { Grid, Box, Button } from "@mui/material"
import { GridPaginationModel } from "@mui/x-data-grid";
import PhoneBookingTableInterface, { BookingFilterProps } from "_interfaces/functions/http-requests/bookings";
import { BookingFilterModel } from "_models/data/phoneBooking/data.phone-booking-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { ExportPhoneBooking, GetPhoneBookingList } from "functions/http-requests/bookings";
import FilterButton from "parts/filterButton"
import SectionSearch from "parts/sectionSearch"
import { useEffect, useRef, useState } from "react";
import PhoneBookingTable from "./phoneBookingTable";
import PhoneBookingFilter from "./phoneBookingFilter";
import moment from "moment";
import CustomDrawer from "parts/customDialog/customDrawer";
import PhoneBookingForm from "./phoneBookingForm";
import { setAlert } from "state/reducers/alert";
import { useDispatch } from "react-redux";

export const PhoneBookingLayout = () => {
    const isInitialRender = useRef(true);
    const [searchKey, setSearchKey] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const handleClearSearch = () => setSearchKey("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<PhoneBookingTableInterface["data"]>([]);
    const [filters, setFilters] = useState<BookingFilterModel>(new BookingFilterModel());
    const [count, setCount] = useState<number>(0);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const toggleForm = () => {
        if (editId) return setEditId("");
        setOpenForm(!openForm);
    };
    const dispatch = useDispatch();
    const [exportCSVLoading, setExportCSVLoading] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>("");
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    const handleExportCSV = async () => {
        setExportCSVLoading(true);
        let PAYLOAD_DATA: BookingFilterProps["DATA"] = {
            searchKey: searchKey,
            pageNumber: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            status: filters.status || "",
            identityType: filters.identityType || "",
            bookingBy: filters.bookingBy || '',
            fromDate: filters.fromDate,
            toDate: filters.toDate
        };

        PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

        try {
            const res = await ExportPhoneBooking({ DATA: PAYLOAD_DATA });

            if (res.status === 200 && res.data) {
                // Access CSV data
                const csvData = res.data;

                // Create a Blob object from the CSV data
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

                // Create a download link
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute('download', 'exported_data.csv');

                // Append link to the document and trigger a click
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                dispatch(setAlert({ type: "success", message: "CSV downloaded successfully!" }));
            } else {
                dispatch(setAlert({ type: "error", message: "Failed to export CSV data!" }));
            }
        } catch (error) {
            dispatch(setAlert({ type: "error", message: "Error in exporting CSV!" }));
        }
        finally {
            setExportCSVLoading(false)
        }
    };


    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: BookingFilterProps["DATA"] = {
                searchKey: searchKey,
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                status: filters.status || "",
                identityType: filters.identityType || "",
                bookingBy: filters.bookingBy || '',
                fromDate: filters.fromDate,
                toDate: filters.toDate
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetPhoneBookingList({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            MSISDN: item?.MSISDN,
                            name: item?.name,
                            email: item?.email,
                            phoneNumber: item?.phoneNumber,
                            status: item?.status,
                            bookingBy: item?.bookingBy,
                            identityType: item?.identityType,
                            passportNumber: item?.passportNumber,
                            bookingTime: item?.bookingTime,
                            createdAt: item?.createdAt,
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [refresh, paginationModel, filters, searchKey]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                    <Box sx={{ flex: "1 1 auto" }}>
                        <SectionSearch
                            name="search_regional_distributor"
                            value={searchKey}
                            onChange={handleSearch}
                            onClear={handleClearSearch}
                            onSubmit={handleSubmitSearch}
                        />
                    </Box>
                    <PhoneBookingFilter
                        open={openFilter}
                        filters={filters}
                        setFilters={setFilters}
                        setOpen={setOpenFilter}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
                    <Button
                        variant="outlined"
                        className="tw-mr-[12px]"
                        onClick={handleExportCSV}
                        disabled={exportCSVLoading}
                    >
                        Export CSV
                    </Button>
                </Grid>
            </Grid>
            <PhoneBookingTable
                setEditId={setEditId}
                data={data}
                loading={dataLoading}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />

            <CustomDrawer
                open={openForm || editId ? true : false}
                onClose={toggleForm}
                title={editId ? "Edit  Coupon Details" : "Add New Coupon"}
            >
                <PhoneBookingForm
                    handleRefresh={handleRefresh}
                    onClose={toggleForm}
                    editId={editId}
                />
            </CustomDrawer>
        </>
    )
}
