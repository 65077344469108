import { Visibility } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import CouponTableInterface from "_interfaces/coupon/couponTable";
import { toTitleCase } from "functions/helper";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React from "react";
import { useNavigate } from "react-router-dom";

const CouponTable: React.FC<CouponTableInterface> = ({
  loading,
  data,
  setEditId,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return params?.value ? (
          <Tooltip title={params?.value}>
            <Box>{toTitleCase(params.value)} </Box>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "applyOn",
      headerName: "Apply On",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return params?.value ? (
          <Tooltip title={params?.value}>
            <Box>{toTitleCase(params.value)} </Box>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "couponCode",
      headerName: "Coupon Code",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return params?.value ? (
          <Tooltip title={params?.value}>
            <Box className="tw-uppercase">{toTitleCase(params.value)} </Box>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "validFrom",
      headerName: "Valid From",
      minWidth: 80,
      valueFormatter(params) {
        return params?.value ? moment(params.value).format("Do MMM YYYY") : "";
      },
    },
    {
      field: "validUntil",
      headerName: "Valid Until",
      minWidth: 80,
      valueFormatter(params) {
        return params?.value ? moment(params.value).format("Do MMM YYYY") : "";
      },
    },
    {
      field: "totalUsed",
      headerName: "Total Used",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "maxUse",
      headerName: "Max Use",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "isOpen",
      headerName: "Open For All",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <IsActiveCell
          status={params?.value ? "Yes" : "No"}
          statusColor={
            params?.value ? palette.success.main : palette.error.main
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => <IsActiveCell status={params?.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() => params?.row?.id && setEditId(params?.row?.id)}
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
    {
      field: "seeDetails",
      headerName: "See Details",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(
                    `${RouteConstant.COUPON}/${params?.row?.id}`
                  )
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                <Visibility color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data || []}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  );
};

export default CouponTable;
