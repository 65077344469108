import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";
import { BuildBundleInterface, PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { AirtelPayload, MomoPayload, PaymentDetailsInterface } from "_interfaces/payment";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import { SimSwapDetailsInterface, SimSwapDocInterface } from "_interfaces/sim-swap";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import { GetSimSwapDoc } from "functions/http-requests/sim-swap";
import { AppStatusCode } from "config/appStatusCode";
import axios from "axios";
import { setAlert } from "state/reducers/alert";
import { HTTP_ERROR } from "functions/http";
import CustomerDocs from "component/customer-management/details/customerDocs";
import { DeRegistrationDetailsInterface, DeRegistrationDocInterface } from "_interfaces/deRegistration";
import { GetDeRegistrationDoc } from "functions/http-requests/de-registration";

interface Props {
  data: DeRegistrationDetailsInterface | null;
  handleOpen: () => void;
}

const DeRegistrationDetailsTable: React.FC<Props> = ({ data, handleOpen }) => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const getStatusColor = (v: string) => {
    if (v === 'pending') return 'orange';
    if (v === 'success') return 'green  ';
    if (v === 'rejected') return 'red';
    return 'green';
  }

  const [openCustomerDoc, setOpenCustomerDoc] = useState<boolean>(false);
  const toggleCustomerDoc = () => {
    setOpenCustomerDoc(!openCustomerDoc);
  };
  const [deRegDocs, setDeRegDocs] = useState<DeRegistrationDocInterface>();

  let handleFetchCustomerDoc = () => {
    Dispatch(toggleLoading(true));
    GetDeRegistrationDoc(data?.id as string)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA = data?.data;

          // CITIZEN
          const visaFrontScan = DATA?.visaFrontScan || null;
          const refugeeDocumentFrontScan = DATA?.refugeeDocumentFrontScan || null;
          const refugeeDocumentBackScan = DATA?.refugeeDocumentBackScan || null;
          const passportFrontScan = DATA?.passportFrontScan || null;
          const nationalIdDocumentScanFront = DATA?.nationalIdDocumentScanFront || null;
          const nationalIdDocumentScanBack = DATA?.nationalIdDocumentScanBack || null;
          const customerPhoto =
            DATA?.customerPhoto || null;

          setDeRegDocs((prev) => ({
            ...prev,
            visaFrontScan,
            customerPhoto,
            passportFrontScan,
            refugeeDocumentBackScan,
            refugeeDocumentFrontScan,
            nationalIdDocumentScanBack,
            nationalIdDocumentScanFront
          }));
        } else {
          // setState(null);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  const rows: {
    field: keyof NonNullable<
      DeRegistrationDetailsInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "MSISDN", headerName: "MSISDN", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      {
        field: "customer", headerName: "Customer",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.CUSTOMER_MANAGEMENT}/${data?.customer}`)} > View Customer Details</Button >
      },
      { field: "registerBy", headerName: "Register By" },
      { field: "reason", headerName: "Reason" },
      {
        field: "status", headerName: "Status",
        renderCell: (v: string) => <Box className='tw-capitalize' sx={{ color: getStatusColor(v) }}>{v}</Box>
      },
      {
        field: "isNiraVerified", headerName: "Nira Varified Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v ? "Verified" : "Not Verified"}</Box>
      },
      {
        field: "isRefugeeVerified", headerName: "Refugee Varified Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v ? "Verified" : "Not Verified"}</Box>
      },
      {
        field: "SIM",
        headerName: "SIM",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.SIM_MANAGEMENT}/${data?.SIM}`)} > View SIM Details</Button >
      },
      {
        field: "kyc",
        headerName: "KYC",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.KYC}/${data?.kyc}`)} > View KYC Details</Button >
      },
      {
        field: "agent",
        headerName: "Agent",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.AGENTS}/${data?.agent}`)} > View Agent Details</Button >
      },
      {
        field: "handlerId",
        headerName: "Handler",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.HANDLERS}/${data?.handlerId}`)} > View Handler Details</Button >
      },
    ];

  return (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item xs={12} sm={6} className="tw-flex tw-justify-end" sx={{ gap: "10px" }}>
        <Button
          variant="contained"
          sx={{
            background: "#C62E2E",
            "&:hover": {
              background: "#C62E2E", // Same color on hover
            },
          }}
          onClick={() => handleOpen()}
        >
          Approve
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            handleFetchCustomerDoc();
            toggleCustomerDoc();
          }}
        >
          View Documents
        </Button>
      </Grid>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
      <CustomDrawer
        open={openCustomerDoc}
        onClose={toggleCustomerDoc}
        title="Customer Documents"
      >
        {deRegDocs ? (
          <CustomerDocs
            onClose={toggleCustomerDoc}
            identityType={data?.identityType}
            data={deRegDocs}
          />
        ) : (
          <></>
        )}
      </CustomDrawer>
    </Grid>
  );
};

export default DeRegistrationDetailsTable;
