import { HTTP } from "functions/http";

import {
  CREATE_PROMOTION,
  GET_ALL_PROMOTION,
  GET_PROMOTION_BY_ID,
  UPDATE_PROMOTION,
} from "config/endpoint";
import {
  PromotionCreateProps,
  PromotionUpdateProps,
} from "_interfaces/functions/http-requests/promotion";

export const CreatePromotion = ({ DATA }: PromotionCreateProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_PROMOTION,
    Data: DATA,
  });
};

export const UpdatePromotion = ({ DATA }: PromotionUpdateProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_PROMOTION,
    Data: DATA,
  });
};

export const GetAllPromotion = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_PROMOTION,
  });
};

export const GetPromotionById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_PROMOTION_BY_ID(ID),
  });
};
