import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Box, Card, Chip } from "@mui/material";
import { TeamDetailsInterface } from "_interfaces/teams";
import { IsActiveCell } from "parts/table/customCell";

interface Props {
  data: TeamDetailsInterface | null;
}

const TeamDetailsTable: React.FC<Props> = ({ data }) => {

  const rows: {
    field: keyof NonNullable<TeamDetailsInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      {
        field: "name", headerName: "Name",
        renderCell: () => <Box className="tw-capitalize">{data?.name}</Box>
      },
      {
        field: "location", headerName: "Location",
        renderCell: () => <Box className="tw-capitalize">{data?.location}</Box>
      },
      { field: "teamID", headerName: "Team ID" },
      {
        field: "isActive", headerName: "Status",
        renderCell: () => <IsActiveCell status={data?.isActive ? true : false} />,
      },
      { field: "createdAt", headerName: "Date", renderCell: () => moment(data?.createdAt).format("Do MMM, YYYY") },
      {
        field: "autoAssignTasks",
        headerName: "Assigned Tasks",
        renderCell: () => (
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {data?.autoAssignTasks && data.autoAssignTasks.length > 0 ? (
              data.autoAssignTasks.map((task, index) => (
                <Chip key={index} label={task} variant="outlined" />
              ))
            ) : (
              <Chip label="No tasks assigned" color="default" />
            )}
          </Box>
        ),
      },
      
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>

    </>
  );
};

export default TeamDetailsTable;
