import { InfluencerModel } from "_models/data/influencer/data.influencer.model";
import { isValidEmail, isValidPhone } from "functions/validators";

export const validateInfluencer = (
  DATA: InfluencerModel,
  isEditing?: boolean
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.email?.trim()) {
    valid = false;
    errors.push({
      name: "email",
      error: "Email is required.",
    });
  }

  if (DATA?.email?.trim() && !isValidEmail(DATA.email)) {
    valid = false;
    errors.push({
      name: "email",
      error: "Invalid email.",
    });
  }

  if (!DATA?.phoneNumber?.trim()) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Phone number is required.",
    });
  }

  if (DATA?.phoneNumber?.trim() && !isValidPhone(DATA.phoneNumber)) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Invalid phone.",
    });
  }

  if (!DATA?.category?.trim()) {
    valid = false;
    errors.push({
      name: "category",
      error: "Category is required.",
    });
  }

  if (!isEditing && !DATA?.couponId?.trim()) {
    valid = false;
    errors.push({
      name: "couponId",
      error: "Please select coupon.",
    });
  }

  if (!DATA?.firstName?.trim()) {
    valid = false;
    errors.push({
      name: "firstName",
      error: "First name is required.",
    });
  }

  if (!DATA?.lastName?.trim()) {
    valid = false;
    errors.push({
      name: "lastName",
      error: "Last name is required.",
    });
  }

  return { valid, errors };
};
