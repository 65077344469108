import PromotionLayout from "component/promotion";
import MainContainer from "parts/mainContainer";

const Promotion = () => {
  return (
    <MainContainer>
      <PromotionLayout />
    </MainContainer>
  );
};

export default Promotion;
