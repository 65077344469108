import { Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { AgentDistributorDetails } from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "recharts";

interface Props {
    DATA: AgentDistributorDetails[]
    loading: boolean,
    count?: number,
    paginationModel?: GridPaginationModel,
    setPaginationModel?: React.Dispatch<
        React.SetStateAction<GridPaginationModel>
    >;
    sortModel?: GridSortModel;
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>;
}

const AgentDetailsTable: React.FC<Props> = ({ DATA, count, paginationModel, loading, setPaginationModel }) => {
    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Agent Name",
            flex: 1,
            minWidth: 150,
            renderCell: (params) => {
                return <Box
                    sx={{ textDecoration: "underline", color: "#0808ce",cursor:"pointer" }}
                    onClick={() => {
                        params?.row?.id &&
                            navigate(
                                `${RouteConstant.AGENTS}/${params?.row?.id}`
                            )
                    }} className="tw-capitalize">{params?.row?.name}</Box>
            },
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "date",
            headerName: "Time",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => moment(params.value).format("MMM D YYYY h:mm A"),
            renderCell: (params) => (
                <Box className="tw-capitalize">{params?.formattedValue}</Box>
            ),
        },
        {
            field: "region",
            headerName: "Region",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "isActive",
            headerName: "Status",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <IsActiveCell status={params?.row?.isActive} />
            ),
        },
    ];

    return (
        <>
            <CustomDataGrid
                loading={loading}
                columns={columns}
                rows={DATA || []}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    )
}

export default AgentDetailsTable;