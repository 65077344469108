import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InventoryUsersDetailsTableInterface from "_interfaces/inventory-users/details/inventoryUsersDetailsTable";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  GetInventoryUsersById,
  InventoryUserActivation,
} from "functions/http-requests/inventory-users";
import { RouteConstant } from "navigation/constant";
import CustomDrawer from "parts/customDialog/customDrawer";
import StatusDialog from "parts/customDialog/statusDialog";
import NavigateBackButton from "parts/navigateBackButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import InventoryUsersForm from "../inventoryUsersForm";
import InventoryUsersDetailsTable from "./detailsTable";

interface Props {
  id: string;
}

const InventoryUsersDetailsLayout: React.FC<Props> = ({ id }) => {
  const Dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] =
    useState<InventoryUsersDetailsTableInterface | null>(null);

  const [editId, setEditId] = useState<string>("");
  const [editStatus, setEditStatus] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  const toggleEdit = () => {
    if (editId) return setEditId("");
  };

  const toggleEditStatus = () => {
    setEditStatus((prev) => !prev);
  };

  const handleChangeStatus = () => {
    if (state?.id) {
      Dispatch(toggleLoading(true));
      InventoryUserActivation({
        DATA: {
          id: state.id,
          activation: state.isActive ? false : true,
        },
      })
        .then((res) => {
          const data = res?.data;
          Dispatch(setAlert({ type: data?.level, message: data?.message }));
          if (data?.statusCode === AppStatusCode.api_updated) {
            if (state?.id) handleFetchById(state?.id);
          } else {
            Dispatch(toggleLoading(false));
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
            Dispatch(toggleLoading(false));
          }
        });
    }
  };

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetInventoryUsersById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            name: DATA?.name || "",
            email: DATA?.email || "",
            phone: DATA?.phone || "",
            role: DATA?.role || "",
            isActive: DATA.isActive,
          }));
        } else {
          setState(null);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
          setIsLoaded(true);
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, refresh, Dispatch]);

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.INVENTORY_USERS} />;

  return (
    <>
      {isLoaded ? (
        <Box mt={2} p={2} className="tw-bg-white tw-rounded-lg">
          <Box className="tw-flex tw-flex-wrap tw-justify-between tw-items-center">
            <NavigateBackButton />
            <Box className="tw-flex tw-justify-end tw-items-center">
              <Box color={state?.isActive ? "error.main" : "success.main"}>
                <Button
                  onClick={toggleEditStatus}
                  variant="outlined"
                  startIcon={
                    state?.isActive ? <CloseIcon /> : <CheckOutlinedIcon />
                  }
                  disableElevation
                  sx={{ ml: 2 }}
                  color="inherit"
                >
                  <Box component="span" className="tw-line-clamp-1">
                    {state?.isActive ? "De-Activate User" : "Activate User"}
                  </Box>
                </Button>
              </Box>

              <Button
                onClick={() => state?.id && setEditId(state?.id)}
                variant="contained"
                startIcon={<EditIcon />}
                disableElevation
                sx={{ ml: 2 }}
              >
                <Box component="span" className="tw-line-clamp-1">
                  Edit Details
                </Box>
              </Button>
            </Box>
          </Box>
          <Box pt={2}>
            <InventoryUsersDetailsTable data={state} />
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <CustomDrawer
        open={editId ? true : false}
        onClose={toggleEdit}
        title="Edit User Details"
      >
        <InventoryUsersForm
          handleRefresh={handleRefresh}
          onClose={toggleEdit}
          editId={editId}
        />
      </CustomDrawer>

      <StatusDialog
        open={editStatus}
        onClose={() => {
          toggleEditStatus();
          handleRefresh();
        }}
        onChangeStatus={handleChangeStatus}
        title={state?.isActive ? "De-activate User" : "Activate User"}
        content={`Are you sure to ${
          state?.isActive ? "De-activate" : "Activate"
        } <strong>"${state?.name}"</strong>? `}
      />
    </>
  );
};

export default InventoryUsersDetailsLayout;
