import LotteryLayout from "component/promotion/lottery";
import MainContainer from "parts/mainContainer";

const Lottery = () => {
  return (
    <MainContainer>
      <LotteryLayout />
    </MainContainer>
  );
};

export default Lottery;
