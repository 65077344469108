import { AppStatusCode } from "config/appStatusCode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import { GetGlobalActivityLogDetails } from "functions/http-requests/global-activity-logs";
import { IGlobalActivityLog } from "_interfaces/global-activity-logs";
import GlobalActivityLogDetailsTable from "./global_activity_log_details_table";

interface Props {
    id: string;
}

const GlobalActivityLogDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<IGlobalActivityLog | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetGlobalActivityLogDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        activity: DATA?.activity,
                        alertLevel: DATA?.alertLevel,
                        level: DATA?.level,
                        message: DATA?.message,
                        moduleName: DATA?.moduleName,
                        createdAt: DATA?.createdAt,
                        error: DATA?.error,
                        id: DATA?._id,
                        signature: DATA?.signature,
                        otherInfo: DATA?.otherInfo,
                        actionNeeded: DATA?.actionNeeded,
                        user: DATA?.user,
                        userID: DATA?.userID,
                        actionFromServer: DATA?.actionFromServer,
                        actionToServer: DATA?.actionToServer,
                        actionId: DATA?.actionId,
                        time: DATA?.time,
                        auth3Id: DATA?.auth3Id,
                        IP:DATA?.IP
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])

    return (
        <>
            <GlobalActivityLogDetailsTable data={details} />
        </>
    )
}

export default GlobalActivityLogDetailsLayout;