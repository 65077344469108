
export interface TransactionFilterModelProps {
  paymentMethod?: 'mobileMoney' | 'creditCard' | '';
  paymentBy?: 'momo' | 'airtelMoney' | 'interSwitch' | 'wallet' | '';
  paymentType?: 'credit' | 'debit' | 'refund' | '';
  fromDate?: string | Date | null;  // YYYY-MM-DD format for date filters
  toDate?: string | Date | null;    // YYYY-MM-DD
}

const defaultRechargeFilterModelProps: TransactionFilterModelProps = {
  paymentMethod: '',           // Default payment method
  paymentBy: '',               // Default payment by
  paymentType: '',             // Default payment type
  fromDate: null,
  toDate: null,
};

export class TransactionFilterModel {
  paymentMethod: 'mobileMoney' | 'creditCard' | '';
  paymentBy: 'momo' | 'airtelMoney' | 'interSwitch' | 'wallet' | '';
  paymentType: 'credit' | 'debit' | 'refund' | '';
  fromDate: string | Date | null;
  toDate: string | Date | null;

  constructor(data: TransactionFilterModelProps = defaultRechargeFilterModelProps) {
    this.paymentMethod = data.paymentMethod || '';
    this.paymentBy = data.paymentBy || '';
    this.paymentType = data.paymentType || '';
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
