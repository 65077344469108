export interface RechargeFilterModelProps {
  status?: 'success' | 'failed' | 'pending' | 'inProgress' | '';
  rechargeType?: 'bundle' | 'airtime' | 'build_bundle' | 'wallet_recharge' | '';
  rechargeBy?: 'interswitch' | 'momo' | 'self' | 'auto' | '';
  fromDate?: string | Date | null;  // YYYY-MM-DD
  toDate?: string | Date | null;    // YYYY-MM-DD
}

const defaultRechargeFilterModelProps: RechargeFilterModelProps = {
  status: '',         // Default status value
  rechargeType: '',   // Default rechargeType value
  rechargeBy: '',
  fromDate: null,
  toDate: null,
};

export class RechargeFilterModel {
  status?: 'success' | 'failed' | 'pending' | 'inProgress' | '';
  rechargeType?: 'bundle' | 'airtime' | 'build_bundle' | 'wallet_recharge' | '';
  rechargeBy?: 'interswitch' | 'momo' | 'self' | 'auto' | '';
  fromDate?: string | Date | null;
  toDate?: string | Date | null;

  constructor(
    data: RechargeFilterModelProps = defaultRechargeFilterModelProps
  ) {
    this.status = data.status || '';
    this.rechargeType = data.rechargeType || '';
    this.rechargeBy = data.rechargeBy || '';
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
