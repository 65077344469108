import InfluencerDetailsLayout from "component/influencer/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { Navigate, useParams } from "react-router-dom";

const InfluencerDetails = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.INFLUENCER} />;

  return (
    <MainContainer>
      <InfluencerDetailsLayout id={params.id} />
    </MainContainer>
  );
};

export default InfluencerDetails;
