import { Card, Grid, Typography } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import { AppliedCouponListProps } from "_interfaces/functions/http-requests/influencer";
import { AppliedCouponFilterModel } from "_models/data/influencer/data.applied-coupon-filter.model";
import AppliedCouponTableInterface from "_models/data/influencer/data.applied-coupon-model";
import AppliedCouponFilter from "component/influencer/details/appliedCouponsFilter";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAppliedCouponListByCouponId, GetCouponById } from "functions/http-requests/coupon";
import { useState, useEffect } from "react";
import AppliedCouponsTable from "./appliedCouponsTable";
import { useDispatch } from "react-redux";
import { CouponModel } from "_models/data/coupon/data.coupon.model";
import axios from "axios";
import { HTTP_ERROR } from "functions/http";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import CouponDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const CouponDetailsLayout: React.FC<Props> = ({ id }) => {
    const [appliedCouponList, setAppliedCouponList] = useState<AppliedCouponTableInterface["data"]>([]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [count, setCount] = useState<number>(0);
    const [filters, setFilters] = useState<AppliedCouponFilterModel>(
        new AppliedCouponFilterModel()
    );
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };
    const [state, setState] = useState<CouponModel>(new CouponModel());
    const Dispatch = useDispatch();

    const handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetCouponById(ID)
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;

                    setState({
                        id: DATA?._id,
                        category: DATA?.category || "",
                        applyOn: DATA?.applyOn || "",
                        couponCode: DATA?.couponCode?.toUpperCase() || "",
                        validFrom: DATA?.validFrom || null,
                        validUntil: DATA?.validUntil || null,
                        maxUse: DATA?.maxUse || "",
                        discountType: DATA?.discountType || "",
                        discountValue: DATA?.discountValue || "",
                        minPurchaseAmount: DATA?.minPurchaseAmount || "",
                        maxDiscountAmount: DATA?.maxDiscountAmount || "",
                        isOpen: DATA?.isOpen || false,
                        info: DATA?.info || "",
                        title: DATA?.title || "",
                        status: DATA?.status || "",
                    });
                } else {
                    Dispatch(setAlert({ type: "error", message: data?.message }));
                    setState(new CouponModel());
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error))
                    Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
            })
            .finally(() => Dispatch(toggleLoading(false)));
    };

    useEffect(() => {
        if (id) {
            let fetchById: (() => void) | null = () => {
                handleFetchById(id);
            };
            fetchById();
            return () => {
                fetchById = null;
            };
        }
    }, [id]);

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: AppliedCouponListProps["DATA"] = {
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                appliedOn: filters.appliedOn || "",
                fromDate: filters?.fromDate || "",
                toDate: filters?.toDate || ""
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetAppliedCouponListByCouponId(id, PAYLOAD_DATA)
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            influencerId: item?.influencerId,
                            appliedOn: item?.appliedOn,
                            couponCode: item?.couponCode,
                            couponId: item?.couponId,
                            time: item?.time,
                            phoneBookingId: item?.phoneBookingId,
                            paymentId: item?.paymentId,
                            discountAmount: item?.discountAmount,
                            createdAt: item?.createdAt,
                        }));
                        setAppliedCouponList(DATA);
                    } else {
                        setAppliedCouponList([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setAppliedCouponList([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [id, refresh, paginationModel, filters]);

    return (
        <>
            <CouponDetailsTable data={state} />
            <Card sx={{ padding: "15px", marginTop: "2rem" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Typography
                            color="text.primary"
                            className="tw-text-[18px]"
                            sx={{ fontWeight: "bold" }}
                        >
                            {"Applied Coupons"}
                        </Typography>
                        <AppliedCouponFilter
                            open={openFilter}
                            onClose={toggleFilter}
                            filters={filters}
                            setFilters={setFilters}
                            setOpen={setOpenFilter}
                        />
                    </Grid>
                </Grid>
                <AppliedCouponsTable
                    data={appliedCouponList}
                    loading={dataLoading}
                    count={count}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                />
            </Card>
        </>
    )
}