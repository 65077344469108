import { Box, Card } from "@mui/material";
import CreateTeamForm from "./createTeamForm"

export const CreateTeamLayout = () => {
    return (
        <>
            <Card
                sx={{
                    padding: "30px"
                }}>
                <CreateTeamForm />
            </Card>
        </>
    )
}