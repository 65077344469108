import { Box, Button, Grid } from "@mui/material";
import { Add } from "@mui/icons-material";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import { GetAllTeam } from "functions/http-requests/teams";
import { CreateTeamProps } from "_interfaces/functions/http-requests/teams";
import { useEffect, useRef, useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import CreateTeamTableInterface from "_models/data/team/data.team.model";
import { TeamFilter } from "_models/data/team/data.team-filter";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import TeamTable from "./teamTable";

export const TeamsLayout = () => {
    const navigate = useNavigate();
    const isInitialRender = useRef(true);
    const [searchKey, setSearchKey] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const handleClearSearch = () => setSearchKey("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<CreateTeamTableInterface["data"]>([]);
    const [filters, setFilters] = useState<TeamFilter>(new TeamFilter());
    const [count, setCount] = useState<number>(0);
    const [openForm, setOpenForm] = useState<boolean>(false);

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: CreateTeamProps["DATA"] = {
                teamName: filters.teamName || "",
                teamLocation: filters.teamLocation || "",
                managerName: filters.managerName || '',
                managerEmail: filters.managerEmail || "",
                managerPhone: filters.managerPhone || "",
                managerPassword: filters.managerPassword || "",
                region: filters.region || "",
                district: filters.district || "",
                county: filters.county || "",
                subCounty: filters.subCounty || "",
                parish: filters.parish || "",
                acknowledgementForm: filters.acknowledgementForm
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetAllTeam({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            name: item?.name,
                            teamID: item?.teamID,
                            location: item?.location,
                            manager: item?.manager,
                            isActive: item?.isActive,
                            createdAt: item?.createdAt,
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [refresh, paginationModel, filters, searchKey]);

    return (
        <>
            <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <Box className="tw-flex tw-justify-end tw-items-center" px={2} py={2}>
                        <Button
                            startIcon={<Add />}
                            type="submit"
                            variant="outlined"
                            disableElevation
                            className="tw-capitalize tw-py-[8px]"
                            onClick={() => navigate(RouteConstant.CREATETEAM)}
                        >
                            Create Team
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <TeamTable
                data={data}
                loading={dataLoading}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    );
};
