import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import PaymentTableInterface from "_models/data/payment/data.payment.model";
import { Button } from "@mui/material";

const PaymentTable: React.FC<
  PaymentTableInterface
> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
    const { palette } = useTheme();
    const navigate = useNavigate();


    const columns: GridColDef[] = [
      {
        field: "paymentFor", headerName: "Payment For", flex: 1, minWidth: 230,
        renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
      },
      { field: "totalAmount", headerName: "Total Amount", flex: 1, minWidth: 150 },
      {
        field: "status", headerName: "Status", flex: 1, minWidth: 130,
        renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
      },
      {
        field: "transactionId", headerName: "Transaction", flex: 1, minWidth: 150,
        renderCell: (params) => <Button
          onClick={() => navigate(`${RouteConstant.TRANSACTIONS}/${params?.value}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          Details
        </Button>
      },
      { field: "MSISDN", headerName: "MSISDN", flex: 1, minWidth: 150 },
      {
        field: "paymentBy", headerName: "Payment By", flex: 1, minWidth: 130,
        renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
      },
      {
        field: "paidTime",
        headerName: "Paid Time",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => moment(params.value).format("MMM D YYYY h:mm A"),
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Box className="tw-flex tw-justify-center tw-items-center">
                <IconButton
                  size="small"
                  onClick={() =>
                    params?.row?.id &&
                    navigate(
                      `${RouteConstant.PAYMENTS}/${params?.row?.id}`
                    )
                  }
                  sx={{ color: palette.secondary.main }}
                >
                  <Tooltip title="View Details">
                    <VisibilityIcon color="inherit" fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            </>
          );
        },
      },
    ];
    return (
      <>
        <Box mt={2}>
          <CustomDataGrid
            loading={loading}
            columns={columns}
            rows={data || []}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      </>
    );
  };

export default PaymentTable;
