import { Box, Button } from "@mui/material";
import { BlockUnblockServiceUser, GetByServiceUserId, GetByUserId } from "functions/http-requests/user";
import { useEffect, useState } from "react";
import BlockIcon from '@mui/icons-material/Block';
import { toggleLoading } from "state/reducers/loading";
import { useDispatch } from "react-redux";
import StatusDialog from "parts/customDialog/statusDialog";
import { UserActivationProps } from "_interfaces/functions/http-requests/user";
import { setAlert } from "state/reducers/alert";
import DoneIcon from '@mui/icons-material/Done';

interface Props {
    ID: string
}

const UserActivation: React.FC<Props> = ({ ID }) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [openBlock, setOpenBlock] = useState<boolean>(false);
    const [userName, setUserName] = useState<string>("");
    const Dispatch = useDispatch();

    const toggleBlockForm = () => {
        setOpenBlock(!openBlock);
    };
    const handleCloseBlockDialog = () => {
        setOpenBlock(false)
    }

    const fetchUser = () => {
        Dispatch(toggleLoading(true));
        GetByServiceUserId(ID).then(res => {
            const data = res?.data;
            if (data?.level === "success") {

                let DATA: any = res?.data?.data;
                setIsActive(DATA?.isActive);
                setUserName(DATA?.name);
            }
        }).catch((err) => {
        }).finally(() => {
            Dispatch(toggleLoading(false));
        });
    }

    const handleBlock = async () => {
        Dispatch(toggleLoading(true));
        const payload: UserActivationProps = {
            DATA: {
                activation: isActive ? false : true,
                id: ID
            }
        }
        await BlockUnblockServiceUser(payload).then(res => {
            if (res.data.level !== "success") {
                Dispatch(setAlert({ type: "error", message: res.data.message }));
                return;
            };
            Dispatch(setAlert({ type: "success", message: res.data.message }));
        })
            .catch((err: any) => Dispatch(setAlert({ type: "error", message: err.response.data.message })))
            .finally(() => Dispatch(toggleLoading(false)));
        fetchUser();
        handleCloseBlockDialog();
    }

    useEffect(() => {
        fetchUser();
    }, [])

    return (
        <>
            <Button
                onClick={toggleBlockForm}
                variant="outlined"
                startIcon={isActive ? <BlockIcon /> : <DoneIcon />}
                disableElevation
                color={!isActive ? "success" : "error"}
            >
                <Box component="span" className="tw-line-clamp-1">
                    {!isActive ? "Unblock" : "Block"} User
                </Box>
            </Button>
            <StatusDialog
                open={openBlock}
                onClose={() => {
                    handleCloseBlockDialog();
                }}
                onChangeStatus={handleBlock}
                title={isActive ? "Block User" : "Unblock User"}
                content={`Are you sure to ${isActive ? "Block" : "Unblock"
                    } <strong>"${userName}"</strong>? `}
            />
        </>
    )
}

export default UserActivation;