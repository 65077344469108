interface InfluencerGenerateCouponLinkErrorModelProps {
  expiryDate?: string;
}

const defaultInfluencerFilterModelProps: InfluencerGenerateCouponLinkErrorModelProps =
  {
    expiryDate: "",
  };

export class InfluencerGenerateCouponLinkErrorModel {
  expiryDate?: string;

  constructor(
    data: InfluencerGenerateCouponLinkErrorModelProps = defaultInfluencerFilterModelProps
  ) {
    this.expiryDate = data.expiryDate || "";
  }
}
