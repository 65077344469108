import { CouponModel } from "_models/data/coupon/data.coupon.model";
import moment from "moment";

export const validateCoupon = (DATA: CouponModel, isEditing?: boolean) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  const couponRegex = /^[a-zA-Z0-9-]+$/;

  if (!DATA?.category?.trim()) {
    valid = false;
    errors.push({
      name: "category",
      error: "Category is required.",
    });
  }

  if (!DATA?.applyOn?.trim()) {
    valid = false;
    errors.push({
      name: "applyOn",
      error: "Apply On is required.",
    });
  }

  if (!DATA?.validFrom) {
    valid = false;
    errors.push({
      name: "validFrom",
      error: "Valid From is required.",
    });
  }

  if (!DATA?.validUntil) {
    valid = false;
    errors.push({
      name: "validUntil",
      error: "Valid Until is required.",
    });
  }

  if (!DATA?.discountType) {
    valid = false;
    errors.push({
      name: "discountType",
      error: "Discount Type is required.",
    });
  }

  if (!DATA?.discountValue) {
    valid = false;
    errors.push({
      name: "discountValue",
      error: "Discount Value is required.",
    });
  }

  const today = moment().startOf("day");

  // if (!isEditing && DATA?.validFrom) {
  //   const validFrom = moment(DATA.validFrom).startOf("day");

  //   if (validFrom.isBefore(today)) {
  //     valid = false;
  //     errors.push({
  //       name: "validFrom",
  //       error: "Valid From cannot be in the past.",
  //     });
  //   }
  // }

  if (DATA?.validFrom && DATA?.validUntil) {
    const validFrom = moment(DATA.validFrom).startOf("day");
    const validUntil = moment(DATA.validUntil).startOf("day");

    if (validFrom.isSame(validUntil)) {
      valid = false;
      errors.push({
        name: "validUntil",
        error: "Validity start and end date cannot be the same.",
      });
    } else if (!validFrom.isBefore(validUntil)) {
      valid = false;
      errors.push({
        name: "validUntil",
        error: "Assign date must be before expiry date.",
      });
    }
  }

  if (DATA?.couponCode && !DATA.couponCode.trim()) {
    valid = false;
    errors.push({
      name: "couponCode",
      error: "Invalid coupon code",
    });
  } else if (DATA?.couponCode?.trim()) {
    if (DATA?.couponCode.length < 4 || DATA?.couponCode?.length > 10) {
      valid = false;
      errors.push({
        name: "couponCode",
        error: "Invalid coupon code",
      });
    } else if (!couponRegex.test(DATA?.couponCode)) {
      valid = false;
      errors.push({
        name: "couponCode",
        error: "Invalid coupon code",
      });
    }
  }

  return { valid, errors };
};
