import { PackageBundleModel } from "_models/data/package-bundle/data.package-bundle.model";

export const validatePackageBundle = (DATA: PackageBundleModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.packageName?.trim()) {
    valid = false;
    errors.push({
      name: "packageName",
      error: "Package name is required.",
    });
  }

  if (!DATA?.isValidityUnlimited && !DATA?.validity?.trim()) {
    valid = false;
    errors.push({
      name: "validity",
      error: "Validity is required.",
    });
  }

  if (
    DATA?.validity?.trim() &&
    (isNaN(Number(DATA.validity)) || Number(DATA.validity) <= 0)
  ) {
    valid = false;
    errors.push({
      name: "validity",
      error: "Validity must be a positive number.",
    });
  }

  if (!DATA?.dataVolume_isUnlimited) {
    if (
      DATA?.dataVolume_quantity?.trim() &&
      (isNaN(Number(DATA.dataVolume_quantity)) ||
        Number(DATA.dataVolume_quantity) < 0)
    ) {
      valid = false;
      errors.push({
        name: "dataVolume_quantity",
        error: "Quantity cannot be negative.",
      });
    }

    if (
      DATA?.dataVolume_quantity?.trim() &&
      !DATA?.dataVolume_dataUnit?.trim()
    ) {
      valid = false;
      errors.push({
        name: "dataVolume_dataUnit",
        error: "Select Data Unit",
      });
    }
  }

  if (
    DATA?.allNetMinutes?.trim() &&
    (isNaN(Number(DATA.allNetMinutes)) || Number(DATA.allNetMinutes) < 0)
  ) {
    valid = false;
    errors.push({
      name: "allNetMinutes",
      error: "Minutes cannot be negative.",
    });
  }

  if (
    DATA?.onNetMinutes?.trim() &&
    (isNaN(Number(DATA.onNetMinutes)) || Number(DATA.onNetMinutes) < 0)
  ) {
    valid = false;
    errors.push({
      name: "onNetMinutes",
      error: "Minutes cannot be negative.",
    });
  }

  if (
    DATA?.offNetMinutes?.trim() &&
    (isNaN(Number(DATA.offNetMinutes)) || Number(DATA.offNetMinutes) < 0)
  ) {
    valid = false;
    errors.push({
      name: "offNetMinutes",
      error: "Minutes cannot be negative.",
    });
  }

  if (
    DATA?.allNetSMS?.trim() &&
    (isNaN(Number(DATA.allNetSMS)) || Number(DATA.allNetSMS) < 0)
  ) {
    valid = false;
    errors.push({
      name: "allNetSMS",
      error: "SMS cannot be negative.",
    });
  }

  if (
    DATA?.onNetSMS?.trim() &&
    (isNaN(Number(DATA.onNetSMS)) || Number(DATA.onNetSMS) < 0)
  ) {
    valid = false;
    errors.push({
      name: "onNetSMS",
      error: "SMS cannot be negative.",
    });
  }

  if (
    DATA?.offNetSMS?.trim() &&
    (isNaN(Number(DATA.offNetSMS)) || Number(DATA.offNetSMS) < 0)
  ) {
    valid = false;
    errors.push({
      name: "offNetSMS",
      error: "SMS cannot be negative.",
    });
  }

  if (
    DATA?.internationalCallMinutes?.trim() &&
    (isNaN(Number(DATA.internationalCallMinutes)) ||
      Number(DATA.internationalCallMinutes) < 0)
  ) {
    valid = false;
    errors.push({
      name: "internationalCallMinutes",
      error: "Minutes cannot be negative.",
    });
  }

  if (!DATA?.packageFor?.trim()) {
    valid = false;
    errors.push({
      name: "packageFor",
      error: "Select package for",
    });
  }

  if (!DATA?.packageID?.trim()) {
    valid = false;
    errors.push({
      name: "packageID",
      error: "Package ID is required.",
    });
  }

  if (!DATA?.retailPrice?.trim()) {
    valid = false;
    errors.push({
      name: "retailPrice",
      error: "Retail Price is required.",
    });
  }

  if (
    DATA?.retailPrice?.trim() &&
    (isNaN(Number(DATA.retailPrice)) || Number(DATA.retailPrice) < 0)
  ) {
    valid = false;
    errors.push({
      name: "retailPrice",
      error: "Price cannot be negative.",
    });
  }

  if (!DATA?.category?.trim()) {
    valid = false;
    errors.push({
      name: "category",
      error: "Please select category.",
    });
  }

  if (!DATA?.packageType?.length) {
    valid = false;
    errors.push({
      name: "packageType",
      error: "Please select package type.",
    });
  }

  return { valid, errors };
};
