import DataGridTableInterface from "_interfaces/_common/dataGridTable";

export interface WalletTransactionFilterModelProps {
  paymentType?: 'credit' | 'debit' | '';
  fromAmount?: number | null;  // Amount range filters
  toAmount?: number | null;
  fromDate?: string | null;  // YYYY-MM-DD format for date filters
  toDate?: string | null;    // YYYY-MM-DD
}

const defaultPaymentFilterModelProps: WalletTransactionFilterModelProps = {
  paymentType: '',            // Default payment type
  fromAmount: null,           // Default fromAmount
  toAmount: null,             // Default toAmount
  fromDate: null,             // Default fromDate
  toDate: null,               // Default toDate
};

export class WalletTransactionFilterModel {
  paymentType?: 'credit' | 'debit' | '';
  fromAmount?: number | null;
  toAmount?: number | null;
  fromDate?: string | null;
  toDate?: string | null;

  constructor(data: WalletTransactionFilterModelProps = defaultPaymentFilterModelProps) {
    this.paymentType = data.paymentType || '';
    this.fromAmount = data.fromAmount || null;
    this.toAmount = data.toAmount || null;
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
