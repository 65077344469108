import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import { toTitleCase } from "functions/helper";
import moment from "moment";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React from "react";
import LotteryTableInterface from "_interfaces/promotion/lottery/lotteryTable";

const LotteryTable: React.FC<LotteryTableInterface> = ({
  loading,
  data,
  setEditId,
}) => {
  const { palette } = useTheme();

  const columns: GridColDef[] = [
    {
      field: "promotionType",
      headerName: "Promotion Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params?.value ? (
          <Tooltip title={params?.value}>
            <Box>{toTitleCase(params.value)} </Box>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "promotionConsumer",
      headerName: "Consumer",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params?.value ? (
          <Tooltip title={params?.value}>
            <Box>{toTitleCase(params.value)} </Box>
          </Tooltip>
        ) : (
          ""
        ),
    },
    {
      field: "showInPage",
      headerName: "Pages",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        params?.value?.length ? (
          <Tooltip title={params?.value?.join(", ")}>
            <Box className="tw-truncate">{params?.value?.join(", ")}</Box>
          </Tooltip>
        ) : (
          ""
        ),
    },
    {
      field: "assignDate",
      headerName: "Assign Date",
      minWidth: 150,
      valueFormatter(params) {
        return params?.value ? moment(params.value).format("Do MMM YYYY") : "";
      },
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      minWidth: 150,
      valueFormatter(params) {
        return params?.value ? moment(params.value).format("Do MMM YYYY") : "";
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      renderCell: (params) => <IsActiveCell status={params?.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() => params?.row?.id && setEditId(params?.row?.id)}
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data}
          paginationMode="client"
        />
      </Box>
    </>
  );
};

export default LotteryTable;
