import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React, { } from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import PhoneBookingTableInterface from "_interfaces/functions/http-requests/bookings";
import EditIcon from "@mui/icons-material/Edit";

const PhoneBookingTable: React.FC<
  PhoneBookingTableInterface
> = ({
  loading,
  data,
  count,
  paginationModel,
  setEditId,
  setPaginationModel,
}) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const getColor = (value: string) => {
      if (!value) return;
      if (value == "cancelled" || value == "expired") {
        return "red";
      }
      if (value == "registered") {
        return "green";
      }
      return "#585757";
    }


    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        minWidth: 200,
      },
      { field: "email", headerName: "Email", flex: 1, minWidth: 270 },
      { field: "phoneNumber", headerName: "Phone", flex: 1, minWidth: 120 },
      {
        field: "identityType", headerName: "Identity Type", flex: 1, minWidth: 130,
        renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
      },
      { field: "MSISDN", headerName: "MSISDN", flex: 1, minWidth: 150 },
      {
        field: "status", headerName: "Status", flex: 1, minWidth: 100,
        renderCell: (params) => <Box sx={{ color: getColor(params?.value?.toLowerCase()) }} className="tw-capitalize">{params.value}</Box>
      },
      {
        field: "bookingTime",
        headerName: "Booking Time",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => moment(params.value).format("MMM D YYYY h:mm A"),
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 80,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Box className="tw-flex tw-justify-center tw-items-center">
                <IconButton
                  size="small"
                  onClick={() => params?.row?.id && setEditId(params?.row?.id)}
                  sx={{ color: palette.secondary.main }}
                >
                  <Tooltip title="Edit">
                    <EditIcon color="inherit" fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            </>
          );
        },
      },
      {
        field: "seeDeatils",
        headerName: "See Details",
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Box className="tw-flex tw-justify-center tw-items-center">
                <IconButton
                  size="small"
                  onClick={() =>
                    params?.row?.id &&
                    navigate(
                      `${RouteConstant.BOOKINGS}/${params?.row?.id}`
                    )
                  }
                  sx={{ color: palette.secondary.main }}
                >
                  <Tooltip title="View Details">
                    <VisibilityIcon color="inherit" fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            </>
          );
        },
      },
    ];
    return (
      <>
        <Box mt={2}>
          <CustomDataGrid
            loading={loading}
            columns={columns}
            rows={data || []}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      </>
    );
  };

export default PhoneBookingTable;
