import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import SimManagementDetailsInterface from "_interfaces/sim-management/details";
import axios from "axios";
import BlockIcon from "component/_common/icons/block";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { GetSimById } from "functions/http-requests/simManagement";
import { RouteConstant } from "navigation/constant";
import CustomDialog from "parts/customDialog";
import SectionSearch from "parts/sectionSearch";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import BlockSimCard from "./blockSimCard";
import CustomerInformationTable from "./customerInformationTable";
import DeactivateSimCard from "./deactivateSimCard";
import SimInformationTable from "./simInformationTable";

interface Props {
  id: string;
}

const SimManagementDetailsLayout: React.FC<Props> = ({ id }) => {
  const { palette } = useTheme();
  const Dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeItem, setActiveItem] = useState("simInformation");

  const [openBlockSim, setOpenBlockSim] = useState<boolean>(false);
  const [openDeactivateSim, setOpenDeactivateSim] = useState<boolean>(false);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<SimManagementDetailsInterface | null>(
    null
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) setAnchorEl(null);
    if (openBlockSim) setOpenBlockSim(false);
    if (openDeactivateSim) setOpenDeactivateSim(false);
  };

  const open = Boolean(anchorEl);
  const manageSimButtonId = open ? "manage-sim-button" : undefined;

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetSimById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            simInfo: {
              simType: DATA?.SIM_type,
              serviceType: DATA?.serviceType,
              carton: DATA?.carton,
              ICCID: DATA?.ICCID,
              MSISDN: DATA?.MSISDN,
              MSISDN_id: DATA?.MSISDN_id,
              assignedDate: DATA?.assignedDate,
              isReserved: DATA?.isReserved,
              status: DATA?.status,
            },
            customerInfo: {
              id: DATA?.customerDetails?._id,
              nameAsDocument: DATA?.customerDetails?.nameAsDocument,
              identityType: DATA?.customerDetails?.identityType,
              MSISDN: DATA?.customerDetails?.MSISDN,
            },
          }));
        } else {
          setState(null);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
          setIsLoaded(true);
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, refresh, Dispatch]);

  const manageSimButtons = [
    { title: "Edit SIM Details", Icon: <EditOutlinedIcon /> },
    {
      title: "Block SIM Card",
      Icon: <BlockIcon color={palette.text.primary} width={18} height={18} />,
      handleClick: () => setOpenBlockSim(true),
    },
    {
      title: "Deactivate SIM",
      Icon: <CancelOutlinedIcon />,
      handleClick: () => setOpenDeactivateSim(true),
    },
  ];

  const listItems = [
    {
      id: "simInformation",
      title: "SIM Information",
      component: <SimInformationTable data={state} />,
    },
    {
      id: "customerDetails",
      title: "Customer Details",
      component: <CustomerInformationTable data={state} />,
    },
    { id: "usageInformation", title: "Usage Information", component: "" },
    { id: "transactionHistory", title: "Transaction History", component: "" },
  ];

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.SIM_MANAGEMENT} />;

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SectionSearch />
          </Grid>
          <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
            <Button
              variant="contained"
              aria-describedby={manageSimButtonId}
              onClick={handleClick}
              endIcon={<ExpandMoreIcon />}
            >
              Manage SIM
            </Button>
            <Popover
              id={manageSimButtonId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box
                className="tw-rounded-md tw-drop-shadow tw-flex tw-flex-col tw-px-[12px] tw-py-[8px]"
                sx={{ color: palette.text.primary }}
              >
                {manageSimButtons?.map(({ title, Icon, handleClick }, i) => (
                  <Button
                    key={i}
                    variant="outlined"
                    color="inherit"
                    startIcon={Icon}
                    className="tw-my-[4px] tw-text-[14px] tw-font-[400]"
                    sx={{ color: palette.text.primary }}
                    onClick={handleClick}
                  >
                    {title}
                  </Button>
                ))}
              </Box>
            </Popover>
          </Grid>
        </Grid>
      </Box>

      <Box pt={5}>
        {listItems?.map(({ id, title, component }, i) => (
          <Fragment key={i}>
            <Box className="tw-border-b">
              <Button
                variant={activeItem === id ? "contained" : "text"}
                color={activeItem === id ? "primary" : "secondary"}
                fullWidth
                onClick={() => setActiveItem(id)}
                endIcon={
                  activeItem === id ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                className="tw-flex tw-justify-between tw-text-[14px] tw-rounded-none"
              >
                {title}
              </Button>
            </Box>
            {activeItem === id ? (
              <Box pt={2} pb={5} px={2}>
                {component}
              </Box>
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </Box>

      <CustomDialog open={openBlockSim} onClose={handleClose} showCloseButton>
        <BlockSimCard onClose={handleClose} />
      </CustomDialog>

      <CustomDialog
        open={openDeactivateSim}
        onClose={handleClose}
        showCloseButton
      >
        <DeactivateSimCard onClose={handleClose} />
      </CustomDialog>
    </>
  );
};

export default SimManagementDetailsLayout;
