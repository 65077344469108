import { WalletTransactionDetailsInterface } from "_interfaces/walletTransaction";
import { AppStatusCode } from "config/appStatusCode";
import { GetWalletTransactionDetails } from "functions/http-requests/wallet-transaction";
import { GetWalletPaymentDetails } from "functions/http-requests/walletPayments";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import WalletTransactionDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const WalletTransactionDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<WalletTransactionDetailsInterface | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetWalletTransactionDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        paymentType: DATA?.paymentType,
                        paidTime: DATA?.paidTime,
                        paymentId: DATA?.paymentId,
                        amount: DATA?.amount,
                        reason: DATA?.reason,
                        PIN_try: DATA?.PIN_try,
                        status: DATA?.status,
                        wallet: DATA?.wallet,
                        walletID: DATA?.walletID,
                        walletPayment: DATA?.walletPayment,
                        walletTransactionId: DATA?.walletTransactionId,
                        message: DATA?.message,
                        globalActivityId: DATA?.globalActivityId,
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <WalletTransactionDetailsTable data={details} />
        </>
    )
}