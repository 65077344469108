import { TeamDetailsInterface } from "_interfaces/teams";
import { AppStatusCode } from "config/appStatusCode";
import { AutoAssignTask, GetTeamDetails, RemoveAssignTask } from "functions/http-requests/teams";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import TeamDetailsTable from "./detailsTable";
import { AutoAssignTaskProps } from "_interfaces/functions/http-requests/teams";
import { SelectChangeEvent, Dialog, DialogTitle, DialogContent, Select, MenuItem, DialogActions, Button, Box, Grid } from "@mui/material";
import { setAlert } from "state/reducers/alert";
import CreateIcon from '@mui/icons-material/Create';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface Props {
    id: string
}

export const TeamDetailsLayout: React.FC<Props> = ({ id }) => {
    const items: string[] = ["company_application",
        "deregistration",
        "sim_swap",
        "company_application",
        "influencer_application",
        "partner_application"]
    const [details, setDetails] = useState<TeamDetailsInterface | null>(null);
    const Dispatch = useDispatch();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState("");
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [assignType, setAssignType] = useState<string>("add");

    const handleDialogOpen = (type: string) => {
        if (type === "remove") {
            setSelectedItems(details?.autoAssignTasks.flat() as string[]);
            setAssignType("remove");
        } else {
            const excludedItems = details?.autoAssignTasks.flat() || [];
            const filteredItems = items.filter(item => !excludedItems.includes(item));
            setSelectedItems(filteredItems);
            setAssignType("add");
        }
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedTask("");
        setSelectedItems([]);
    };

    const handleTaskChange = (event: SelectChangeEvent) => {
        setSelectedTask(event.target.value as string);
    }

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetTeamDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        teamID: DATA?.teamID,
                        name: DATA?.name,
                        location: DATA?.location,
                        isActive: DATA?.isActive,
                        manager: DATA?.manager,
                        autoAssignTasks: DATA?.autoAssignTasks,
                        createdAt: DATA?.createdAt,
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    const handleAssign = async () => {
        if (!selectedTask || !id) {
            Dispatch(
                setAlert({
                    type: "warning",
                    message: "Please select a task for assign!",
                })
            );
            return;
        }

        const props: AutoAssignTaskProps = {
            DATA: {
                teamId: id,
                taskCategory: selectedTask
            }
        }
        if (assignType == "add") {

            AutoAssignTask(props)
                .then(res => {
                    const data = res?.data;
                    if (
                        data?.statusCode === AppStatusCode.api_success ||
                        data?.statusCode === AppStatusCode.api_updated
                    ) {
                        const DATA = data?.data;
                        Dispatch(
                            setAlert({
                                type: "success",
                                message: data?.message,
                            })
                        );
                        handleDialogClose();
                        handleFetchById(id)
                    } else {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: "There is some issue in assigning tasks",
                            })
                        );
                    }
                })
                .catch(err => {
                    const errorMessage = err?.response?.data?.message;
                    if (errorMessage) {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: errorMessage,
                            })
                        );
                    }
                    else {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: "There is some error is assigning tasks",
                            })
                        );
                    }
                })
        }
        else {
            RemoveAssignTask(props)
                .then(res => {
                    const data = res?.data;
                    if (
                        data?.statusCode === AppStatusCode.api_success ||
                        data?.statusCode === AppStatusCode.api_updated
                    ) {
                        const DATA = data?.data;
                        Dispatch(
                            setAlert({
                                type: "success",
                                message: data?.message,
                            })
                        );
                        handleDialogClose();
                        handleFetchById(id)
                    } else {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: "There is some issue in assigning tasks",
                            })
                        );
                    }
                })
                .catch(err => {
                    const errorMessage = err?.response?.data?.message;
                    if (errorMessage) {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: errorMessage,
                            })
                        );
                    }
                    else {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: "There is some error is assigning tasks",
                            })
                        );
                    }
                })
        }
    }

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                    <Box className="tw-flex tw-justify-end tw-items-center" px={1} py={1}>
                        {(details?.autoAssignTasks && details?.autoAssignTasks?.length > 0) && <Button
                            startIcon={<RemoveCircleOutlineIcon />}
                            type="submit"
                            variant="outlined"
                            disableElevation
                            className="tw-capitalize tw-py-[8px]"
                            onClick={() => handleDialogOpen("remove")}
                            sx={{
                                marginRight: "20px",
                                color: "#de4242",
                                border: "1px solid #de4242"
                            }}
                        >
                            Remove Task
                        </Button>}
                        <Button
                            startIcon={<CreateIcon />}
                            type="submit"
                            variant="outlined"
                            disableElevation
                            className="tw-capitalize tw-py-[8px]"
                            onClick={() => handleDialogOpen("add")}
                        >
                            Assign Task
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <TeamDetailsTable data={details} />

            {/* assign taskdialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '90%' } }} >
                <DialogTitle>Select Task</DialogTitle>
                <DialogContent>
                    <Select
                        value={selectedTask}
                        onChange={handleTaskChange}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select a Task
                        </MenuItem>
                        {
                            selectedItems.map(item => (
                                <MenuItem value={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAssign} color="primary">
                        {assignType == "add" ? "Assign" : "Remove"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}