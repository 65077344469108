import { Box, Button, Grid } from "@mui/material";
import { AutoCompleteOptionsProps } from "_interfaces/_common/forms";
import { PaymentFilterModel } from "_models/data/payment/data.payment-filter.model";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import moment from "moment";
import FilterButton from "parts/filterButton";
import SideDrawer from "parts/sideDrawer";
import { Fragment } from "react/jsx-runtime";
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import Badge from '@mui/material/Badge';
import Text from "component/_common/forms/text";

interface filterList {
    type?: string,
    name: string,
    label?: string,
    placeholder?: string,
    options?: AutoCompleteOptionsProps[],
    multiple?: boolean,
}


interface Props {
    filters: any;
    setFilters: React.Dispatch<
        React.SetStateAction<any>
    >;
    open: boolean;
    setOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    filterList: filterList[];
}

export const CustomFilter: React.FC<Props> = ({ filters, setFilters, open, setOpen, filterList }) => {
    const onClose = () => {
        setOpen(false);
    }

    const isFilterApplied = () => {
        return Object.keys(filters).some(key => filters[key]);
    };

    const clearFilters = () => {
        setFilters({});
    };

    const handleAutoComplete = (
        e: React.SyntheticEvent,
        value: any,
        name: string,
        multiple?: boolean
    ) => {
        setFilters((prev: any) => ({
            ...prev,
            [name]: multiple
                ? value?.map(
                    (e: { value: string | number; id: string | number }) =>
                        e?.value || e?.id
                )
                : value?.value || value?.id,
        }));
    };

    const handleNumber = (
        e: React.SyntheticEvent,
        value: any,
        name: string) => {
        setFilters((prev: any) => ({
            ...prev,
            [name]: value ? parseInt(value) : null
        }));
    }

    return (
        <>
            <Badge color="primary" invisible={!isFilterApplied()} badgeContent=" ">
                <FilterButton onClick={() => setOpen(true)} />
            </Badge>

            <SideDrawer open={open} onClose={onClose}>
                <Box id="filter_customer_management">
                    <Box className='filter-button'>
                        <Button variant="outlined" onClick={clearFilters} disabled={!isFilterApplied()}><FilterListOffIcon /></Button>
                    </Box>
                    <Grid container rowSpacing={3} columnSpacing={2}>
                        {filterList.map(
                            (
                                { type, name, label, placeholder, options, multiple },
                                index
                            ) => (
                                <Fragment key={index}>
                                    <>
                                        {type === "autoComplete" && options?.length ? (
                                            <Grid item xs={12}>
                                                <InputAutoComplete
                                                    name={name}
                                                    options={options}
                                                    label={label}
                                                    placeholder={placeholder}
                                                    onChange={(e, v, m) =>
                                                        handleAutoComplete(e, v, name, m)
                                                    }
                                                    value={
                                                        options &&
                                                        options?.length &&
                                                        filters?.[
                                                        name as keyof PaymentFilterModel
                                                        ]
                                                    }
                                                    multiple={multiple}
                                                />
                                            </Grid>
                                        ) : type === "dateRange" ? (
                                            <Grid item xs={12}>
                                                <CustomDateRangePicker
                                                    label={label}
                                                    value={[
                                                        filters?.fromDate
                                                            ? moment(filters.fromDate).toDate()
                                                            : null,
                                                        filters.toDate
                                                            ? moment(filters.toDate).toDate()
                                                            : null,
                                                    ]}
                                                    onChange={(newValue: [Date | null, Date | null]) => {
                                                        setFilters(
                                                            (prev: PaymentFilterModel) => ({
                                                                ...prev,
                                                                fromDate: newValue[0]
                                                                    ? moment(newValue[0]).format('YYYY/MM/DD')
                                                                    : null,
                                                                toDate: newValue[1]
                                                                    ? moment(newValue[1]).format('YYYY/MM/DD')
                                                                    : null,
                                                            })
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        ) : type === "number" ? (
                                            <Grid item xs={12}>
                                                <Text
                                                    type={"number"}
                                                    name={name}
                                                    label={label}
                                                    placeholder={placeholder}
                                                    value={filters[name]}
                                                    onChange={(e) => handleNumber(e, e.target.value, name)}
                                                />
                                                {/* <CustomDateRangePicker
                                                    label={label}
                                                    value={[
                                                        filters?.fromAmount
                                                            ? moment(filters.fromAmount).toDate()
                                                            : null,
                                                        filters.toAmount
                                                            ? moment(filters.toAmount).toDate()
                                                            : null,
                                                    ]}
                                                    onChange={(newValue: [Date | null, Date | null]) => {
                                                        setFilters(
                                                            (prev: PaymentFilterModel) => ({
                                                                ...prev,
                                                                fromAmount: newValue[0]
                                                                    ? moment(newValue[0]).format('YYYY/MM/DD')
                                                                    : null,
                                                                toAmount: newValue[1]
                                                                    ? moment(newValue[1]).format('YYYY/MM/DD')
                                                                    : null,
                                                            })
                                                        );
                                                    }}
                                                /> */}
                                            </Grid>
                                        )
                                            : (
                                                <></>
                                            )}
                                    </>
                                </Fragment>
                            )
                        )}
                    </Grid>
                </Box>
            </SideDrawer>
        </>
    )
}