import { GridPaginationModel } from "@mui/x-data-grid";
import { PaymentFilterProps } from "_interfaces/functions/http-requests/payment";
import { PaymentFilterModel } from "_models/data/payment/data.payment-filter.model";
import PaymentTableInterface from "_models/data/payment/data.payment.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetPaymentList } from "functions/http-requests/payment";
import { useRef, useState, useEffect } from "react";
import PaymentTable from "./paymentTable";
import { Grid, Box } from "@mui/material";
import SectionSearch from "parts/sectionSearch";
import PaymentFilter from "./paymentFilter";

export const PaymentsLayout = () => {
    const isInitialRender = useRef(true);
    const [searchKey, setSearchKey] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const handleClearSearch = () => setSearchKey("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<PaymentTableInterface["data"]>(
        []
    );
    const [count, setCount] = useState<number>(0);
    const [filters, setFilters] = useState<PaymentFilterModel>(
        new PaymentFilterModel()
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: PaymentFilterProps["DATA"] = {
                searchKey: searchKey,
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                status: filters.status || "",
                fromAmount: filters.fromAmount,
                toAmount: filters.toAmount,
                paymentBy: filters.paymentBy,
                paymentFor: filters?.paymentFor,
                paymentMethod: filters?.paymentMethod,
                paymentType: filters?.paymentType,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetPaymentList({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            MSISDN: item?.MSISDN,
                            paymentID: item?.paymentID,
                            totalAmount: item?.totalAmount,
                            paymentMethod: item?.paymentMethod,
                            status: item?.status,
                            paymentType: item?.paymentType,
                            paymentBy: item?.paymentBy,
                            paymentFor: item?.paymentFor,
                            paidTime: item?.paidTime,
                            createdAt: item?.createdAt,
                            transactionId:item?.transactionId
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [refresh, paginationModel, filters, searchKey]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                    <Box sx={{ flex: "1 1 auto" }}>
                        <SectionSearch
                            name="search_regional_distributor"
                            value={searchKey}
                            onChange={handleSearch}
                            onClear={handleClearSearch}
                            onSubmit={handleSubmitSearch}
                        />
                    </Box>
                    <PaymentFilter
                        open={openFilter}
                        onClose={toggleFilter}
                        filters={filters}
                        setFilters={setFilters}
                        setOpen={setOpenFilter}
                    />
                </Grid>
            </Grid>
            <PaymentTable
                data={data}
                loading={dataLoading}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    )
}