export interface BookingFilterModelProps {
  fromDate?: string | Date | null;           // YYYY-MM-DD
  toDate?: string | Date | null;   
  status?: 'booked' | 'cancelled' | 'registered' | 'expired' | '';
  bookingBy?: 'self' | 'whole_seller' | 'agent' | '';
  identityType?: 'citizen' | 'tourist' | 'refugee' | '';
}

const defaultBookingFilterModelProps: BookingFilterModelProps = {
  fromDate: "",      // Default fromDate value
  toDate: "", 
  status: '',
  bookingBy: '',
  identityType: ''
};

export class BookingFilterModel {
  fromDate?: string | Date | null;
  toDate?: string | Date | null;
  status?: 'booked' | 'cancelled' | 'registered' | 'expired' | '';
  bookingBy?: 'self' | 'whole_seller' | 'agent' | '';
  identityType?: 'citizen' | 'tourist' | 'refugee' | '';

  constructor(
    data: BookingFilterModelProps = defaultBookingFilterModelProps
  ) {
    this.fromDate = data.fromDate || "";
    this.toDate = data.toDate || "";
    this.status = data.status;
    this.bookingBy = data.bookingBy;
    this.identityType = data.identityType;
  }
}
