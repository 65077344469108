import { Box, Grid } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GLOBAL_ACTIVITY_LOGS_DATA, GLOBAL_ACTIVITY_LOGS_PROPS } from "_interfaces/global-activity-logs";
import { GlobalActivityLogFilterModel } from "_models/data/global-activity-logs/data.global-activity-logs-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { GetGlobalActivityLogsList } from "functions/http-requests/global-activity-logs";
import moment from "moment";
import FilterButton from "parts/filterButton";
import { useEffect, useRef, useState } from "react";
import GlobalActivityLogsTable from "./globalActivityLogsTable";
import SectionSearch from "parts/sectionSearch";
import GlobalActivityLogsFilter from "./global_activity_logs_filter";

export const GLOBAL_ACTIVITY_LOGS_LAYOUT = () => {
    const isInitialRender = useRef(true);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [count, setCount] = useState<number>(0);
    const [searchKey, setSearchKey] = useState<string>("");
    const [data, setData] = useState<GLOBAL_ACTIVITY_LOGS_DATA[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [globalActivityFilters, setGlobalActivityFilters] = useState<GlobalActivityLogFilterModel>(
        new GlobalActivityLogFilterModel()
    );
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    const handleClearSearch = () => setSearchKey("");

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };


    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);
            let PAYLOAD_DATA: GLOBAL_ACTIVITY_LOGS_PROPS["DATA"] = {
                pageNumber: (paginationModel.page + 1).toString(),
                pageSize: paginationModel.pageSize.toString(),
                moduleName: globalActivityFilters.moduleName || "",
                searchKey: searchKey || "",
                level: globalActivityFilters.level || "",
                alertLevel: globalActivityFilters.alertLevel || "",
                actionFromServer: globalActivityFilters.actionFromServer || "",
                actionToServer: globalActivityFilters.actionToServer || "",
                activity: globalActivityFilters.activity || "",
                fromDate: globalActivityFilters?.fromDate
                    ? moment(globalActivityFilters.fromDate).format("YYYY/MM/DD")
                    : "",
                toDate: globalActivityFilters?.toDate
                    ? moment(globalActivityFilters.toDate).format("YYYY/MM/DD")
                    : ""
            };

            GetGlobalActivityLogsList({
                DATA: PAYLOAD_DATA,
            })
                .then((res) => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            activity: item?.activity || "",
                            moduleName: item?.moduleName || "",
                            message: item?.message || "",
                            MSISDN: item?.MSISDN || "",
                            activityId: item?.activityId || "",
                            time: item?.time,
                            level: item?.level,
                            alertLevel: item?.alertLevel,
                            createdAt: item?.createdAt,
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [paginationModel, globalActivityFilters, refresh, searchKey]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                    <Box sx={{ flex: "1 1 auto" }}>
                        <SectionSearch
                            name="search_regional_distributor"
                            value={searchKey}
                            onChange={handleSearch}
                            onClear={handleClearSearch}
                            onSubmit={handleSubmitSearch}
                        />
                    </Box>
                    <FilterButton onClick={() => setOpenFilter(true)} />
                </Grid>
            </Grid>

            <GlobalActivityLogsTable
                data={data}
                loading={dataLoading}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                count={count}
            />

            <GlobalActivityLogsFilter
                open={openFilter}
                onClose={toggleFilter}
                filters={globalActivityFilters}
                setFilters={setGlobalActivityFilters}
            />
        </>
    )
}