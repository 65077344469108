import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  maxWidth?: Breakpoint | false;
  children?: React.ReactNode;
  successButtonName?: string;
  onChangeStatus: () => void;
}
const StatusDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  content,
  maxWidth,
  children,
  successButtonName,
  onChangeStatus,
}) => {
  const handleChangeStatus = () => {
    onChangeStatus();
    onClose();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={maxWidth || "xs"}
        sx={{ minWidth: 220 }}
      >
        <Box p={3}>
          <DialogTitle>{title || "Change status"}</DialogTitle>
          <DialogContent>
            {content ? (
              <Box dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
              <></>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={handleChangeStatus}
              color="warning"
            >
              {successButtonName ? successButtonName : "Change Status"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default StatusDialog;
