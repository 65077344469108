import { WalletDetailsInterface } from "_interfaces/walletDetails";
import { AppStatusCode } from "config/appStatusCode";
import { GetWalletDetails } from "functions/http-requests/walletDetails";
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import WalletDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const WalletDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<WalletDetailsInterface | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetWalletDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        user: DATA?.user,
                        userID: DATA?.userID,
                        walletID: DATA?.walletID,
                        walletFor: DATA?.walletFor,
                        status: DATA?.status,
                        roleId: DATA?.roleId,
                        isActive: DATA?.isActive,
                        createdAt: DATA?.createdAt
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <WalletDetailsTable data={details} />
        </>
    )
}