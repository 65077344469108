interface packageBundleErrorModelProps {
  packageName?: string;
  validity?: string;

  dataVolume_isUnlimited?: string;
  dataVolume_quantity?: string;
  dataVolume_dataUnit?: string;

  dataPerDay_isUnlimited?: string;
  dataPerDay_quantity?: string;
  dataPerDay_dataUnit?: string;

  allNetMinutes?: string;
  allNetMinutes_isUnlimited?: string;
  onNetMinutes?: string;
  onNetMinutes_isUnlimited?: string;
  offNetMinutes?: string;
  offNetMinutes_isUnlimited?: string;

  allNetSMS?: string;
  allNetSMS_isUnlimited?: string;
  onNetSMS?: string;
  onNetSMS_isUnlimited?: string;
  offNetSMS?: string;
  offNetSMS_isUnlimited?: string;

  internationalCallMinutes?: string;
  packageFor?: string;
  packageID?: string;
  retailPrice?: string;
  description?: string;
  category?: string;
  discountFor?: string;
  discountPercent?: string;
  packageType?: string;
  vat?: string;
}

export class packageBundleErrorModel {
  packageName?: string;
  validity?: string;

  dataVolume_isUnlimited?: string;
  dataVolume_quantity?: string;
  dataVolume_dataUnit?: string;

  dataPerDay_isUnlimited?: string;
  dataPerDay_quantity?: string;
  dataPerDay_dataUnit?: string;

  allNetMinutes?: string;
  allNetMinutes_isUnlimited?: string;
  onNetMinutes?: string;
  onNetMinutes_isUnlimited?: string;
  offNetMinutes?: string;
  offNetMinutes_isUnlimited?: string;

  allNetSMS?: string;
  allNetSMS_isUnlimited?: string;
  onNetSMS?: string;
  onNetSMS_isUnlimited?: string;
  offNetSMS?: string;
  offNetSMS_isUnlimited?: string;

  internationalCallMinutes?: string;
  packageFor?: string;
  packageID?: string;
  retailPrice?: string;
  description?: string;
  category?: string;
  discountFor?: string;
  discountPercent?: string;
  packageType?: string;
  vat?: string;

  constructor(data: packageBundleErrorModelProps = {}) {
    this.packageName = data.packageName || "";
    this.validity = data.validity || "";

    this.dataVolume_isUnlimited = data.dataVolume_isUnlimited || "";
    this.dataVolume_quantity = data.dataVolume_quantity || "";
    this.dataVolume_dataUnit = data.dataVolume_dataUnit || "";

    this.dataPerDay_isUnlimited = data.dataPerDay_isUnlimited || "";
    this.dataPerDay_quantity = data.dataPerDay_quantity || "";
    this.dataPerDay_dataUnit = data.dataPerDay_dataUnit || "";

    this.allNetMinutes = data.allNetMinutes || "";
    this.allNetMinutes_isUnlimited = data.allNetMinutes_isUnlimited || "";
    this.onNetMinutes = data.onNetMinutes || "";
    this.onNetMinutes_isUnlimited = data.onNetMinutes_isUnlimited || "";
    this.offNetMinutes = data.offNetMinutes || "";
    this.offNetMinutes_isUnlimited = data.offNetMinutes_isUnlimited || "";

    this.allNetSMS = data.allNetSMS || "";
    this.allNetSMS_isUnlimited = data.allNetSMS_isUnlimited || "";
    this.onNetSMS = data.onNetSMS || "";
    this.onNetSMS_isUnlimited = data.onNetSMS_isUnlimited || "";
    this.offNetSMS = data.offNetSMS || "";
    this.offNetSMS_isUnlimited = data.offNetSMS_isUnlimited || "";

    this.internationalCallMinutes = data.internationalCallMinutes || "";
    this.packageFor = data.packageFor || "";
    this.packageID = data.packageID || "";
    this.retailPrice = data.retailPrice || "";
    this.description = data.description || "";
    this.category = data.category || "";
    this.discountFor = data.discountFor || "";
    this.discountPercent = data.discountPercent || "";
    this.packageType = data.packageType || "";
    this.vat = data.vat || "";
  }
}
