import moment from "moment";
import { LotteryModel } from "_models/data/promotion/lottery/data.lottery.model";

export const validateLottery = (DATA: LotteryModel, isEditing?: boolean) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.promotionType?.trim()) {
    valid = false;
    errors.push({
      name: "promotionType",
      error: "Promotion type is required.",
    });
  }

  if (!DATA?.promotionConsumer?.trim()) {
    valid = false;
    errors.push({
      name: "promotionConsumer",
      error: "Promotion consumer is required.",
    });
  }

  if (!DATA?.playBasedOn?.trim()) {
    valid = false;
    errors.push({
      name: "playBasedOn",
      error: "Play based on is required.",
    });
  }

  if (!DATA?.promotionPrize?.trim()) {
    valid = false;
    errors.push({
      name: "promotionPrize",
      error: "Promotion prize is required.",
    });
  }

  if (!DATA?.maxWinner?.trim()) {
    valid = false;
    errors.push({
      name: "maxWinner",
      error: "Maz Winner is required.",
    });
  }

  if (DATA?.maxWinner?.trim() && Number(DATA.maxWinner) < 0) {
    valid = false;
    errors.push({
      name: "maxWinner",
      error: "Invalid Amount",
    });
  }

  if (!DATA?.playTime) {
    valid = false;
    errors.push({
      name: "playTime",
      error: "Play Time is required.",
    });
  }

  if (!DATA?.startTime) {
    valid = false;
    errors.push({
      name: "startTime",
      error: "Start Time is required.",
    });
  }

  if (!DATA?.assignDate) {
    valid = false;
    errors.push({
      name: "assignDate",
      error: "Assign date is required.",
    });
  }

  if (!DATA?.expiryDate) {
    valid = false;
    errors.push({
      name: "expiryDate",
      error: "Expiry date is required.",
    });
  }

  const today = moment().startOf("day");

  if (!isEditing && DATA?.assignDate) {
    const assignDate = moment(DATA.assignDate).startOf("day");

    if (assignDate.isBefore(today)) {
      valid = false;
      errors.push({
        name: "assignDate",
        error: "Assign date cannot be in the past.",
      });
    }
  }

  if (DATA?.assignDate && DATA?.expiryDate) {
    const assignDate = moment(DATA.assignDate).startOf("day");
    const expiryDate = moment(DATA.expiryDate).startOf("day");

    if (assignDate.isSame(expiryDate)) {
      valid = false;
      errors.push({
        name: "expiryDate",
        error: "Assign date and expiry date cannot be the same.",
      });
    } else if (!assignDate.isBefore(expiryDate)) {
      valid = false;
      errors.push({
        name: "assignDate",
        error: "Assign date must be before expiry date.",
      });
    }
  }

  return { valid, errors };
};

export const validatePromotionTextTypes = (DATA: LotteryModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.UI_textType?.trim()) {
    valid = false;
    errors.push({
      name: "UI_textType",
      error: "Text type is required.",
    });
  }

  if (!DATA?.UI_text?.trim()) {
    valid = false;
    errors.push({
      name: "UI_text",
      error: "Text is required.",
    });
  }

  return { valid, errors };
};
