import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { GetKycById } from "functions/http-requests/kyc";
import { RouteConstant } from "navigation/constant";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import {
  getKycType,
  KycServerUrl,
  kycServerUrlOrder,
  KycServerUrlType,
} from "_interfaces/functions/http-requests/kyc";
import KycDetailsInterface from "_interfaces/kyc";
import KycServerInfoTable from "./kycServerInfoTable";
import TopStatistics from "./topStatistics";

interface Props {
  id: string;
}

const KycLayout: React.FC<Props> = ({ id }) => {
  const Dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState("customerInfo");

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<KycDetailsInterface | null>(null);

  const [serverResponsesList, setServerResponsesList] = useState<any[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  let handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetKycById(ID)
      .then((res) => {
        const data = res?.data;
        if (
          data?.statusCode === AppStatusCode.api_success ||
          data?.statusCode === AppStatusCode.api_updated
        ) {
          const DATA: any = data?.data;

          const API_Responses = [];

          for (const [key, value] of Object.entries(DATA)) {
            if (value && typeof value === "object" && !Array.isArray(value)) {
              if (
                "isRequired" in value ||
                "isVerified" in value ||
                "status" in value ||
                "tryCount" in value ||
                "API_Responses" in value ||
                "successResponse" in value
              ) {
                API_Responses.push({ [key]: value });
              }
            }
          }

          setServerResponsesList(API_Responses);

          setState((prev) => ({
            ...prev,
            id: DATA?._id,
            KYC_type: DATA?.KYC_type,
            MSISDN_id: DATA?.MSISDN_id,
            MSISDN: DATA?.MSISDN,
            registerBy: DATA?.registerBy,
            customer: DATA?.customer,
            agent: DATA?.agent,
            time: DATA?.time,
            status: DATA?.status,
            createdAt: DATA?.createdAt,
            updatedAt: DATA?.updatedAt,
            selectedPackage: DATA?.selectedPackage || null,
            buildBundle: DATA?.buildBundle || null,
          }));
          setIsLoaded(true);
        } else {
          setState(null);
          setIsLoaded(true);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          setState(null);
          setIsLoaded(true);
        }
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    if (id) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(id);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [id, Dispatch, refresh]);

  if (id && isLoaded && !state?.id)
    return <Navigate to={RouteConstant.CUSTOMER_MANAGEMENT} />;

  return (
    <>
      {isLoaded ? (
        <>
          <TopStatistics data={state} />

          <Box pt={3}>
            {serverResponsesList
              ?.sort((a, b) => {
                const responseKeyA = Object.keys(a)?.[0];
                const responseKeyB = Object.keys(b)?.[0];

                const aIndex = kycServerUrlOrder.indexOf(
                  responseKeyA as KycServerUrlType
                );
                const bIndex = kycServerUrlOrder.indexOf(
                  responseKeyB as KycServerUrlType
                );

                if (aIndex === -1) return 1;
                if (bIndex === -1) return -1;

                return aIndex - bIndex;
              })
              ?.map((item: any, i: number, arr: any) => {
                const responseKey = Object?.keys(item || {})?.[0];
                const responseValue = item?.[responseKey];

                const ApiUrl = KycServerUrl?.[responseKey as KycServerUrlType];
                const type = getKycType(responseKey);

                const NIRA_RESPONSE = arr?.find(
                  (arrItem: any) => Object?.keys(arrItem || {})?.[0] === "NIRA"
                );

                const UCC_RESPONSE = arr?.find(
                  (arrItem: any) => Object?.keys(arrItem || {})?.[0] === "UCC"
                );

                return (
                  <Fragment key={i}>
                    <Box className="tw-border-b">
                      <Button
                        variant={
                          activeItem === responseKey ||
                          !responseValue?.isVerified
                            ? "contained"
                            : "text"
                        }
                        color={
                          !responseValue?.isVerified
                            ? "error"
                            : activeItem === responseKey
                            ? "primary"
                            : "secondary"
                        }
                        fullWidth
                        onClick={() =>
                          activeItem === responseKey
                            ? setActiveItem("")
                            : setActiveItem(responseKey)
                        }
                        endIcon={
                          activeItem === responseKey ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )
                        }
                        className="tw-flex tw-justify-between tw-text-[14px] tw-rounded-none tw-uppercase"
                      >
                        <Box>
                          {responseKey}

                          {type === "mt" ? (
                            <small> MUTUNDWE</small>
                          ) : type === "mb" ? (
                            <small> MBYUA</small>
                          ) : null}
                        </Box>
                      </Button>
                    </Box>

                    {activeItem === responseKey ? (
                      <Box pt={2} pb={5} px={2}>
                        {responseValue ? (
                          <KycServerInfoTable
                            serverName={activeItem}
                            kycId={id}
                            Url={ApiUrl || undefined}
                            type={type}
                            data={responseValue}
                            isRetryEnabled={
                              responseKey === "NIRA" || responseKey === "UCC"
                                ? !NIRA_RESPONSE?.NIRA?.isVerified &&
                                  !UCC_RESPONSE?.UCC?.isVerified
                                : NIRA_RESPONSE?.NIRA?.isVerified ||
                                  UCC_RESPONSE?.UCC?.isVerified
                            }
                            handleRefresh={handleRefresh}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Fragment>
                );
              })}
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default KycLayout;
