import InfluencerLayout from "component/influencer";
import MainContainer from "parts/mainContainer";

const Influencer = () => {
  return (
    <MainContainer>
      <InfluencerLayout />
    </MainContainer>
  );
};

export default Influencer;
