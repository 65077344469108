interface LotteryErrorModelProps {
  id?: string;
  promotionType?: string;
  promotionConsumer?: string;
  playBasedOn?: string;
  playTime?: string;
  count?: string;
  promotionPrize?: string;
  startTime?: string;
  maxWinner?: string;
  showInPage?: string;
  UI?: string;
  UI_theme?: string;
  UI_textType?: string;
  UI_text?: string;
  UI_textSize?: string;
  UI_textColor?: string;
  UI_textPosition?: string;
  UI_textLinePosition?: string;
  UI_textShow?: string;
  assignDate?: string;
  expiryDate?: string;
  isActive?: string;
}
export class LotteryErrorModel {
  id?: string;
  promotionType?: string;
  promotionConsumer?: string;
  playBasedOn?: string;
  playTime?: string;
  count?: string;
  promotionPrize?: string;
  startTime?: string;
  maxWinner?: string;
  showInPage?: string;
  UI?: string;
  UI_theme?: string;
  UI_textType?: string;
  UI_text?: string;
  UI_textSize?: string;
  UI_textColor?: string;
  UI_textPosition?: string;
  UI_textLinePosition?: string;
  UI_textShow?: string;
  assignDate?: string;
  expiryDate?: string;
  isActive?: string;

  constructor(data: LotteryErrorModelProps = {}) {
    this.id = data.id || "";
    this.promotionType = data.promotionType || "";
    this.promotionConsumer = data.promotionConsumer || "";
    this.playBasedOn = data.playBasedOn || "";
    this.playTime = data.playTime || "";
    this.count = data.count || "";
    this.promotionPrize = data.promotionPrize || "";
    this.startTime = data.startTime || "";
    this.maxWinner = data.maxWinner || "";
    this.showInPage = data.showInPage || "";
    this.UI = data.UI || "";
    this.UI_theme = data.UI_theme || "";
    this.UI_textType = data.UI_textType || "";
    this.UI_text = data.UI_text || "";
    this.UI_textSize = data.UI_textSize || "";
    this.UI_textColor = data.UI_textColor || "";
    this.UI_textPosition = data.UI_textPosition || "";
    this.UI_textLinePosition = data.UI_textLinePosition || "";
    this.UI_textShow = data.UI_textShow || "";
    this.assignDate = data.assignDate || "";
    this.expiryDate = data.expiryDate || "";
    this.isActive = data.isActive || "";
  }
}
