interface InfluencerGenerateCouponLinkModelProps {
  expiryDate?: Date | null;
  couponLink?: string;
}

const defaultInfluencerFilterModelProps: InfluencerGenerateCouponLinkModelProps =
  {
    expiryDate: null,
    couponLink: "",
  };

export class InfluencerGenerateCouponLinkModel {
  expiryDate?: Date | null;
  couponLink?: string;

  constructor(
    data: InfluencerGenerateCouponLinkModelProps = defaultInfluencerFilterModelProps
  ) {
    this.expiryDate = data.expiryDate || null;
    this.couponLink = data.couponLink || "";
  }
}
