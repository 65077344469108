import CartonsTable from "component/regional-distributors/details/cartonsTable";
import { AppStatusCode } from "config/appStatusCode";
import { GetAllCartons } from "functions/http-requests/carton";
import { useEffect, useState } from "react";
import AllCartonsTable from "./AllCartonTable";
import { CartonList } from "_interfaces/carton";

const CartonLayout = () => {
    const [allCartons, setAllCartons] = useState<CartonList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setLoading(true);
            GetAllCartons().then(res => {
                const data = res?.data;

                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    DATA = DATA.map((item: any) => ({
                        id: item._id,
                        firstICCID: item?.firstICCID,
                        lastICCID: item?.lastICCID,
                        batchNo: item?.batchNo,
                        cartonNo: item?.cartonNo,
                        quantity: item?.quantity,
                        date: item?.date,
                        SIM_type: item?.SIM_type,
                        distributor: item?.distributorDetails?.name || ""
                    }))
                    setAllCartons(DATA);
                }
                else {
                    setAllCartons([]);
                }
            }).catch((error) => {
                setAllCartons([])
            })
                .finally(() => setLoading(false));
        }
        fetchList();
        return () => {
            fetchList = null;
        }
    }, [])

    return (
        <>
            <AllCartonsTable DATA={allCartons} loading={loading} />
        </>
    )
};

export default CartonLayout;