import Box from "@mui/material/Box";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React, { } from "react";
import moment from "moment";
import ThirtPartyApiAlertsTableInterface from "_interfaces/log_alerts";
import { Chip, IconButton, Tooltip, useTheme } from "@mui/material";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const getColor = (value: string) => {
    if (!value) return;
    if (value == "veryhigh") {
        return "red";
    }
    if (value == "high") {
        return "#ff7700";
    }
    if (value == "medium") {
        return "#F1952B";
    }
    return "#b48c44";
}

const ThirdPartyLogAlertsTable: React.FC<
    ThirtPartyApiAlertsTableInterface
> = ({
    loading,
    data,
    count,
    paginationModel,
    setPaginationModel,
}) => {
        const navigate = useNavigate();
        const { palette } = useTheme();



        const columns: GridColDef[] = [
            { field: "moduleName", headerName: "Module Name", flex: 1, minWidth: 50 },
            {
                field: "level",
                headerName: "Level",
                flex: 1,
                minWidth: 50,
                renderCell: (params) => (
                    <Box className="tw-capitalize">
                        {params.value}
                    </Box>
                )
            },
            {
                field: "alertLevel",
                headerName: "Alert Level",
                minWidth: 50,
                flex: 1,
                renderCell: (params) => (
                    <Box className="tw-capitalize" sx={{ color: getColor(params?.value?.toLowerCase()) }} >
                        <Chip sx={{ background: getColor(params?.value?.toLowerCase()), color: "white" }} label={params?.value?.charAt(0).toUpperCase() + params?.value?.slice(1).toLowerCase()}></Chip>
                    </Box>
                )
            },
            {
                field: "activity",
                headerName: "Activity",
                flex: 1,
                minWidth: 200,
            },
            { field: "message", headerName: "Message", flex: 1, minWidth: 200 },
            {
                field: "time",
                headerName: "Time",
                flex: 1,
                minWidth: 250,
                renderCell: (params) => moment(params.value).format("MM-DD-YYYY h:mm:ss"),
            },
            {
                field: "action",
                headerName: "Action",
                flex: 1,
                minWidth: 50,
                renderCell: (params) => {
                    return (
                        <>
                            <Box className="tw-flex tw-justify-center tw-items-center">
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        params?.row?.id &&
                                        navigate(
                                            `${RouteConstant.LOG_AND_ALERTS}/${params?.row?.id}`
                                        )
                                    }
                                    sx={{ color: palette.secondary.main }}
                                >
                                    <Tooltip title="View Details">
                                        <VisibilityIcon color="inherit" fontSize="small" />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        </>
                    );
                },
            },
        ];
        return (
            <>
                <Box mt={2}>
                    <CustomDataGrid
                        loading={loading}
                        columns={columns}
                        rows={data || []}
                        count={count}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                    />
                </Box>
            </>
        );
    };

export default ThirdPartyLogAlertsTable;
