import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDateRangePicker from "component/_common/forms/customDateRangePicker";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import moment from "moment";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { BookingFilterModel } from "_models/data/phoneBooking/data.phone-booking-filter.model";
import { CustomFilter } from "parts/customFilter";

interface InputListProps extends FormInputProps {
 name: "status"|'bookingBy'|'identityType'|'dateRange'
}
interface Props extends SideDrawerProps {
  filters: BookingFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<BookingFilterModel>
  >;
  setOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const PhoneBookingFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
  setOpen
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
          (e: { value: string | number; id: string | number }) =>
            e?.value || e?.id
        )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions([
          'booked', 'cancelled', 'registered', 'expired'
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "bookingBy",
        label: "Filter By Booking",
        placeholder: "Select Booking",
        options: generateAutocompleteOptions([
          'self', 'whole_seller', 'agent'
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "identityType",
        label: "Filter by Identity Type",
        placeholder: "Select Identity Type",
        options: generateAutocompleteOptions(['citizen', 'tourist', 'refugee']),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      /> 
    </>
  );
};

export default PhoneBookingFilter;
