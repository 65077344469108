import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { PaymentFilterModel } from "_models/data/payment/data.payment-filter.model";
import { CustomFilter } from "parts/customFilter";
import { AppliedCouponFilterModel } from "_models/data/influencer/data.applied-coupon-filter.model";

interface InputListProps extends FormInputProps {
    name: "appliedOn" | 'dateRange'
}
interface Props extends SideDrawerProps {
    filters: AppliedCouponFilterModel;
    setFilters: React.Dispatch<
        React.SetStateAction<AppliedCouponFilterModel>
    >;
    setOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const AppliedCouponFilter: React.FC<Props> = ({
    open,
    filters,
    setFilters,
    setOpen
}) => {

    const inputList: InputListProps[] = useMemo(
        () => [
            {
                type: "autoComplete",
                name: "appliedOn",
                label: "Filter by Status",
                placeholder: "Select Status",
                options: generateAutocompleteOptions([
                    'simBooking', 'recharge'
                ]),
                multiple: false,
            },
            {
                type: "dateRange",
                name: "dateRange",
                label: "Select Date range",
                placeholder: "Select Date range",
            },
        ],
        []
    );

    return (
        <>
            <CustomFilter
                filters={filters}
                setFilters={setFilters}
                filterList={inputList}
                open={open as boolean}
                setOpen={setOpen}
            />
        </>
    );
};

export default AppliedCouponFilter;
