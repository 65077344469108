import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WalletDetailsInterface } from "_interfaces/walletDetails";
import { IsActiveCell } from "parts/table/customCell";

interface Props {
  data: WalletDetailsInterface | null;
}

const WalletDetailsTable: React.FC<Props> = ({ data }) => {
  const NAVIGATE = useNavigate();

  const rows: {
    field: keyof NonNullable<
      WalletDetailsInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "walletFor", headerName: "Wallet For", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "walletID", headerName: "Wallet ID" },
      {
        field: "userID", headerName: "User ID",
      },
      {
        field: "roleId", headerName: "Role ID"
      },
      {
        field: "status", headerName: "Status", renderCell: (v: string) =>
          <IsActiveCell status={v} />
      },
      {
        field: "createdAt",
        headerName: "Time",
        renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
      },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default WalletDetailsTable;
