import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint } from "@mui/material/styles";
import React from "react";

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  maxWidth?: Breakpoint | false;
  children?: React.ReactNode;
  onDelete: () => void;
}
const DeleteDialog: React.FC<Props> = ({
  open,
  onClose,
  title,
  content,
  maxWidth,
  children,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={maxWidth || "xs"}
        sx={{ minWidth: 220 }}
      >
        <DialogTitle>{title || "Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
