import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import PaymentTableInterface from "_models/data/payment/data.payment.model";
import { Button } from "@mui/material";
import AppliedCouponTableInterface from "_models/data/influencer/data.applied-coupon-model";

const AppliedCouponsTable: React.FC<
    AppliedCouponTableInterface
> = ({
    loading,
    data,
    count,
    paginationModel,
    setPaginationModel,
}) => {
        const { palette } = useTheme();
        const navigate = useNavigate();


        const columns: GridColDef[] = [
            {
                field: "appliedOn", headerName: "Applied On", flex: 1, minWidth: 150,
                renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
            },
            {
                field: "couponId", headerName: "Coupon", flex: 1, minWidth: 150,
                renderCell: (params) => <Button
                    onClick={() => navigate(`${RouteConstant.COUPON}/${params?.value}`)}
                    variant="text"
                    size="small"
                    sx={{ fontSize: "inherit" }}
                >
                    Coupon Details
                </Button>
            },
            {
                field: "phoneBookingId", headerName: "Phone Booking", flex: 1, minWidth: 150,
                renderCell: (params) => <Button
                    onClick={() => navigate(`${RouteConstant.BOOKINGS}/${params?.value}`)}
                    variant="text"
                    size="small"
                    sx={{ fontSize: "inherit" }}
                >
                    Booking Details
                </Button>
            },
            {
                field: "paymentId", headerName: "Payment", flex: 1, minWidth: 150,
                renderCell: (params) => <Button
                    onClick={() => navigate(`${RouteConstant.PAYMENTS}/${params?.value}`)}
                    variant="text"
                    size="small"
                    sx={{ fontSize: "inherit" }}
                >
                    Payment Details
                </Button>
            },
            { field: "couponCode", headerName: "Coupon Code", flex: 1, minWidth: 150 },
            { field: "discountAmount", headerName: "Discount Amount", flex: 1, minWidth: 150 },
            {
                field: "time",
                headerName: "Paid Time",
                flex: 1,
                minWidth: 150,
                renderCell: (params) => moment(params.value).format("MMM D YYYY h:mm A"),
            },
        ];
        return (
            <>
                <Box mt={2}>
                    <CustomDataGrid
                        loading={loading}
                        columns={columns}
                        rows={data || []}
                        count={count}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                    />
                </Box>
            </>
        );
    };

export default AppliedCouponsTable;
