import { Box, Card, Typography, useTheme } from "@mui/material";
import InfluencerDetailsInterface from "_interfaces/influencer/details";
import { toTitleCase } from "functions/helper";
import moment from "moment";
import { IsActiveCell } from "parts/table/customCell";
import DetailsBox from "parts/table/detailsBox";
import React from "react";

interface Props {
  DATA: InfluencerDetailsInterface["couponDetails"];
}

const InfluencerCouponDetailsTable: React.FC<Props> = ({ DATA }) => {
  const { palette } = useTheme();

  const rows: {
    field: keyof NonNullable<InfluencerDetailsInterface["couponDetails"]>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "title", headerName: "Title" },
    { field: "info", headerName: "Info" },
    {
      field: "category",
      headerName: "Category",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "applyOn",
      headerName: "Apply On",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    { field: "couponCode", headerName: "Coupon Code" },
    { field: "maxUse", headerName: "Max Use" },
    { field: "totalUsed", headerName: "Total Used" },
    {
      field: "validFrom",
      headerName: "Valid From",
      renderCell(v) {
        return v ? moment(v).format("Do MMM YYYY") : "";
      },
    },
    {
      field: "validUntil",
      headerName: "Valid Until",
      renderCell(v) {
        return v ? moment(v).format("Do MMM YYYY") : "";
      },
    },
    {
      field: "discountType",
      headerName: "Discount Type",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    { field: "discountValue", headerName: "Discount Value" },
    { field: "minPurchaseAmount", headerName: "Min Purchase Amount" },
    { field: "maxDiscountAmount", headerName: "Max Discount Amount" },
    {
      field: "isOpen",
      headerName: "Open For All",
      renderCell(v) {
        return (
          <IsActiveCell
            status={v ? "Yes" : "No"}
            statusColor={v ? palette.success.main : palette.error.main}
          />
        );
      },
    },
    {
      field: "isOpen",
      headerName: "Status",
      renderCell(v) {
        return <IsActiveCell status={v ? "Active" : "InActive"} />;
      },
    },
  ];

  return (
    <Card
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        mt: 3,
      }}
    >
      <Box className="tw-flex tw-justify-between tw-items-center">
        <Typography
          color="text.primary"
          className="tw-text-[18px]"
          sx={{ fontWeight: "bold" }}
        >
          {"Coupon Details"}
        </Typography>
      </Box>
      <DetailsBox data={DATA} rows={rows} />
    </Card>
  );
};

export default InfluencerCouponDetailsTable;
