export enum LotteryPromotionTypeProps {
  once = "once",
  weekly = "weekly",
  monthly = "monthly",
}
export enum LotteryPromotionConsumerProps {
  registeredCustomer = "registeredCustomer",
  simBooking = "simBooking",
}
export enum LotteryPlayBasedOnProps {
  count = "count",
  date = "date",
}
export enum LotteryPromotionPrizeProps {
  buildBundle = "buildBundle",
  other = "other",
}

export enum LotteryPromotionShowInPage {
  "home" = "home",
  "products" = "products",
  "products-build-bundle" = "products-build-bundle",
  "products-premium-plans" = "products-premium-plans",
  "home_top_carousel" = "home_top_carousel",
  "promotionPage" = "promotionPage",
}

export enum LotteryPromotionTextTypeSizeProps {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}
export enum LotteryPromotionTextTypePositionProps {
  left = "left",
  right = "right",
  bottom = "bottom",
  top = "top",
  middle = "middle",
}

export type LotteryPromotionTextTypeProps = {
  text: string;
  size: LotteryPromotionTextTypeSizeProps;
  color: string;
  position: LotteryPromotionTextTypePositionProps;
  linePosition: number;
  show: boolean;
};
export enum LotteryPromotionUiThemeProps {
  one = 1,
  two = 2,
  three = 3,
  four = 4,
}

export type LotteryPromotionUiProps = {
  heading?: LotteryPromotionTextTypeProps;
  title1?: LotteryPromotionTextTypeProps;
  title2?: LotteryPromotionTextTypeProps;
  title3?: LotteryPromotionTextTypeProps;
  title4?: LotteryPromotionTextTypeProps;
  description?: LotteryPromotionTextTypeProps;
  theme?: LotteryPromotionUiThemeProps;
};
export enum LotteryPromotionUiTextTypeProps {
  heading = "heading",
  title1 = "title1",
  title2 = "title2",
  title3 = "title3",
  title4 = "title4",
  description = "description",
}

export type LotteryCreateProps = {
  DATA: {
    promotionType?: LotteryPromotionTypeProps;
    promotionConsumer?: LotteryPromotionConsumerProps;
    playBasedOn?: LotteryPlayBasedOnProps;
    playTime?: Date;
    count?: number;
    promotionPrize?: LotteryPromotionPrizeProps;
    startTime?: Date;
    maxWinner?: number;
    showInPage?: LotteryPromotionShowInPage[];
    UI?: LotteryPromotionUiProps;
    assignDate?: Date;
    expiryDate?: Date;
    isActive?: boolean;
  };
};

export type LotteryUpdateProps = {
  DATA: LotteryCreateProps["DATA"] & {
    id?: string;
  };
};
