import { AssignTasksProps, GetAllTaskProps } from "_interfaces/functions/http-requests/tasks";
import TaskTableInterface from "_interfaces/tasks";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { AssignTask, GetAllTask } from "functions/http-requests/tasks";
import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TaskTable from "./taskTable";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GetAllTeam } from "functions/http-requests/teams";
import { CreateTeamProps } from "_interfaces/functions/http-requests/teams";
import { TeamModel } from "_models/data/team/data.team.model";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";

export const TasksLayout = () => {
    const isInitialRender = useRef(true);
    const Dispatch = useDispatch();

    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const [data, setData] = useState<TaskTableInterface["data"]>([]);

    const [openUploadForm, setOpenUploadForm] = useState<boolean>(false);

    const [refresh, setRefresh] = useState<boolean>(false);

    const [searchKey, setSearchKey] = useState<string>("");

    const [count, setCount] = useState<number>(0);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState("");
    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => {
        setSelectedTeam("");
        setDialogOpen(false);
    };
    const [teamList, setTeamList] = useState<TeamModel[]>([]);
    const handleTeamChange = (event: SelectChangeEvent) => setSelectedTeam(event.target.value as string);

    const handleAssign = async () => {
        if (!selectedTeam || selectedRows.length == 0) {
            Dispatch(
                setAlert({
                    type: "warning",
                    message: "Please select a team for assign",
                })
            );
            return;
        }
        Dispatch(toggleLoading(true));

        const taskIds: string[] = selectedRows?.map(item => item.id);

        const props: AssignTasksProps = {
            DATA: {
                teamId: selectedTeam,
                tasksId: taskIds
            }
        }
        AssignTask(props)
            .then(res => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    Dispatch(
                        setAlert({
                            type: "success",
                            message: data?.message,
                        })
                    );
                    handleDialogClose();
                    Dispatch(toggleLoading(false));
                    getAllData();
                    setSelectedRows([]);
                } else {
                    Dispatch(
                        setAlert({
                            type: "error",
                            message: "There is some issue in assigning tasks",
                        })
                    );
                }
            })
            .catch(err => {
                const errorMessage = err?.response?.data?.message;
                if (errorMessage) {
                    Dispatch(
                        setAlert({
                            type: "error",
                            message: errorMessage,
                        })
                    );
                }
                else {
                    Dispatch(
                        setAlert({
                            type: "error",
                            message: "There is some error is assigning tasks",
                        })
                    );
                }
            })
    }

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: CreateTeamProps["DATA"] = {
                teamName: "",
                teamLocation: "",
                managerName: '',
                managerEmail: "",
                managerPhone: "",
                managerPassword: "",
                region: "",
                district: "",
                county: "",
                subCounty: "",
                parish: "",
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetAllTeam({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            _id: item?._id,
                            name: item?.name,
                            teamID: item?.teamID,
                            location: item?.location,
                            manager: item?.manager,
                            isActive: item?.isActive,
                            createdAt: item?.createdAt,
                        }));
                        setTeamList(DATA);
                    } else {
                        setTeamList([]);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, []);

    const getAllData = async () => {
        setDataLoading(true);
        let PAYLOAD_DATA: GetAllTaskProps["DATA"] = {
            unassignedOnly: true
        };

        let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

        GetAllTask({
            DATA: FILTERED_PAYLOAD_DATA,
        })
            .then((res) => {
                const data = res?.data;

                if (data?.statusCode === AppStatusCode.api_success) {
                    let count = res?.data?.meta?.count;
                    if (count && count > 1) {
                        if (count > 1) setCount(count);
                    } else setCount(0);

                    let DATA: any = res?.data?.data;
                    DATA = DATA?.map((item: any) => ({
                        id: item?._id,
                        category: item?.category || "",
                        assignedTeam: item?.assignedTeam || "",
                        type: item?.type,
                        eVerificationId: item?.eVerificationId || "",
                        status: item?.status || "",
                        assignDate: item?.assignDate || "",
                        lastUpdateDate: item?.lastUpdateDate || "",
                    }));
                    setData(DATA);
                } else {
                    setData([]);
                    setCount(0);
                }
            })
            .catch(() => {
                setData([]);
                setCount(0);
            })
            .finally(() => {
                setDataLoading(false);
            });
    }

    useEffect(() => {
        getAllData();
    }, []);
    return (
        <>
            <Grid item className="tw-flex tw-justify-end tw-items-center">
                <Button
                    variant="outlined"
                    className="tw-ml-[8px]"
                    disabled={selectedRows.length == 0}
                    onClick={handleDialogOpen}
                >
                    Assign to Team
                </Button>
            </Grid>
            <Box pt={2}>
                <TaskTable
                    data={data}
                    loading={dataLoading}
                    count={count}
                    setSelectedRows={setSelectedRows}
                />
            </Box>

            {/* select teams dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '90%' } }} >
                <DialogTitle>Select Task</DialogTitle>
                <DialogContent>
                    <Select
                        value={selectedTeam}
                        onChange={handleTeamChange}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select a Task
                        </MenuItem>
                        {
                            teamList.map(team => (
                                <MenuItem value={team?._id}>{team?.name}</MenuItem>
                            ))
                        }
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleAssign} color="primary">
                        Assign
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}