import { AssignCartonToAgentProps, AssignCartonToDistributorProps } from "_interfaces/functions/http-requests/cartons";
import { ASSIGN_CARTON_TO_AGENT, ASSIGN_CARTON_TO_DISTRIBUTOR, CARTON_LIST_BY_AGENT, CARTON_LIST_BY_DISTRIBUTOR, GET_ALL_CARTONS } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetCartonByAgent = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: CARTON_LIST_BY_AGENT(ID),
    });
};

export const GetCartonByDistributor = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: CARTON_LIST_BY_DISTRIBUTOR(ID),
    });
};

export const GetAllCartons = () => {
    return HTTP({
        Method: "GET",
        Url: GET_ALL_CARTONS,
    });
};

export const AssignCartonToDistributor = ({ DATA }: AssignCartonToDistributorProps, cartonId: string) => {
    return HTTP({
        Method: "POST",
        Url: ASSIGN_CARTON_TO_DISTRIBUTOR(cartonId),
        Data: DATA
    });
};
export const AssignCartonToAgent = ({ DATA }: AssignCartonToAgentProps, cartonId: string) => {
    return HTTP({
        Method: "POST",
        Url: ASSIGN_CARTON_TO_AGENT(cartonId),
        Data: DATA
    });
};

