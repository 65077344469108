import { Button, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MsisdnManagementDetailsInterface, { MsisdnDialogInterface } from "_interfaces/msisdn-management/details";
import { camelCaseToFormattedString } from "functions/helper";
import { IsActiveCell } from "parts/table/customCell";
import DetailsTable from "parts/table/detailsTable";
import React, { useState } from "react";
import MsisdnChangeTypeStatusDialog from "./msisdnChangeTypeStatusDialog";

interface Props {
  data: MsisdnManagementDetailsInterface | null;
  id: string;
  getAllData: () => void
}

const MsisdnInformationTable: React.FC<Props> = ({ id, data, getAllData }) => {

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<MsisdnDialogInterface[]>([]);
  const [fieldName, setFieldName] = useState<string>("");


  const handleDialogData = (field: string, data: string[], selectedName: string | undefined) => {
    if (!selectedName) return;
    setFieldName(field);
    let DATA = data.map(val => {
      if (val.toLowerCase() == selectedName.toLowerCase()) {
        return {
          type: val,
          selected: true
        }
      }
      else {
        return {
          type: val,
          selected: false
        }
      }
    })
    setDialogData(DATA);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  }

  return (
    <>
      {data?.id ? (
        <DetailsTable>
          <TableRow>
            <TableCell>MSISDN</TableCell>
            <TableCell>{data?.msisdnInfo?.MSISDN}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone Number</TableCell>
            <TableCell>{data?.msisdnInfo?.phoneNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>
              {
                data?.msisdnInfo?.status == "idle" ?
                  <Box className="tw-capitalize">
                    <Tooltip title="Change Type">
                      <Button sx={{ color: "#da9d41" }} onClick={() => handleDialogData("Type", ["Premium", "Normal"], data?.msisdnInfo?.type)}>
                        {data?.msisdnInfo?.type
                          ? camelCaseToFormattedString(data?.msisdnInfo?.type)
                          : ""}
                      </Button>
                    </Tooltip>
                  </Box>
                  :
                  <Box className="tw-capitalize">
                    {data?.msisdnInfo?.type
                      ? camelCaseToFormattedString(data?.msisdnInfo?.type)
                      : ""}
                  </Box>
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Number Type</TableCell>
            <TableCell>
              {data?.msisdnInfo?.isFixed ? "Fixed" : "Normal"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              <Box>
                <Tooltip title="Change Status">
                  <Button sx={{ color: data?.msisdnInfo?.status == "idle" ? "#da9d41" : "#ce1f1f" }} onClick={() => handleDialogData("Status", ["Idle", "Blocked"], data?.msisdnInfo?.status as string)}>
                    {/* <IsActiveCell status={data?.msisdnInfo?.status} /> */}
                    {data?.msisdnInfo?.status}
                  </Button>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        </DetailsTable>
      ) : (
        <></>
      )}

      <MsisdnChangeTypeStatusDialog id={id} open={openDialog} handleClose={handleClose} data={dialogData} field={fieldName} getAllData={getAllData} />
    </>
  );
};

export default MsisdnInformationTable;
