import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import PackageBundleTableInterface from "_interfaces/package-bundle/packageBundleTable";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React from "react";
import { toTitleCase } from "functions/helper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";

const PackageBundleTable: React.FC<PackageBundleTableInterface> = ({
  loading,
  data,
  setEditId,
  count,
  paginationModel,
  setPaginationModel,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "packageName",
      headerName: "Package Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize"> {params?.value} </Box>
      ),
    },
    {
      field: "packageID",
      headerName: "Package ID",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize"> {toTitleCase(params?.value)} </Box>
      ),
    },
    {
      field: "packageType",
      headerName: "Package Type",
      flex: 1,
      minWidth: 150,
      renderCell: (params) =>
        Array?.isArray(params?.value) && params?.value?.length ? (
          <Tooltip title={params?.value?.join(", ")}>
            <Box className="tw-capitalize tw-truncate">
              {toTitleCase(params?.value?.join(", "))}{" "}
            </Box>
          </Tooltip>
        ) : (
          <></>
        ),
    },
    {
      field: "packageFor",
      headerName: "Package For",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize"> {toTitleCase(params?.value)} </Box>
      ),
    },
    {
      field: "validity",
      headerName: "Validity",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Box className="tw-capitalize">
            {params?.row?.isValidityUnlimited ? (
              <Box component="span" fontSize={30}>
                &#x221e;
              </Box>
            ) : params?.value ? (
              toTitleCase(params?.value)
            ) : (
              ""
            )}
          </Box>
        </>
      ),
    },
    // {
    //   field: "dataVolume",
    //   headerName: "Data Volume",
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params) => (
    //     <Box>
    //       {params?.row?.dataVolume?.isUnlimited ? (
    //         "Unlimited"
    //       ) : (
    //         <>
    //           {params?.row?.dataVolume?.quantity}{" "}
    //           {params?.row?.dataVolume?.dataUnit}
    //         </>
    //       )}
    //     </Box>
    //   ),
    // },
    // {
    //   field: "dataPerDay",
    //   headerName: "Data/Day",
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params) => (
    //     <Box>
    //       {params?.row?.dataPerDay?.isUnlimited ? (
    //         "Unlimited"
    //       ) : (
    //         <>
    //           {params?.row?.dataPerDay?.quantity}{" "}
    //           {params?.row?.dataPerDay?.dataUnit}
    //         </>
    //       )}
    //     </Box>
    //   ),
    // },

    {
      field: "retailPrice",
      headerName: "Retail Price",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "isActive",
      headerName: "Status",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <IsActiveCell status={params?.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() => params?.row?.id && setEditId(params?.row?.id)}
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
    {
      field: "seeDeatils",
      headerName: "See Details",
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() =>
                  params?.row?.id &&
                  navigate(
                    `${RouteConstant.PACKAGE_BUNDLE}/${params?.row?.id}`
                  )
                }
                sx={{ color: palette.secondary.main }}
              >
                <Tooltip title="View Details">
                  <VisibilityIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box mt={2}>
        <CustomDataGrid
          loading={loading}
          columns={columns}
          rows={data || []}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>
    </>
  );
};

export default PackageBundleTable;
