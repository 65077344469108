export interface CreateAppVersionProps {
  releaseDate: string;
  isUpdateMandatory: boolean;
  currentVersion: string;
  description: string;
  appName: string
  app: string;
}

const defaultCreateAppsProps: CreateAppVersionProps = {
  releaseDate: "",
  isUpdateMandatory: false,
  currentVersion: "",
  description: "",
  appName: "",
  app: "",
};

export class CreateAppVersionModel {
  releaseDate: string;
  isUpdateMandatory: boolean
  currentVersion: string
  description: string
  appName: string
  app: any

  constructor(data: CreateAppVersionProps = defaultCreateAppsProps) {
    this.releaseDate = data.releaseDate || "";
    this.isUpdateMandatory = data.isUpdateMandatory || false
    this.currentVersion = data.currentVersion || ""
    this.description = data.description || ""
    this.appName = data.appName
    this.app = data.app
  }
}

export class CreateAppVersionErrorModel {
  releaseDate: string;
  isUpdateMandatory: string
  currentVersion: string
  description: string
  appName: string
  app: any

  constructor(data: any = defaultCreateAppsProps) {
    this.releaseDate = data.releaseDate || "";
    this.isUpdateMandatory = data.isUpdateMandatory || ""
    this.currentVersion = data.currentVersion || ""
    this.description = data.description || ""
    this.appName = data.appName || ""
    this.app = data.app
  }
}