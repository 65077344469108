import { Card, Typography, Grid, Box } from "@mui/material";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllHandlersProps } from "_interfaces/functions/http-requests/handlers";
import HandlerTableInterface from "_interfaces/handler";
import { HandlersFilterModel } from "_models/data/handler/data.handlers-filter.model";
import HandlerDetailsFilter from "component/agents/details/handlerDetailsFilter";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllHandlers } from "functions/http-requests/handlers";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import AllHandlersTable from "./allHandlersTable";

const HandlersLayout = () => {
    const isInitialRender = useRef(true);
    const [data, setData] = useState<HandlerTableInterface["data"]>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>("");
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const [filters, setFilters] = useState<any>(
        new HandlersFilterModel()
    );
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [totalcount, setTotalCount] = useState<number>(0);
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };
    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };
    const handleClearSearch = () => setSearchKey("");

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };


    useEffect(() => {

        let fetchAllAgentByDistributor: (() => void) | null = () => {
            setDataLoading(true);
            let PAYLOAD_DATA: GetAllHandlersProps["DATA"] = {
                searchKey: searchKey,
                isActive: filters.activeStatus == "active" ? true : filters.activeStatus == "inActive" ? false : undefined,
                isApprove: filters.approveStatus == "approve" ? true : filters.approveStatus == "notApprove" ? false : undefined,
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
            };

            let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);
            GetAllHandlers({ DATA: FILTERED_PAYLOAD_DATA })
                .then(res => {
                    const data = res?.data;
                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count;
                        if (count && count > 1) {
                            if (count > 1) setTotalCount(count);
                        } else setTotalCount(0);
                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            name: item?.userDetails?.name || "",
                            email: item?.userDetails?.email || "",
                            phone: item?.userDetails?.phone || "",
                            role: item?.userDetails?.role || "",
                            date: item?.createdAt || "",
                            isActive: item?.isActive
                        }));
                        setData(DATA);
                        setTotalCount(res?.data?.meta?.count)
                    }
                    else {
                        setData([]);
                        setTotalCount(0);
                    }
                })
                .catch((err) => {
                    setData([]);
                    setTotalCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchAllAgentByDistributor();
    }, [refresh, paginationModel, filters, searchKey])

    return (
        <>
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Handlers List"}
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Box sx={{ flex: "1 1 auto" }}>
                            <SectionSearch
                                name="search_sim"
                                value={searchKey}
                                onChange={handleSearch}
                                onClear={handleClearSearch}
                                onSubmit={handleSubmitSearch}
                            />
                        </Box>
                        <FilterButton onClick={() => setOpenFilter(true)} />
                    </Grid>
                </Grid>
                <AllHandlersTable data={data}
                    loading={dataLoading}
                    count={totalcount}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    setFilters={setFilters} />
                <HandlerDetailsFilter
                    open={openFilter}
                    onClose={toggleFilter}
                    filters={filters}
                    setFilters={setFilters}
                />
            </Card>
        </>
    )
}

export default HandlersLayout;