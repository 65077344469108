import { PackageBundleModel } from "_models/data/package-bundle/data.package-bundle.model";
import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { GetPackageBundleById } from "functions/http-requests/package-bundle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import PackageBundleDetailsTable from "./detailsTable";

interface Props {
    id: string;
}

export const PackageBundleDetailsLayout: React.FC<Props> = ({ id }) => {
    const [state, setState] = useState<PackageBundleModel>(
        new PackageBundleModel()
    );
    const Dispatch = useDispatch();

    const handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetPackageBundleById(ID)
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    setState({
                        id: DATA?._id || "",
                        packageName: DATA?.packageName || "",
                        dataVolume: DATA?.dataVolume,
                        isValidityUnlimited: DATA?.isValidityUnlimited,
                        validity: DATA?.validity ? DATA?.validity?.toString() : "",

                        dataVolume_isUnlimited: DATA?.dataVolume?.isUnlimited,
                        dataVolume_quantity: DATA?.dataVolume?.quantity?.toString() || "",
                        dataVolume_dataUnit: DATA?.dataVolume?.dataUnit || "",

                        dataPerDay_isUnlimited: DATA?.dataPerDay?.isUnlimited,
                        dataPerDay_quantity: DATA?.dataPerDay?.quantity?.toString() || "",
                        dataPerDay_dataUnit: DATA?.dataPerDay?.dataUnit || "",

                        allNetMinutes:
                            DATA?.allNetMinutes && DATA?.allNetMinutes !== "unlimited"
                                ? DATA.allNetMinutes?.toString()
                                : "",
                        allNetMinutes_isUnlimited:
                            DATA?.allNetMinutes && DATA?.allNetMinutes === "unlimited"
                                ? true
                                : false,

                        onNetMinutes:
                            DATA?.onNetMinutes && DATA?.onNetMinutes !== "unlimited"
                                ? DATA.onNetMinutes?.toString()
                                : "",
                        onNetMinutes_isUnlimited:
                            DATA?.onNetMinutes && DATA?.onNetMinutes === "unlimited"
                                ? true
                                : false,

                        offNetMinutes:
                            DATA?.offNetMinutes && DATA?.offNetMinutes !== "unlimited"
                                ? DATA.offNetMinutes?.toString()
                                : "",
                        offNetMinutes_isUnlimited:
                            DATA?.offNetMinutes && DATA?.offNetMinutes === "unlimited"
                                ? true
                                : false,

                        allNetSMS:
                            DATA?.allNetSMS && DATA?.allNetSMS !== "unlimited"
                                ? DATA.allNetSMS?.toString()
                                : "",
                        allNetSMS_isUnlimited:
                            DATA?.allNetSMS && DATA?.allNetSMS === "unlimited"
                                ? true
                                : false,

                        onNetSMS:
                            DATA?.onNetSMS && DATA?.onNetSMS !== "unlimited"
                                ? DATA.onNetSMS?.toString()
                                : "",
                        onNetSMS_isUnlimited:
                            DATA?.onNetSMS && DATA?.onNetSMS === "unlimited" ? true : false,

                        offNetSMS:
                            DATA?.offNetSMS && DATA?.offNetSMS !== "unlimited"
                                ? DATA.offNetSMS?.toString()
                                : "",
                        offNetSMS_isUnlimited:
                            DATA?.offNetSMS && DATA?.offNetSMS === "unlimited"
                                ? true
                                : false,

                        internationalCallMinutes: DATA?.internationalCallMinutes
                            ? DATA?.internationalCallMinutes?.toString()
                            : "",
                        packageFor: DATA?.packageFor || "",
                        packageID: DATA?.packageID || "",
                        retailPrice: DATA?.retailPrice
                            ? DATA?.retailPrice?.toString()
                            : "",
                        description: DATA?.description || "",
                        category: DATA?.category || "",
                        discountFor: DATA?.discountFor || "",
                        discountPercent: DATA?.discountPercent
                            ? DATA?.discountPercent?.toString()
                            : "",
                        packageType: DATA?.packageType || [],
                        vat: DATA?.vat ? DATA?.vat?.toString() : "",
                        isActive: DATA?.isActive || false,
                    });

                } else {
                    Dispatch(setAlert({ type: "error", message: data?.message }));
                    setState(new PackageBundleModel());
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error))
                    Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
            })
            .finally(() => Dispatch(toggleLoading(false)));
    };

    useEffect(() => {
        if (id) {
            let fetchById: (() => void) | null = () => {
                handleFetchById(id);
            };
            fetchById();
            return () => {
                fetchById = null;
            };
        }
    }, [id]);

    return (
        <>
            <PackageBundleDetailsTable data={state} />
        </>
    )
}