import { InputBaseProps, InputProps, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef } from "react";

interface Props extends InputBaseProps {
  label?: string;
  InputProps?: InputProps;
  errorText?: string;
  active?: boolean;
  activeColor?: string;
}

const Text: React.FC<Props> = ({
  type,
  name,
  label,
  placeholder,
  autoComplete,
  minRows,
  InputProps,
  onChange,
  onFocus,
  onBlur,
  value,
  errorText,
  disabled,
  active,
  activeColor,
  multiline,
}) => {
  const { palette } = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  // Disable scroll for number inputs
  useEffect(() => {
    const inputElement = inputRef.current;
    if (type === "number" && inputElement) {
      const handleWheel = (e: WheelEvent) => e.preventDefault();

      inputElement.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        inputElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, [type]);

  return (
    <>
      {label ? (
        <FormLabel
          htmlFor={name}
          className="tw-mb-[6px] tw-block"
          sx={{ color: palette.secondary.main }}
        >
          {label}
        </FormLabel>
      ) : null}
      <TextField
        inputRef={inputRef} // Set the ref for accessing the DOM element
        id={name}
        fullWidth
        variant="outlined"
        type={type}
        name={name}
        value={value || ""}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        autoComplete={autoComplete}
        multiline={multiline || type === "textArea"}
        minRows={minRows}
        error={!!errorText}
        helperText={
          errorText ? (
            <Box
              component="span"
              dangerouslySetInnerHTML={{ __html: errorText }}
            />
          ) : null
        }
        disabled={disabled}
        InputProps={{
          ...(InputProps || {}),
          inputProps: {
            step: "any",
            ...(InputProps?.inputProps || {}),
          },
        }}
        sx={{
          ".MuiInputBase-root.MuiOutlinedInput-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor:
                active && activeColor
                  ? activeColor
                  : active && !activeColor
                  ? palette.primary.main
                  : "",
            },
          },
        }}
      />
    </>
  );
};

export default Text;
