import {
  CBS_LIFE_CYCLE_RE_SUBMIT_REQUEST,
  CBS_RE_SUBMIT_REQUEST,
  CREATE_APN,
  CREATE_HLR,
  CREATE_HSS,
  CREATE_OCSI,
  CREATE_PDP,
  CREATE_SMSCI,
  CREATE_SUPPLEMENT1,
  CREATE_SUPPLEMENT2,
  CREATE_TCSI,
  E_SIM_CONFIRM_ORDER,
  E_SIM_DOWNLOAD_ORDER,
  NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST,
} from "config/endpoint";

export const KycServerUrl = {
  NIRA: NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST,
  UCC: NIRA_UCC_REGISTRATION_RE_SUBMIT_REQUEST,
  CBS: CBS_RE_SUBMIT_REQUEST,
  CBS_life_cycle: CBS_LIFE_CYCLE_RE_SUBMIT_REQUEST,
  HLR_SUBSCRIBE: CREATE_HLR,
  HLR_SUBSCRIBE_mt: CREATE_HLR,
  HLR_PDP: CREATE_PDP,
  HLR_PDP_mt: CREATE_PDP,
  HLR_OCSI: CREATE_OCSI,
  HLR_OCSI_mt: CREATE_OCSI,
  HLR_SMSCI: CREATE_SMSCI,
  HLR_SMSCI_mt: CREATE_SMSCI,
  HLR_SUPPLEMENT1: CREATE_SUPPLEMENT1,
  HLR_SUPPLEMENT1_mt: CREATE_SUPPLEMENT1,
  HLR_SUPPLEMENT2: CREATE_SUPPLEMENT2,
  HLR_SUPPLEMENT2_mt: CREATE_SUPPLEMENT2,
  HLR_TCSI: CREATE_TCSI,
  HLR_TCSI_mt: CREATE_TCSI,
  HSS: CREATE_HSS,
  HSS_mt: CREATE_HSS,
  HSS_SUBSCRIBE_APN: CREATE_APN,
  HSS_SUBSCRIBE_APN_mt: CREATE_APN,
  E_SIM_DOWNLOAD_ORDER: E_SIM_DOWNLOAD_ORDER,
  E_SIM_CONFIRM_ORDER: E_SIM_CONFIRM_ORDER,
};

export type KycServerUrlType = keyof typeof KycServerUrl;

export const kycServerUrlOrder = Object.keys(
  KycServerUrl
) as KycServerUrlType[];

export type CallKycServerApiProps = {
  Url: (typeof KycServerUrl)[KycServerUrlType];
  DATA: {
    kycId: string;
    type?: "mb" | "mt";
  };
};

export const getKycType = (
  key: string
): CallKycServerApiProps["DATA"]["type"] => {
  // Check if the key contains "HLR_" or "HSS_"
  if (key.startsWith("HLR_") || key.startsWith("HSS_")) {
    // If the key ends with "_mt", return "mt", else return "mb"
    if (key.endsWith("_mt")) {
      return "mt";
    } else {
      return "mb";
    }
  }

  return undefined;
};
