import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { PaymentFilterModel } from "_models/data/payment/data.payment-filter.model";
import { CustomFilter } from "parts/customFilter";
import { SimSwapFilterModel } from "_models/data/sim-swap/data.sim-swap-filter.model";

interface InputListProps extends FormInputProps {
  name: "status" | 'dateRange'
}
interface Props extends SideDrawerProps {
  filters: SimSwapFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<SimSwapFilterModel>
  >;
  setOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const SimSwapFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen
}) => {

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions([
          'success', 'rejected'
        ]),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default SimSwapFilter;
