import { PackageBundleDetailsLayout } from "component/package-bundle/details"
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer"
import { useParams, Navigate } from "react-router-dom";

export const PackageBundleDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.CUSTOMER_MANAGEMENT} />;

    return (
        <MainContainer>
            <PackageBundleDetailsLayout id={params.id} />
        </MainContainer>
    )
}