export const RouteConstant = {
  UNAUTHORIZED: "/unauthorized",
  LOG_IN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",

  DASHBOARD: "/",

  KYC: "/kyc",

  CUSTOMER_MANAGEMENT: "/customer-management",
  SIM_MANAGEMENT: "/sim-management",

  MSISDN_MANAGEMENT: "/msisdn-management",

  INVENTORY_MANAGEMENT: "/inventory-management",

  ANALYTICS: "/analytics",
  ANALYTICS_REPORT: "/analytics/report",

  USER_MANAGEMENT: "/user-management",
  INVENTORY_USERS: "/user-management/inventory-users",
  REGIONAL_DISTRIBUTORS: "/user-management/distributors",
  REGIONAL_DISTRIBUTOR_DETAILS:
    "/user-management/distributors/distributor-details",
  AGENTS: "/user-management/agents",
  AGENT_DETAILS: "/user-management/agents/details",
  HANDLERS: "/user-management/handlers",

  SEARCH_INFORMATION: "/search-information",

  SIM_CARD_CHARGES: "/charges-fee/sim-card-charges",
  PACKAGE_BUNDLE: "/charges-fee/package-bundle",
  PROMOTION: "/charges-fee/promotion",
  LOTTERY: "/charges-fee/promotion/lottery",
  COUPON: "/management/coupon",
  INFLUENCER: "/management/influencer",

  MANAGE_ACCOUNT: "/manage-account",
  MANAGE_ACCOUNT_NOTIFICATION: "/manage-account/notification",
  MANAGE_ACCOUNT_TWO_WAY_AUTH: "/manage-account/two-way-auth",
  MANAGE_ACCOUNT_REPORT: "/manage-account/report",

  INTER_SWITCH_E_VALUE: "/inter-switch-e-value",
  CURRENT_VERSION: "/current-version",
  APP_VERSION_HISTORY: "/app-version-history",
  ALL_CARTONS: "/all-cartons",
  LOG_AND_ALERTS: "/log-alerts",
  GLOBAL_ACTIVITY_LOGS: "/global-activity-logs",
  CARTON_DETAILS: "/carton-details",

  RISE_TICKET: "/rise-ticket",

  FAQ: "/faq",
  CONTACT_US: "/contact-us",
  BOOKINGS: "/bookings",
  RECHARGES: "/recharges",
  PAYMENTS: "/payments",
  TRANSACTIONS: "/transactions",
  WALLETPAYMENTS: "/wallet-payments",
  WALLETTRANSACTION: "/wallet-transaction",
  WALLETTDETAILS: "/wallet-details",
  SIMSWAP: "/sim-swap",
  DEREGISTRATION: "/de-registration",
  TEAM: "/management/team",
  CREATETEAM: "/management/team/createTeam",
  TASK: "/management/task"
};
