import { PaymentDetailsInterface } from "_interfaces/payment";
import { TransactionDetailsInterface } from "_interfaces/transactions";
import { AppStatusCode } from "config/appStatusCode";
import { GetPaymentDetails } from "functions/http-requests/payment";
import { GetTransactionDetails } from "functions/http-requests/transactions";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import TransactionDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const TransactionDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<TransactionDetailsInterface | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetTransactionDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        paymentBy: DATA?.paymentBy,
                        paymentType: DATA?.paymentType,
                        paidTime: DATA?.paidTime,
                        paymentId: DATA?.paymentId,
                        paymentMethod: DATA?.paymentMethod,
                        totalAmount: DATA?.totalAmount,
                        transactionId: DATA?.transactionId,
                        momoResponse: DATA?.momoResponse,
                        airtelResponse:DATA?.airtelResponse
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <TransactionDetailsTable data={details} />
        </>
    )
}