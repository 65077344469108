import AddIcon from "@mui/icons-material/Add";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { AppStatusCode } from "config/appStatusCode";
import { GetAllPromotion } from "functions/http-requests/promotions";
import { GetAllLottery } from "functions/http-requests/promotions/lottery";
import { RouteConstant } from "navigation/constant";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LotteryTableInterface from "_interfaces/promotion/lottery/lotteryTable";
import LotteryForm from "./lotteryForm";
import LotteryTable from "./lotteryTable";

const LotteryLayout = () => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<LotteryTableInterface["data"]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");

  const toggleForm = () => {
    if (editId) return setEditId("");
    setOpenForm(!openForm);
  };

  const handleRefresh = () => setRefresh(!refresh);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      GetAllLottery()
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              promotionType: item?.promotionType || "",
              promotionConsumer: item?.promotionConsumer || [],
              showInPage: item?.showInPage || [],
              count: item?.count,
              maxWinner: item?.maxWinner,
              assignDate: item?.assignDate,
              expiryDate: item?.expiryDate,
              isActive: item?.isActive,
            }));
            setData(DATA);
          } else {
            setData([]);
          }
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [refresh]);

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} sm={6}>
          <Box className="tw-flex tw-items-center">
            <Link to={RouteConstant.PROMOTION}>
              <Button
                variant="text"
                disableElevation
                color="inherit"
                size="small"
                startIcon={<ArrowBack fontSize="small" />}
                sx={{ fontSize: 14 }}
              >
                Back to Promotion
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="tw-flex tw-justify-end tw-items-center">
            <Button
              onClick={toggleForm}
              variant="outlined"
              startIcon={<AddIcon />}
              disableElevation
            >
              <Box component="span" className="tw-line-clamp-1">
                Add Lottery
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <LotteryTable data={data} loading={dataLoading} setEditId={setEditId} />

      <CustomDrawer
        open={openForm || editId ? true : false}
        onClose={toggleForm}
        title={editId ? "Edit  Lottery" : "Add Lottery"}
      >
        <LotteryForm
          handleRefresh={handleRefresh}
          onClose={toggleForm}
          editId={editId}
        />
      </CustomDrawer>
    </>
  );
};

export default LotteryLayout;
