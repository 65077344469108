import { UpdateBookingModel } from "_models/data/phoneBooking/data.phone-booking.model";
import { isValidEmail } from "functions/validators";

export const validatePhoneBooking = (
  DATA: UpdateBookingModel,
  isEditing?: boolean
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.email?.trim()) {
    valid = false;
    errors.push({
      name: "email",
      error: "Email is required.",
    });
  }

  if (DATA?.email?.trim() && !isValidEmail(DATA.email)) {
    valid = false;
    errors.push({
      name: "email",
      error: "Invalid email.",
    });
  }

  if (!DATA?.name?.trim()) {
    valid = false;
    errors.push({
      name: "name",
      error: "Name is required.",
    });
  }
  if (!DATA?.passportNumber?.trim() && DATA?.passportNumber != null) {
    valid = false;
    errors.push({
      name: "passportNumber",
      error: "Passport Number is required.",
    });
  }
  if (!DATA?.refugeeDocumentNumber?.trim() && DATA?.refugeeDocumentNumber != null) {
    valid = false;
    errors.push({
      name: "refugeeDocumentNumber",
      error: "Refugee Document Number is required.",
    });
  }

  if (!DATA?.nationalIdIdNumber?.trim() && DATA?.nationalIdIdNumber != null) {
    valid = false;
    errors.push({
      name: "nationalIdIdNumber",
      error: "National Id is required.",
    });
  }


  if (!DATA?.name?.trim()) {
    valid = false;
    errors.push({
      name: "name",
      error: "Name is required.",
    });
  }


  return { valid, errors };
};
