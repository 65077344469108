import { PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { AppStatusCode } from "config/appStatusCode";
import { GetPhoneBookingDetails } from "functions/http-requests/bookings";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import PhoneBookingDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const PhoneBookingDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<PhoneBooking | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetPhoneBookingDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        MSISDN: DATA?.MSISDN,
                        name: DATA?.name,
                        email: DATA?.email,
                        phoneNumber: DATA?.phoneNumber,
                        status: DATA?.status,
                        bookingBy: DATA?.bookingBy,
                        identityType: DATA?.identityType,
                        passportNumber: DATA?.passportNumber,
                        bookingTime: DATA?.bookingTime,
                        createdAt: DATA?.createdAt,
                        MSISDN_id: DATA?.MSISDN_id,
                        phoneNumberSelectedTime: DATA?.phoneNumberSelectedTime,
                        buildBundle: DATA?.buildBundle,
                        payment: DATA?.payment,
                        tempBookingId: DATA?.tempBookingId,
                        bill: DATA?.bill,
                        bookingID: DATA?.bookingID,
                        promotion: DATA?.isPromotionApplied,
                        couponId: DATA?.couponId,
                        refugeeDocumentNumber: DATA?.refugeeDocumentNumber,
                        nationalIdIdNumber: DATA?.nationalIdIdNumber,
                        selectedPackage:DATA?.selectedPackage
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <PhoneBookingDetailsTable data={details} />
        </>
    )
}