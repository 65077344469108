import {
  PromotionApplyOnProps,
  PromotionBuildBundleInterval,
  PromotionConsumerProps,
  PromotionRechargeDataUnitProps,
  PromotionRechargeTypeProps,
  PromotionShowInPage,
  PromotionTextTypeProps,
  PromotionTypeProps,
  PromotionUiProps,
  PromotionUiTextTypeProps,
  PromotionUiThemeProps,
} from "_interfaces/functions/http-requests/promotion";

interface PromotionModelProps {
  id?: string;
  promotionType?: PromotionTypeProps | "";
  promotionConsumer?: PromotionConsumerProps[];
  amount?: string;
  promotionApplyOn?: PromotionApplyOnProps | "";
  rechargeType?: PromotionRechargeTypeProps | "";
  rechargeDataUnit?: PromotionRechargeDataUnitProps | "";
  buildBundleData_onNetVoice?: string;
  buildBundleData_offNetVoice?: string;
  buildBundleData_dataMb?: string;
  buildBundleData_smsOffNet?: string;
  buildBundleData_intervalId?: PromotionBuildBundleInterval | "";
  maxUse?: string;
  showInPage?: PromotionShowInPage[];
  UI?: null | PromotionUiProps;
  UI_theme?: PromotionUiThemeProps | "";
  UI_textType?: PromotionUiTextTypeProps | "";
  UI_text?: PromotionTextTypeProps["text"] | "";
  UI_textSize?: PromotionTextTypeProps["size"] | "";
  UI_textColor?: PromotionTextTypeProps["color"] | "";
  UI_textPosition?: PromotionTextTypeProps["position"] | "";
  UI_textLinePosition?: PromotionTextTypeProps["linePosition"] | "";
  UI_textShow?: boolean;
  assignDate?: Date | null;
  expiryDate?: Date | null;
  isActive?: boolean;
}
export class PromotionModel {
  id?: string;
  promotionType?: PromotionTypeProps | "";
  promotionConsumer?: PromotionConsumerProps[];
  amount?: string;
  promotionApplyOn?: PromotionApplyOnProps | "";
  rechargeType?: PromotionRechargeTypeProps | "";
  rechargeDataUnit?: PromotionRechargeDataUnitProps | "";
  buildBundleData_onNetVoice?: string;
  buildBundleData_offNetVoice?: string;
  buildBundleData_dataMb?: string;
  buildBundleData_smsOffNet?: string;
  buildBundleData_intervalId?: PromotionBuildBundleInterval | "";
  maxUse?: string;
  showInPage?: PromotionShowInPage[];
  UI?: null | PromotionUiProps;
  UI_theme?: PromotionUiThemeProps | "";
  UI_textType?: PromotionUiTextTypeProps | "";
  UI_text?: PromotionTextTypeProps["text"] | "";
  UI_textSize?: PromotionTextTypeProps["size"] | "";
  UI_textColor?: PromotionTextTypeProps["color"] | "";
  UI_textPosition?: PromotionTextTypeProps["position"] | "";
  UI_textLinePosition?: PromotionTextTypeProps["linePosition"] | "";
  UI_textShow?: boolean;
  assignDate?: Date | null;
  expiryDate?: Date | null;
  isActive?: boolean;

  constructor(data: PromotionModelProps = {}) {
    this.id = data.id || "";
    this.promotionType = data.promotionType || "";
    this.promotionConsumer = data.promotionConsumer || [];
    this.amount = data.amount || "";
    this.promotionApplyOn = data.promotionApplyOn || "";
    this.rechargeType = data.rechargeType || "";
    this.rechargeDataUnit = data.rechargeDataUnit || "";
    this.buildBundleData_onNetVoice = data.buildBundleData_onNetVoice || "";
    this.buildBundleData_offNetVoice = data.buildBundleData_offNetVoice || "";
    this.buildBundleData_dataMb = data.buildBundleData_dataMb || "";
    this.buildBundleData_smsOffNet = data.buildBundleData_smsOffNet || "";
    this.buildBundleData_intervalId = data.buildBundleData_intervalId || "";
    this.showInPage = data.showInPage || [];
    this.UI = data.UI || null;
    this.UI_theme = data.UI_theme || "";
    this.UI_textType = data.UI_textType || "";
    this.UI_text = data.UI_text || "";
    this.UI_textSize = data.UI_textSize || "";
    this.UI_textColor = data.UI_textColor || "";
    this.UI_textPosition = data.UI_textPosition || "";
    this.UI_textLinePosition = data.UI_textLinePosition || "";
    this.UI_textShow = data.UI_textShow || true;
    this.assignDate = data.assignDate || null;
    this.expiryDate = data.expiryDate || null;
    this.isActive = data.isActive || true;
  }
}
