import {
  LotteryPlayBasedOnProps,
  LotteryPromotionConsumerProps,
  LotteryPromotionPrizeProps,
  LotteryPromotionShowInPage,
  LotteryPromotionTextTypeProps,
  LotteryPromotionTypeProps,
  LotteryPromotionUiProps,
  LotteryPromotionUiTextTypeProps,
  LotteryPromotionUiThemeProps,
} from "_interfaces/functions/http-requests/promotion/lottery";

interface LotteryModelProps {
  id?: string;
  promotionType?: LotteryPromotionTypeProps | "";
  promotionConsumer?: LotteryPromotionConsumerProps | "";
  playBasedOn?: LotteryPlayBasedOnProps | "";
  playTime?: Date | null;
  count?: string;
  promotionPrize?: LotteryPromotionPrizeProps | "";
  startTime?: Date | null;
  maxWinner?: string;
  showInPage?: LotteryPromotionShowInPage[];
  UI?: null | LotteryPromotionUiProps;
  UI_theme?: LotteryPromotionUiThemeProps | "";
  UI_textType?: LotteryPromotionUiTextTypeProps | "";
  UI_text?: LotteryPromotionTextTypeProps["text"] | "";
  UI_textSize?: LotteryPromotionTextTypeProps["size"] | "";
  UI_textColor?: LotteryPromotionTextTypeProps["color"] | "";
  UI_textPosition?: LotteryPromotionTextTypeProps["position"] | "";
  UI_textLinePosition?: LotteryPromotionTextTypeProps["linePosition"] | "";
  UI_textShow?: boolean;
  assignDate?: Date | null;
  expiryDate?: Date | null;
  isActive?: boolean;
}
export class LotteryModel {
  id?: string;
  promotionType?: LotteryPromotionTypeProps | "";
  promotionConsumer?: LotteryPromotionConsumerProps | "";
  playBasedOn?: LotteryPlayBasedOnProps | "";
  playTime?: Date | null;
  count?: string;
  promotionPrize?: LotteryPromotionPrizeProps | "";
  startTime?: Date | null;
  maxWinner?: string;
  showInPage?: LotteryPromotionShowInPage[];
  UI?: null | LotteryPromotionUiProps;
  UI_theme?: LotteryPromotionUiThemeProps | "";
  UI_textType?: LotteryPromotionUiTextTypeProps | "";
  UI_text?: LotteryPromotionTextTypeProps["text"] | "";
  UI_textSize?: LotteryPromotionTextTypeProps["size"] | "";
  UI_textColor?: LotteryPromotionTextTypeProps["color"] | "";
  UI_textPosition?: LotteryPromotionTextTypeProps["position"] | "";
  UI_textLinePosition?: LotteryPromotionTextTypeProps["linePosition"] | "";
  UI_textShow?: boolean;
  assignDate?: Date | null;
  expiryDate?: Date | null;
  isActive?: boolean;

  constructor(data: LotteryModelProps = {}) {
    this.id = data.id || "";
    this.promotionType = data.promotionType || "";
    this.promotionConsumer = data.promotionConsumer || "";
    this.playBasedOn = data.playBasedOn || "";
    this.playTime = data.playTime || null;
    this.count = data.count || "";
    this.promotionPrize = data.promotionPrize || "";
    this.startTime = data.startTime || null;
    this.maxWinner = data.maxWinner || "";
    this.showInPage = data.showInPage || [];
    this.UI = data.UI || null;
    this.UI_theme = data.UI_theme || "";
    this.UI_textType = data.UI_textType || "";
    this.UI_text = data.UI_text || "";
    this.UI_textSize = data.UI_textSize || "";
    this.UI_textColor = data.UI_textColor || "";
    this.UI_textPosition = data.UI_textPosition || "";
    this.UI_textLinePosition = data.UI_textLinePosition || "";
    this.UI_textShow = data.UI_textShow || true;
    this.assignDate = data.assignDate || null;
    this.expiryDate = data.expiryDate || null;
    this.isActive = data.isActive || true;
  }
}
