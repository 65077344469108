import { AssignTasksProps, GetAllTaskProps } from "_interfaces/functions/http-requests/tasks"
import { ASSIGN_TASK, GET_ALL_TASK } from "config/endpoint"
import { HTTP } from "functions/http"

export const GetAllTask = ({ DATA }: GetAllTaskProps) => {
    return HTTP({
        Method: "POST",
        Url: GET_ALL_TASK,
        Data: DATA,
    })
}
export const AssignTask = ({ DATA }: AssignTasksProps) => {
    return HTTP({
        Method: "POST",
        Url: ASSIGN_TASK,
        Data: DATA,
    })
}
