interface InfluencerErrorModelProps {
  email?: string;
  phoneNumber?: string;
  category?: string;
  couponId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  region?: string;
  district?: string;
  county?: string;
  subCounty?: string;
  parish?: string;
}

export class InfluencerErrorModel {
  email?: string;
  phoneNumber?: string;
  category?: string;
  couponId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  region?: string;
  district?: string;
  county?: string;
  subCounty?: string;
  parish?: string;

  constructor(data: InfluencerErrorModelProps = {}) {
    this.email = data.email || "";
    this.phoneNumber = data.phoneNumber || "";
    this.category = data.category || "";
    this.couponId = data.couponId || "";
    this.firstName = data.firstName || "";
    this.middleName = data.middleName || "";
    this.lastName = data.lastName || "";
    this.region = data.region || "";
    this.district = data.district || "";
    this.county = data.county || "";
    this.subCounty = data.subCounty || "";
    this.parish = data.parish || "";
  }
}
