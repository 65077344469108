import { GLOBAL_ACTIVITY_LOGS_PROPS } from "_interfaces/global-activity-logs";
import { GET_GLOBAL_ACTIVITY_LOG_DETAILS, GET_GLOBAL_ACTIVITY_LOGS_LIST } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetGlobalActivityLogsList = ({ DATA }: GLOBAL_ACTIVITY_LOGS_PROPS) => {
    return HTTP({
        Method: "POST",
        Url: GET_GLOBAL_ACTIVITY_LOGS_LIST,
        Data: DATA,
    });
};

export const GetGlobalActivityLogDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: GET_GLOBAL_ACTIVITY_LOG_DETAILS(ID)
    });
};
