import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { CartonList } from "_interfaces/carton";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import DetailsTable from "parts/table/detailsTable";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
    data: CartonList | null | undefined;
    handleDialog: (type: string) => void
}

const CartonDetailsTable: React.FC<Props> = ({ data, handleDialog }) => {
    const navigate = useNavigate();

    return (
        <>
            {data?.id ? (
                <DetailsTable>
                    <TableRow>
                        <TableCell>First ICCID</TableCell>
                        <TableCell>
                            <Box className="tw-capitalize">
                                {data?.firstICCID}
                            </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Last ICCID</TableCell>
                        <TableCell>
                            <Box className="tw-capitalize">
                                {data?.lastICCID}
                            </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Distributor</TableCell>
                        <TableCell>
                            {data?.distributorDetails ?
                                <Box
                                    sx={{
                                        textDecoration: "underline",
                                        color: 'blue',
                                        fontSize: '16px',
                                        cursor: "pointer"
                                    }}
                                    onClick={() => navigate(`${RouteConstant.REGIONAL_DISTRIBUTORS}/${data?.distributorDetails?._id}`)}>
                                    {data?.distributorDetails?.name}
                                </Box>
                                :
                                <Button onClick={() => handleDialog("distributor")} >Assign Distributor</Button>}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Agent</TableCell>
                        <TableCell>
                            {data?.agentDetails ?
                                <Box
                                    sx={{
                                        textDecoration: "underline",
                                        color: 'blue',
                                        fontSize: '16px',
                                        cursor: "pointer"
                                    }}
                                    onClick={() => navigate(`${RouteConstant.AGENTS}/${data?.agentDetails?._id}`)}>
                                    {data?.agentDetails?.firstName} {data?.agentDetails?.middleName} {data?.agentDetails?.lastName}
                                </Box>
                                :
                                <Button
                                    onClick={() => handleDialog("agent")}>
                                    Assign Agent
                                </Button>}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Batch No.</TableCell>
                        <TableCell>{data?.batchNo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Box No.</TableCell>
                        <TableCell>{data?.boxNo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Quantity</TableCell>
                        <TableCell>{data?.quantity}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sim Type</TableCell>
                        <TableCell>{data?.SIM_type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Service Type</TableCell>
                        <TableCell>{data?.serviceType}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Time</TableCell>
                        <TableCell>{moment(data?.date).format("MMM D YYYY h:mm A")}</TableCell>
                    </TableRow>
                </DetailsTable>
            ) : (
                <></>
            )}
        </>
    );
};

export default CartonDetailsTable;
