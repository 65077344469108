import { useEffect, useRef, useState } from "react";
import DistributorAnalytics from "./distributorAnalytics";
import { GetCustomerByDistributorId, GETCUSTOMERCOUNTBYDISTRIUTOR } from "functions/http-requests/customer-management";
import { AgentDistributorDetails, CartonListByDistributor, DistributorDetailsAnalytics } from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import DistributorDetailsTable from "./distributorDetailsTable";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import { AppStatusCode } from "config/appStatusCode";
import { GetRegionalDistributorById } from "functions/http-requests/regional-distributors";
import { RegionalDistributorsTableRowsInterface } from "_interfaces/regional-distributors/regionalDistributorsTable";
import { AgentGetAllByDistributorProps } from "_interfaces/functions/http-requests/agents";
import { AgentGetAllByDistributor } from "functions/http-requests/agents";
import { filterNonNullValues } from "functions/helper";
import { GridPaginationModel } from "@mui/x-data-grid";
import CustomerManagementTable from "component/customer-management/customerManagementTable";
import CustomerManagementTableInterface from "_interfaces/customer-management/customerManagementTable";
import moment from "moment";
import { GetAllCustomersByDistributorProps, GetAllCustomersProps } from "_interfaces/functions/http-requests/customer-management";
import { Box, Card, Grid, Typography } from "@mui/material";
import AgentDetailsTable from "./agentDetailsTable";
import { CustomerManagementFilterModel } from "_models/data/customer-management/data.customer-management-filter.model";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import CustomerManagementFilter from "component/customer-management/customerManagementFilter";
import AgentDetailsFilter from "./agentDetailsFilter";
import { GetCartonByDistributor } from "functions/http-requests/carton";
import CartonsTable from "./cartonsTable";
interface Props {
    ID: string
}

const DistributorDeailsLayout: React.FC<Props> = ({ ID }) => {
    const isInitialRender = useRef(true);
    const isInitialAgentRender = useRef(true);
    const [analyticsData, setAnalyticsData] = useState<DistributorDetailsAnalytics[]>([]);
    const [distributorDetails, setDistributorDetails] = useState<RegionalDistributorsTableRowsInterface | null>(null);
    const [agentPaginationModel, setAgentPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [customerPaginationModel, setCustomerPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const Dispatch = useDispatch();
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [agentsDataLoading, setAgentsDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<CustomerManagementTableInterface["data"]>(
        []
    );
    const [totalCustomercount, setTotalCustomerCount] = useState<number>(0);
    const [totalAgentcount, setTotalAgentCount] = useState<number>(0);
    const [agentsData, setAgentsData] = useState<AgentDistributorDetails[]>([]);
    const [userId, setUserId] = useState<string | null>(null);
    const [customerFilters, setCustomerFilters] = useState<CustomerManagementFilterModel>(
        new CustomerManagementFilterModel()
    );
    const [refresh, setRefresh] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>("");
    const handleRefresh = () => setRefresh(!refresh);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [agentRefresh, setAgentRefresh] = useState<boolean>(false);
    const [agentSearchKey, setAgentSearchKey] = useState<string>("");
    const handleAgentRefresh = () => setAgentRefresh(!agentRefresh);
    const [openAgentFilter, setOpenAgentFilter] = useState<boolean>(false);
    const [agentsFilters, setAgentsFilters] = useState<any>(
        new CustomerManagementFilterModel()
    );
    const [cartons, setCartons] = useState<CartonListByDistributor[]>([]);
    const [cartonLoading, setCartonLoading] = useState<boolean>(false);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    const handleClearSearch = () => setSearchKey("");

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setCustomerPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    // AGENT HANDLERS
    const handleAgentSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setAgentSearchKey(value);
    };

    const toggleAgentFilter = () => {
        setOpenAgentFilter(!openAgentFilter);
    };

    const handleClearAgentSearch = () => setAgentSearchKey("");

    const handleSubmitAgentSearch = () => {
        isInitialAgentRender.current = false;
        setAgentPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleAgentRefresh();
    };

    const fetchDistributorAnalytics = () => {
        Dispatch(toggleLoading(true));
        GETCUSTOMERCOUNTBYDISTRIUTOR(ID)
            .then(res => setAnalyticsData(res?.data?.data))
            .catch(err => setAnalyticsData([]))
            .finally(() => Dispatch(toggleLoading(false)))
    }

    const fetchDistributorDetails = () => {
        Dispatch(toggleLoading(true));
        GetRegionalDistributorById(ID)
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    setUserId(DATA?.userDetails?._id);
                    setDistributorDetails({
                        id: DATA?._id,
                        email: DATA?.userDetails?.email,
                        name: DATA?.userDetails?.name,
                        phone: DATA?.userDetails?.phone,
                        isActive: DATA?.isActive,
                        date: DATA?.createdAt,
                        assignedLocation: [
                            {
                                region: DATA?.assignedLocation[0]?.region,
                                districts: DATA?.assignedLocation[0]?.districts
                            }
                        ],
                    })
                }
            })
            .catch((error) => {
                setDistributorDetails(null)
            })
            .finally(() => Dispatch(toggleLoading(false)));
    }

    const fetchCartons = () => {
        setCartonLoading(true);
        GetCartonByDistributor(ID)
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    DATA = DATA.map((item: any) => ({
                        id: item._id,
                        firstICCID: item?.firstICCID,
                        lastICCID: item?.lastICCID,
                        batchNo: item?.batchNo,
                        boxNo: item?.boxNo,
                        quantity: item?.quantity,
                        date: item?.date,
                        SIM_type: item?.SIM_type,
                        serviceType: item?.serviceType,
                        regDistributor: item?.regDistributor
                    }))
                    setCartons(DATA);
                }
            })
            .catch((error) => {
                setCartons([])
            })
            .finally(() => setCartonLoading(false));
    }

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);
            let PAYLOAD_DATA: GetAllCustomersByDistributorProps["DATA"] = {
                searchKey: searchKey,
                distributorId: ID,
                kycStatus: customerFilters?.kycStatus || undefined,
                serviceStatus: customerFilters?.serviceStatus || undefined,
                customerType: customerFilters?.customerType || undefined,
                identityType: customerFilters?.identityType || undefined,
                fromDate: customerFilters?.fromDate
                    ? moment(customerFilters.fromDate).format("YYYY/MM/DD")
                    : undefined,
                toDate: customerFilters?.toDate
                    ? moment(customerFilters.toDate).format("YYYY/MM/DD")
                    : undefined,
                pageNumber: customerPaginationModel.page + 1,
                pageSize: customerPaginationModel.pageSize,
            };

            let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetCustomerByDistributorId({
                DATA: FILTERED_PAYLOAD_DATA,
            })
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count;
                        if (count && count > 1) {
                            if (count > 1) setTotalCustomerCount(count);
                        } else setTotalCustomerCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            MSISDN: item?.MSISDN || "",
                            nameAsDocument: item?.nameAsDocument || "",
                            identityType: item?.identityType || "",
                            kyc_Details: item?.kyc_Details,
                            MSISDN_details: item?.MSISDN_details,
                            createdAt: item?.createdAt || "",
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setTotalCustomerCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setTotalCustomerCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [customerPaginationModel, refresh, customerFilters, searchKey]);

    useEffect(() => {
        fetchDistributorAnalytics();
        fetchDistributorDetails();
        fetchCartons();
    }, [])

    useEffect(() => {

        let fetchAllAgentByDistributor: (() => void) | null = () => {
            if (!ID) return;
            setAgentsDataLoading(true);
            let PAYLOAD_DATA: AgentGetAllByDistributorProps["DATA"] = {
                searchKey: agentSearchKey,
                distributorId: ID,
                type: agentsFilters?.type || undefined,
                isActive: agentsFilters.activeStatus == "active" ? true : agentsFilters.activeStatus == "inActive" ? false : undefined,
                isApprove: agentsFilters.approveStatus == "approve" ? true : agentsFilters.approveStatus == "notApprove" ? false : undefined,
                pageNumber: agentPaginationModel.page + 1,
                pageSize: agentPaginationModel.pageSize,
                fromDate: agentsFilters?.fromDate
                    ? moment(agentsFilters.fromDate).format("YYYY/MM/DD")
                    : undefined,
                toDate: agentsFilters?.toDate
                    ? moment(agentsFilters.toDate).format("YYYY/MM/DD")
                    : undefined,
            };

            let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);
            AgentGetAllByDistributor({ DATA: FILTERED_PAYLOAD_DATA })
                .then(res => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count;
                        if (count && count > 1) {
                            if (count > 1) setTotalAgentCount(count);
                        } else setTotalAgentCount(0);
                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            name: item?.userDetails?.name || "",
                            email: item?.userDetails?.email || "",
                            phone: item?.userDetails?.phone || "",
                            region: item?.assignedLocation[0]?.region || "",
                            date: item?.createdAt || "",
                            isActive: item?.isActive || ""
                        }));
                        setAgentsData(DATA);
                        setTotalAgentCount(res?.data?.meta?.count)
                    }
                    else {
                        setAgentsData([]);
                        setTotalAgentCount(0);
                    }
                })
                .catch(() => {
                    setAgentsData([]);
                    setTotalAgentCount(0);
                })
                .finally(() => {
                    setAgentsDataLoading(false);
                });
        };
        fetchAllAgentByDistributor();
        return () => {
            fetchAllAgentByDistributor = null;
        };
    }, [agentSearchKey, agentRefresh, agentsFilters, agentPaginationModel])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <DistributorAnalytics Data={analyticsData} />
            {distributorDetails && <DistributorDetailsTable DATA={distributorDetails} UserId={userId} />}

            {/* //AGENTS TABLE// */}
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Agents List"}
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Box sx={{ flex: "1 1 auto" }}>
                            <SectionSearch
                                name="search_sim"
                                value={agentSearchKey}
                                onChange={handleAgentSearch}
                                onClear={handleClearAgentSearch}
                                onSubmit={handleSubmitAgentSearch}
                            />
                        </Box>
                        <FilterButton onClick={() => setOpenAgentFilter(true)} />
                    </Grid>
                </Grid>
                <AgentDetailsTable
                    DATA={agentsData}
                    loading={agentsDataLoading}
                    count={totalAgentcount}
                    paginationModel={agentPaginationModel}
                    setPaginationModel={setAgentPaginationModel} />

                <AgentDetailsFilter
                    open={openAgentFilter}
                    onClose={toggleAgentFilter}
                    filters={agentsFilters}
                    setFilters={setAgentsFilters}
                />
            </Card>

            {/* //CARTONS TABLE// */}
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Cartons List"}
                </Typography>
                <CartonsTable DATA={cartons} loading={cartonLoading}/>

            </Card>

            {/* //CUSTOMERS TABLE// */}
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography color="text.primary" className="tw-text-[18px]" sx={{ fontWeight: "bold" }}>
                    {"Customers List"}
                </Typography>
                <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
                    <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                        <Box sx={{ flex: "1 1 auto" }}>
                            <SectionSearch
                                name="search_sim"
                                value={searchKey}
                                onChange={handleSearch}
                                onClear={handleClearSearch}
                                onSubmit={handleSubmitSearch}
                            />
                        </Box>
                        <FilterButton onClick={() => setOpenFilter(true)} />
                    </Grid>
                </Grid>
                <CustomerManagementTable data={data}
                    loading={dataLoading}
                    count={totalCustomercount}
                    paginationModel={customerPaginationModel}
                    setPaginationModel={setCustomerPaginationModel} />
                <CustomerManagementFilter
                    open={openFilter}
                    onClose={toggleFilter}
                    filters={customerFilters}
                    setFilters={setCustomerFilters}
                />
            </Card>
        </Box>
    )
};

export default DistributorDeailsLayout;