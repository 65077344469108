import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { ALLOWED_INFLUENCER_CATEGORY } from "_interfaces/functions/http-requests/influencer";
import { InfluencerFilterModel } from "_models/data/influencer/data.influencer-filter.model";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import { generateAutocompleteOptions } from "functions/helper";
import SideDrawer from "parts/sideDrawer";
import React, { Fragment, useMemo } from "react";

interface InputListProps extends FormInputProps {
  name: "category";
}
interface Props extends SideDrawerProps {
  filters: InfluencerFilterModel;
  setFilters: React.Dispatch<React.SetStateAction<InfluencerFilterModel>>;
}

const SimManagementFilter: React.FC<Props> = ({
  open,
  onClose,
  filters,
  setFilters,
}) => {
  const handleAutoComplete = (
    e: React.SyntheticEvent,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setFilters((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "category",
        label: "Filter by Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(ALLOWED_INFLUENCER_CATEGORY),
        multiple: false,
      },
    ],
    [ALLOWED_INFLUENCER_CATEGORY]
  );

  return (
    <>
      <SideDrawer open={open} onClose={onClose}>
        <Box id="filter_influencer">
          <Grid container rowSpacing={3} columnSpacing={2}>
            {inputList.map(
              (
                { type, name, label, placeholder, options, multiple },
                index
              ) => (
                <Fragment key={index}>
                  <>
                    {type === "autoComplete" && options?.length ? (
                      <Grid item xs={12}>
                        <InputAutoComplete
                          name={name}
                          options={options}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options?.length &&
                            filters?.[name as keyof InfluencerFilterModel]
                          }
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                </Fragment>
              )
            )}
          </Grid>
        </Box>
      </SideDrawer>
    </>
  );
};

export default SimManagementFilter;
