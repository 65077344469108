import { CartonList } from "_interfaces/carton";
import { AppStatusCode } from "config/appStatusCode";
import { AssignCartonToAgent, AssignCartonToDistributor, GetAllCartons } from "functions/http-requests/carton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import CartonDetailsTable from "./CartonDetailsTable";
import { Autocomplete, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { GetAllRegionalDistributor } from "functions/http-requests/regional-distributors";
import { GetAllAgentList } from "functions/http-requests/agents";
import { setAlert } from "state/reducers/alert";

interface Props {
    id: string
}

const CartonDetailsLayout: React.FC<Props> = ({ id }) => {
    const [carton, setCarton] = useState<CartonList | undefined | null>(undefined);
    const Dispatch = useDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string>("");
    const [modalContent, setModalContent] = useState([]);
    const [assignedId, setAssignedId] = useState<string | null>("");
    const [assignedName, setAssignedName] = useState<string | null>("");
    const [errorText, setErrorText] = useState<string | null>("");

    const fetchCarton = () => {
        Dispatch(toggleLoading(true));
        GetAllCartons().then(res => {
            const data = res?.data;

            if (data?.statusCode === AppStatusCode.api_success) {
                const DATA: any = data?.data;
                let resultedData;
                DATA.forEach((item: any) => {
                    if (item._id == id) {
                        resultedData = {
                            id: item._id,
                            firstICCID: item?.firstICCID,
                            lastICCID: item?.lastICCID,
                            batchNo: item?.batchNo,
                            boxNo: item?.boxNo,
                            cartonNo: item?.cartonNo,
                            quantity: item?.quantity,
                            date: item?.date,
                            SIM_type: item?.SIM_type,
                            serviceType: item?.serviceType,
                            distributorDetails: item?.distributorDetails,
                            agentDetails: item?.agentDetails
                        }
                    }
                })
                setCarton(resultedData);
            }
        }).catch((error) => {
            setCarton(null)
        })
            .finally(() => Dispatch(toggleLoading(false)));
    }

    const handleDialog = (type: string) => {
        setModalType(type);
        let fetchContent;
        if (type == "distributor") {
            fetchContent = GetAllRegionalDistributor()
        }
        else {
            fetchContent = GetAllAgentList()
        }
        fetchContent
            .then((res) => {
                const data = res?.data;

                if (data?.statusCode === AppStatusCode.api_success) {

                    let DATA: any = res?.data?.data;
                    DATA = DATA?.map((item: any) => ({
                        id: item?._id,
                        label: item?.name || item?.userDetails?.name,
                    }));
                    setModalContent(DATA);
                } else {
                    setModalContent([]);
                }
            })
            .catch(() => {
                setModalContent([]);
            })
            .finally(() => {
                Dispatch(toggleLoading(false))
            });
        setOpenModal(true);
    }

    const closeModal = () => {
        setOpenModal(false);
        setErrorText(null);
        setOpenConfirmationModal(false)
    }

    const handleAssign = () => {
        if (!assignedId) {
            setErrorText(`Please select ${modalType == "distributor" ? "a" : "an"} ${modalType}`);
            return;
        };

        if (modalType == "distributor") {
            Dispatch(toggleLoading(true));
            AssignCartonToDistributor({ DATA: { distributer_id: assignedId } }, id)
                .then(res => {
                    if (res?.data?.level == "error") {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: res?.data?.message,
                            })
                        );
                    }
                    else {
                        Dispatch(
                            setAlert({
                                type: "success",
                                message: res?.data?.message,
                            })
                        );
                        setOpenModal(false);
                        setOpenConfirmationModal(false);
                        fetchCarton();
                    }
                })
                .catch(err => {
                    Dispatch(
                        setAlert({
                            type: "error",
                            message: "Some error occoured",
                        })
                    );
                })
                .finally(() => Dispatch(toggleLoading(false)))
        }
        else {
            Dispatch(toggleLoading(true))
            AssignCartonToAgent({ DATA: { agent_id: assignedId } }, id)
                .then(res => {
                    if (res?.data?.level == "error") {
                        Dispatch(
                            setAlert({
                                type: "error",
                                message: res?.data?.message,
                            })
                        );
                    }
                    else {
                        Dispatch(
                            setAlert({
                                type: "success",
                                message: res?.data?.message,
                            })
                        );
                        setOpenModal(false);
                        setOpenConfirmationModal(false);
                        fetchCarton();
                    }
                })
                .catch(err => {
                    Dispatch(
                        setAlert({
                            type: "error",
                            message: "Some error occoured",
                        })
                    );
                })
                .finally(() => Dispatch(toggleLoading(false)))
        }
    }

    useEffect(() => {
        fetchCarton();
    }, [])

    return (
        <>
            <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography className="tw-text-[18px]" sx={{ fontWeight: "bold", color: "#F1952B" }}>
                    Carton List</Typography>
                <CartonDetailsTable data={carton} handleDialog={handleDialog} />
            </Card>
            <Dialog
                open={openModal}
                onClose={closeModal}
            >
                <DialogTitle>
                    {modalType == "distributor" ? "Select A Distributor" : "Select An Agent"}
                </DialogTitle>
                <DialogContent
                    sx={{
                        height: "18rem"
                    }}
                >
                    <Autocomplete
                        disablePortal
                        options={modalContent}
                        sx={{ width: 500, padding: "1rem 0" }}
                        onChange={(event, value: any) => {
                            setAssignedId(value?.id);
                            setAssignedName(value?.label)
                            setOpenConfirmationModal(true)
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                label={modalType == "distributor" ? "Enter Distributor Name" : "Enter Agent Name"}
                                error={errorText ? true : false}
                                helperText={errorText}
                            />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeModal} >Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
            >
                <DialogContent>
                    Are you sure you want to assign "{assignedName}" to this carton?
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)} >Close</Button>
                    <Button variant="outlined" onClick={handleAssign} >Assign</Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default CartonDetailsLayout;