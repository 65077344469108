interface CouponErrorModelProps {
  category?: string;
  applyOn?: string;
  couponCode?: string;
  validFrom?: string;
  validUntil?: string;
  maxUse?: string;
  discountType?: string;
  discountValue?: string;
  minPurchaseAmount?: string;
  maxDiscountAmount?: string;
  isOpen?: string;
  info?: string;
  title?: string;
}

export class CouponErrorModel {
  category?: string;
  applyOn?: string;
  couponCode?: string;
  validFrom?: string;
  validUntil?: string;
  maxUse?: string;
  discountType?: string;
  discountValue?: string;
  minPurchaseAmount?: string;
  maxDiscountAmount?: string;
  isOpen?: string;
  info?: string;
  title?: string;

  constructor(data: CouponErrorModelProps = {}) {
    this.category = data.category || "";
    this.applyOn = data.applyOn || "";
    this.couponCode = data.couponCode || "";
    this.validFrom = data.validFrom || "";
    this.validUntil = data.validUntil || "";
    this.maxUse = data.maxUse || "";
    this.discountType = data.discountType || "";
    this.discountValue = data.discountValue || "";
    this.minPurchaseAmount = data.minPurchaseAmount || "";
    this.maxDiscountAmount = data.maxDiscountAmount || "";
    this.isOpen = data.isOpen || "";
    this.info = data.info || "";
    this.title = data.title || "";
  }
}
