import { InfluencerCategoryProps } from "_interfaces/functions/http-requests/influencer";

interface InfluencerFilterModelProps {
  category?: InfluencerCategoryProps | "";
}

const defaultInfluencerFilterModelProps: InfluencerFilterModelProps = {
  category: "",
};

export class InfluencerFilterModel {
  category?: InfluencerCategoryProps | "";

  constructor(
    data: InfluencerFilterModelProps = defaultInfluencerFilterModelProps
  ) {
    this.category = data.category || "";
  }
}
