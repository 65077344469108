import CouponLayout from "component/coupon";
import MainContainer from "parts/mainContainer";

const Coupon = () => {
  return (
    <MainContainer>
      <CouponLayout />
    </MainContainer>
  );
};

export default Coupon;
