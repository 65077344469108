import { PaymentDetailsLayout } from "component/payments/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const PaymentDetails = () => {
    const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.PAYMENTS} />;

  return (
    <MainContainer>
      <PaymentDetailsLayout id={params.id} />
    </MainContainer>
  );
}