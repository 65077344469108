
export interface CreateTeamModelProps {
  teamName?: "";
  teamLocation?: "";
  managerName?: "";
  email?: "";
  managerPhone?: "";
  password?: "";
  region?: "";
  district?: "";
  county?: "";
  subCounty?: "";
  parish?: "";
  acknowledgementForm?: any;
}

export class CreateTeamModel {
  teamName?: string;
  teamLocation?: string;
  managerName?: string;
  email?: string;
  managerPhone?: string;
  password?: string;
  region?: string;
  district?: string;
  county?: string;
  subCounty?: string;
  parish?: string;
  acknowledgementForm?: any;

  constructor(data: CreateTeamModelProps = {}) {
    this.teamName = data.teamName || "";
    this.teamLocation = data.teamLocation || "";
    this.managerName = data.managerName || "";
    this.email = data.email || "";
    this.managerPhone = data.managerPhone || "";
    this.password = data.password || "";
    this.region = data.region || "";
    this.district = data.district || "";
    this.county = data.county || "";
    this.subCounty = data.subCounty || "";
    this.parish = data.parish || "";
    this.acknowledgementForm = data.acknowledgementForm || "";
  }
}

interface CreateTeamErrorModelProps {
  teamName?: "";
  teamLocation?: "";
  managerName?: "";
  managerEmail?: "";
  managerPhone?: "";
  password?: "";
}


export class CreateTeamErrorModel {
  teamName?: string;
  teamLocation?: string;
  managerName?: string;
  managerEmail?: string;
  managerPhone?: string;
  password?: string;

  constructor(data: CreateTeamErrorModelProps = {}) {
    this.teamName = data.teamName || "";
    this.teamLocation = data.teamLocation || "";
    this.managerName = data.managerName || "";
    this.managerEmail = data.managerEmail || "";
    this.managerPhone = data.managerPhone || "";
    this.password = data.password || "";
  }
}

