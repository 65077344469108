import { DeRegistrationDetailsInterface } from "_interfaces/deRegistration";
import { AppStatusCode } from "config/appStatusCode";
import { ApproveDEReg, GetDeRegistrationDetails } from "functions/http-requests/de-registration";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import DeRegistrationDetailsTable from "./detailsTable";
import StatusDialog from "parts/customDialog/statusDialog";
import { ApproveDeRegProps } from "_interfaces/functions/http-requests/de-registration";
import { setAlert } from "state/reducers/alert";

interface Props {
    id: string
}

export const DeRegistrationDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<DeRegistrationDetailsInterface | null>(null);
    const Dispatch = useDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleApprove = () => {
        Dispatch(toggleLoading(true));
        const props: ApproveDeRegProps = {
            DATA: {
                id: id
            }
        }
        ApproveDEReg(props)
            .then((res) => {
                const data = res.data;
                if (data.level == "success") {
                    Dispatch(setAlert({ type: "success", message: "Approved successfully!" }))
                } else {
                    Dispatch(setAlert({ type: "error", message: "Failed to approve!" }))
                }
            })
            .catch(err => {
                Dispatch(setAlert({ type: "error", message: "Error in approve!" }))
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            })
    }

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetDeRegistrationDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        MSISDN: DATA?.MSISDN,
                        status: DATA?.status,
                        SIM: DATA?.SIM,
                        kyc: DATA?.kyc,
                        registerBy: DATA?.registerBy,
                        isNiraVerified: DATA?.isNiraVerified,
                        isRefugeeVerified: DATA?.isRefugeeVerified,
                        reason: DATA?.reason,
                        handlerId: DATA?.handlerId,
                        agent: DATA?.agent,
                        identityType: DATA?.identityType,
                        customer: DATA?.customer
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <DeRegistrationDetailsTable data={details} handleOpen={handleOpen} />
            <StatusDialog
                open={open}
                onClose={handleClose}
                onChangeStatus={handleApprove}
                title={"Approve Status"}
                successButtonName={"Approve"}
                content={`Are you sure you want to Approve? `}
            />
        </>
    )
}