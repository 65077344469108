export class UpdateBookingErrorModel {
    name?: string;
    email?: string;
    nationalIdIdNumber?: string;
    passportNumber?: string;
    refugeeDocumentNumber?: string;
  
    constructor(data: Partial<UpdateBookingErrorModel> = defaultUpdateBookingErrorProps) {
      this.name = data.name || "";
      this.email = data.email || "";
      this.nationalIdIdNumber = data.nationalIdIdNumber || "";
      this.passportNumber = data.passportNumber || "";
      this.refugeeDocumentNumber = data.refugeeDocumentNumber || "";
    }
  }
  
  const defaultUpdateBookingErrorProps: UpdateBookingErrorModel = {
    name: "",
    email: "",
    nationalIdIdNumber: "",
    passportNumber: "",
    refugeeDocumentNumber: "",
  };
  