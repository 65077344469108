import { GridPaginationModel } from "@mui/x-data-grid";
import { WalletTransactionFilterProps } from "_interfaces/functions/http-requests/wallet-transaction";
import { WalletTransactionFilterModel } from "_models/data/walletTransaction/data.wallet-transaction-filter.model";
import WalletTransactionTableInterface from "_models/data/walletTransaction/data.wallet-transaction.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetWalletTransactionList } from "functions/http-requests/wallet-transaction";
import { useRef, useState, useEffect } from "react";
import WalletTransactionTable from "./walletTransactionTable";
import WalletTransactionFilter from "./walletTransactionFilter";
import { Grid, Box } from "@mui/material";
import SectionSearch from "parts/sectionSearch";

export const WalletTransactionLayout = () => {
    const isInitialRender = useRef(true);
    const [searchKey, setSearchKey] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const handleClearSearch = () => setSearchKey("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<WalletTransactionTableInterface["data"]>(
        []
    );
    const [filters, setFilters] = useState<WalletTransactionFilterModel>(
        new WalletTransactionFilterModel()
    );
    const [count, setCount] = useState<number>(0);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: WalletTransactionFilterProps["DATA"] = {
                searchKey: searchKey,
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                fromAmount: filters.fromAmount,
                toAmount: filters.toAmount,
                paymentType: filters?.paymentType,
                fromDate: filters.fromDate,
                toDate: filters.toDate
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetWalletTransactionList({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            walletID: item?.walletID,
                            amount: item?.amount,
                            paidTime: item?.paidTime,
                            createdAt: item?.createdAt,
                            paymentId: item?.paymentId,
                            paymentDetails: item?.paymentDetails,
                            walletPayment: item?.walletPayment

                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [refresh, paginationModel, filters, searchKey]);
    return (
        <>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                <Box sx={{ flex: "1 1 auto" }}>
                    <SectionSearch
                        name="search_waalet_transaction"
                        value={searchKey}
                        onChange={handleSearch}
                        onClear={handleClearSearch}
                        onSubmit={handleSubmitSearch}
                    />
                </Box>
                <WalletTransactionFilter
                    open={openFilter}
                    onClose={toggleFilter}
                    filters={filters}
                    setFilters={setFilters}
                    setOpen={setOpenFilter}
                />
            </Grid>
        </Grid >
            <WalletTransactionTable
                data={data}
                loading={dataLoading}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    )
}