import { AppStatusCode } from "config/appStatusCode";
import { GetAllVersion } from "functions/http-requests/apps";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import AppVersionHistoryTable from "./AppVersionHistoryTable";

const AppVersionHistoryLayout = () => {
    const [allVersion, setAllVersion] = useState<any[]>([]);
    const Dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchAllAppversion = () => {
        setLoading(true);
        GetAllVersion()
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    DATA = DATA?.map((item: any) => ({
                        id: item?._id,
                        currentVersion: item?.currentVersion || "",
                        releaseDate: item?.releaseDate || "",
                        description: item?.description || "",
                    }));
                    setAllVersion(DATA);
                }
            })
            .catch((error) => {
                setAllVersion([])
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchAllAppversion()
    }, [])

    return (
        <>
            <AppVersionHistoryTable data={allVersion} loading={loading} />
        </>
    )
}

export default AppVersionHistoryLayout;