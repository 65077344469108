import { HTTP } from "functions/http";

import {
  CREATE_LOTTERY,
  GET_ALL_LOTTERY,
  GET_LOTTERY_BY_ID,
  UPDATE_LOTTERY,
} from "config/endpoint";
import {
  LotteryCreateProps,
  LotteryUpdateProps,
} from "_interfaces/functions/http-requests/promotion/lottery";

export const CreateLottery = ({ DATA }: LotteryCreateProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_LOTTERY,
    Data: DATA,
  });
};

export const UpdateLottery = ({ DATA }: LotteryUpdateProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_LOTTERY,
    Data: DATA,
  });
};

export const GetAllLottery = () => {
  return HTTP({
    Method: "GET",
    Url: GET_ALL_LOTTERY,
  });
};

export const GetLotteryById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_LOTTERY_BY_ID(ID),
  });
};
