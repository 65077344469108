import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import PaymentTableInterface from "_models/data/payment/data.payment.model";
import { Button } from "@mui/material";
import SimSwapTableInterface from "_models/data/sim-swap/data.sim-swap.model";

const SimSwapTable: React.FC<
  SimSwapTableInterface
> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
    const { palette } = useTheme();
    const navigate = useNavigate();


    const columns: GridColDef[] = [
      {
        field: "MSISDN", headerName: "MSISDN", flex: 1, minWidth: 150,
      },
      { field: "oldICCID", headerName: "Old ICCID", flex: 1, minWidth: 150 },
      {
        field: "ICCID", headerName: "ICCID", flex: 1, minWidth: 200,
      },
      {
        field: "swapKyc", headerName: "KYC", flex: 1, minWidth: 120,
        renderCell: (params) => <Button
          onClick={() => navigate(`${RouteConstant.KYC}/${params?.value}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          Details
        </Button>
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Box className="tw-flex tw-justify-center tw-items-center">
                <IconButton
                  size="small"
                  onClick={() =>
                    params?.row?.id &&
                    navigate(
                      `${RouteConstant.SIMSWAP}/${params?.row?.id}`
                    )
                  }
                  sx={{ color: palette.secondary.main }}
                >
                  <Tooltip title="View Details">
                    <VisibilityIcon color="inherit" fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            </>
          );
        },
      },
    ];
    return (
      <>
        <Box mt={2}>
          <CustomDataGrid
            loading={loading}
            columns={columns}
            rows={data || []}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      </>
    );
  };

export default SimSwapTable;
