import { WalletDetailsLayout } from "component/walletDetails"
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const WalletDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.WALLETTDETAILS} />;

    return (
        <MainContainer>
            <WalletDetailsLayout id={params.id} />
        </MainContainer>
    );
}