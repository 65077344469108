import DataGridTableInterface from "_interfaces/_common/dataGridTable";

export interface SimSwapFilterModelProps {
  status?: 'success' | 'rejected' | '';
  fromDate?: string | Date | null;  // YYYY-MM-DD format for date filters
  toDate?: string | Date | null;    // YYYY-MM-DD
}

const defaultRechargeFilterModelProps: SimSwapFilterModelProps = {
  status: '',                 // Default status value
  fromDate: null,
  toDate: null,
};

export class SimSwapFilterModel {
  status?: 'success' | 'rejected' | '';
  fromDate?: string | Date | null;
  toDate?: string | Date | null;

  constructor(data: SimSwapFilterModelProps = defaultRechargeFilterModelProps) {
    this.status = data.status || '';
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
