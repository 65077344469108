import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";
import { PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { BuildBundle, PaymentDetailsProps, RechargeDetailsTableInterface } from "_interfaces/recharge";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";

interface Props {
  data: RechargeDetailsTableInterface | null;
}

const RechargeDetailsTable: React.FC<Props> = ({ data }) => {
  const [openbuildBundle, setOpenBuildBundle] = useState<boolean>(false);
  const handleToggleBuildBundle = () => setOpenBuildBundle(!openbuildBundle);
  const navigate = useNavigate();

  const handleStatusColor = (value: string) => {
    if (value == 'success') {
      return 'green';
    }
    if (value == 'failed') {
      return 'red';
    }
    if (value == 'pending') {
      return 'orange';
    }
    if (value == 'inProgress') {
      return 'brown';
    }
  }
  const rows: {
    field: keyof NonNullable<
      RechargeDetailsTableInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "rechargeType", headerName: "Recharge Type", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box>, },
      { field: "rechargeBy", headerName: "Recharge By", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box>, },
      // Tourist
      {
        field: "status", headerName: "Status",
        renderCell: (v: string) => <Box className='tw-capitalize' style={{ color: handleStatusColor(v), fontWeight: "600" }} >{v}</Box>,
      },
      {
        field: "amount",
        headerName: "Amount"
      },
      {
        field: "cbs_transactionId",
        headerName: "CBS Transaction Id",
      },
      {
        field: "walletId",
        headerName: "Wallet Id",
      },
      {
        field: "walletId",
        headerName: "Wallet Id",
      },
      {
        field: "isRechargeDone",
        headerName: "Recharge Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v ? "Done" : "Not Done"}</Box>,
      },
      {
        field: "transactionId",
        headerName: "Transaction Id",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box>,
      },
      { field: "MSISDN", headerName: "MSISDN" },
      {
        field: "time",
        headerName: "Time",
        renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
      },
      {
        field: "paidTime",
        headerName: "Paid Time",
        renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
      },
      {
        field: "paymentId",
        headerName: "Payment",
        renderCell: (v: string) => <Button
          onClick={() => navigate(`${RouteConstant.PAYMENTS}/${v}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          View Payment Details
        </Button>,
      },
      {
        field: "buildBundle",
        headerName: "Build Bundle",
        renderCell(v) {
          return data?.buildBundle ? (
            <Button
              onClick={handleToggleBuildBundle}
              variant="text"
              size="small"
              sx={{ fontSize: "inherit" }}
            >
              View Build Bundle
            </Button>
          ) : (
            <Box>N/A</Box>
          );
        },
      },
    ];
  const buildBundleRows: {
    field: keyof NonNullable<BuildBundle>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      {
        field: "subscriptionCharge",
        headerName: "Subcription On Charge",
        renderCell: (params) => <Box>{params}</Box>
      },
      { field: "onNetVoice", headerName: "On Net Voice", renderCell: (params) => <Box>{params} Minutes</Box> },
      {
        field: "dataMb",
        headerName: "Data",
        renderCell: (params) => <Box>{params} MB</Box>
      },
      {
        field: "intervalId",
        headerName: "Interval ID",
      },
      {
        field: "smsOffNet",
        headerName: "SMS Off Net",
      },
      {
        field: "isApplied",
        headerName: "Applied Status",
        renderCell: (params) => <Box>{params ? "Applied" : "Not Applied"}</Box>
      },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
      <CustomDrawer
        title={`Build Bundle`}
        open={openbuildBundle}
        onClose={handleToggleBuildBundle}
      >
        <Grid container spacing={2}>
          <DetailsBox data={data?.buildBundle} rows={buildBundleRows} fullWidth />
        </Grid>
      </CustomDrawer>
    </>
  );
};

export default RechargeDetailsTable;
