import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import { BuildBundleInterface, PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { Box, Button, Card, Grid } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";

interface Props {
  data: PhoneBooking | null;
}

const PhoneBookingDetailsTable: React.FC<Props> = ({ data }) => {
  const [openbuildBundle, setOpenBuildBundle] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleToggleBuildBundle = () => setOpenBuildBundle(!openbuildBundle);

  const rows: {
    field: keyof NonNullable<PhoneBooking>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    {
      field: "MSISDN",
      headerName: "MSISDN",
      renderCell: (v: string) => (
        <Button
          onClick={() => navigate(`${RouteConstant.MSISDN_MANAGEMENT}/${data?.MSISDN_id}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          {data?.MSISDN}
        </Button>
      ),
    },
    { field: "phoneNumber", headerName: "Phone Number" },
    { field: "promotion", headerName: "Promotion",
      renderCell: (v: string) => <Box className="tw-capitalize">{v?"Applied":"Not Applied"}</Box>,
     },
    { field: "passportNumber", headerName: "Passport Number" },
    { field: "nationalIdIdNumber", headerName: "National Id" },
    { field: "refugeeDocumentNumber", headerName: "Refugee Document" },
    {
      field: "status",
      headerName: "Status",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    {
      field: "bookingBy",
      headerName: "Booking By",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    {
      field: "identityType",
      headerName: "Identity Type",
      renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
    },
    {
      field: "payment",
      headerName: "Payment",
      renderCell: (v: string) => (
        <Button
          onClick={() => navigate(`${RouteConstant.PAYMENTS}/${v}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          View Payment Details
        </Button>
      ),
    },
    {
      field: "selectedPackage",
      headerName: "Package",
      renderCell: (v: string) => (
        <Button
          onClick={() => navigate(`${RouteConstant.PACKAGE_BUNDLE}/${v}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          View Package Details
        </Button>
      ),
    },
    {
      field: "couponId",
      headerName: "Coupon",
      renderCell: (v: string) => (
        <Button
          onClick={() => navigate(`${RouteConstant.COUPON}/${v}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          View Coupon Details
        </Button>
      ),
    },
    {
      field: "promotion",
      headerName: "Promotion",
      renderCell: (v: string) => (
        <Button
          onClick={() => navigate(`${RouteConstant.PROMOTION}/${v}`)}
          variant="text"
          size="small"
          sx={{ fontSize: "inherit" }}
        >
          View Promotion Details
        </Button>
      ),
    },
    {
      field: "bookingTime",
      headerName: "Booking Time",
      renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
    },
    // Conditionally add the Build Bundle column with a type assertion
    ...(data?.buildBundle?.isApplied
      ? [
          {
            field: "buildBundle" as keyof PhoneBooking,
            headerName: "Build Bundle",
            renderCell: () => (
              <Button
                onClick={handleToggleBuildBundle}
                variant="text"
                size="small"
                sx={{ fontSize: "inherit" }}
              >
                View Build Bundle
              </Button>
            ),
          },
        ]
      : []),
  ];

  const buildBundleRows: {
    field: keyof NonNullable<BuildBundleInterface>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      {
        field: "subscriptionCharge",
        headerName: "Subcription On Charge",
        renderCell: (params) => <Box>{params}</Box>
      },
      { field: "onNetVoice", headerName: "On Net Voice", renderCell: (params) => <Box>{params} Minutes</Box> },
      { field: "dataUsageMb", headerName: "Data Usage", renderCell: (params) => <Box>{params} MB</Box> },
      {
        field: "dataMb",
        headerName: "Data",
        renderCell: (params) => <Box>{params} MB</Box>
      },
      {
        field: "intervalId",
        headerName: "Interval ID",
      },
      {
        field: "isApplied",
        headerName: "Applied Status",
        renderCell: (params) => <Box>{params ? "Applied" : "Not Applied"}</Box>
      },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
      <CustomDrawer
        title={`Build Bundle`}
        open={openbuildBundle}
        onClose={handleToggleBuildBundle}
      >
        <Grid container spacing={2}>
          <DetailsBox data={data?.buildBundle} rows={buildBundleRows} fullWidth />
        </Grid>
      </CustomDrawer>
    </>
  );
};

export default PhoneBookingDetailsTable;
