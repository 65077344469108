import ThirdPartyApiAlertDetailsLayout from "component/log_alerts/details";
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { Navigate, useParams } from "react-router-dom";

const ThirdPartyLogDetails = () => {
  const params = useParams();

  if (!params?.id) return <Navigate to={RouteConstant.LOG_AND_ALERTS} />;

  return (
    <MainContainer>
      <ThirdPartyApiAlertDetailsLayout id={params.id} />
    </MainContainer>
  );
};

export default ThirdPartyLogDetails;
