import CartonLayout from "component/carton";
import MainContainer from "parts/mainContainer"

const Carton = () => {

    return (
        <MainContainer>
            <CartonLayout />
        </MainContainer>
    )
}

export default Carton;