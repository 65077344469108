import { TeamDetailsLayout } from "component/team/details";
import { RouteConstant } from "navigation/constant";
import { useParams, Navigate } from "react-router-dom";

export const TeamDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.BOOKINGS} />;
    return (
        <>
            <TeamDetailsLayout id={params.id} />
        </>
    )
}