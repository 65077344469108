import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GridColDef } from "@mui/x-data-grid";
import { toTitleCase } from "functions/helper";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import {
  PromotionTextTypeProps,
  PromotionUiTextTypeProps,
} from "_interfaces/functions/http-requests/promotion";
import { PromotionModel } from "_models/data/promotion/data.promotion.model";

interface Props {
  state: PromotionModel;
  setState: React.Dispatch<React.SetStateAction<PromotionModel>>;
  handleToggleTextTypesForm: () => void;
}

const PromotionTextTypesTable: React.FC<Props> = ({
  state,
  setState,
  handleToggleTextTypesForm,
}) => {
  function isPromotionTextTypeProps(
    value: any
  ): value is PromotionTextTypeProps {
    return typeof value === "object" && "show" in value;
  }

  const columns: GridColDef[] = [
    {
      field: "textType",
      headerName: "Text Type",
      renderCell: (params) => {
        return <Box>{toTitleCase(params?.row?.[0])} </Box>;
      },
    },
    {
      field: "text",
      headerName: "Text",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Tooltip title={params?.row?.[1]?.text || ""}>
            <Box className="tw-truncate">{params?.row?.[1]?.text || ""}</Box>
          </Tooltip>
        );
      },
    },
    {
      field: "textSize",
      headerName: "Size",
      renderCell: (params) => {
        return <Box>{toTitleCase(params?.row?.[1]?.size)} </Box>;
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      renderCell: (params) => <IsActiveCell status={params?.row?.[1]?.show} />,
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Box className="tw-flex tw-justify-center tw-items-center">
              <IconButton
                size="small"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    UI_textType: params?.row?.[0] as PromotionUiTextTypeProps,
                    UI_text: params?.row?.[1]?.text,
                    UI_textSize: params?.row?.[1]?.size,
                    UI_textColor: params?.row?.[1]?.color,
                    UI_textPosition: params?.row?.[1]?.position,
                    UI_textLinePosition: params?.row?.[1]?.linePosition,
                    UI_textShow: params?.row?.[1]?.show,
                  }));
                  handleToggleTextTypesForm();
                }}
              >
                <Tooltip title="Edit">
                  <EditIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  setState((prev) => {
                    const updatedUI = { ...prev.UI };
                    const textType = params
                      ?.row?.[0] as PromotionUiTextTypeProps;
                    delete updatedUI[textType];

                    return {
                      ...prev,
                      UI: updatedUI,
                    };
                  });
                }}
              >
                <Tooltip title="Clear">
                  <CloseIcon color="inherit" fontSize="small" />
                </Tooltip>
              </IconButton>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box py={2}>
        <CustomDataGrid
          columns={columns}
          rows={
            state?.UI && Object.entries(state?.UI)?.length
              ? Object.entries(state?.UI)
                  ?.filter(([key, value]) => isPromotionTextTypeProps(value))
                  ?.map((item, i) => ({
                    ...item,
                    id: i + 1,
                  }))
              : []
          }
          paginationMode="client"
          hideFooter
          height={350}
        />
      </Box>
    </>
  );
};

export default PromotionTextTypesTable;
