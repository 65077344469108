import DataGridTableInterface from "_interfaces/_common/dataGridTable";

export interface AppliedCouponFilterModelProps {
    appliedOn: string;
    fromDate?: string | Date | null;  // YYYY-MM-DD format for date filters
    toDate?: string | Date | null;    // YYYY-MM-DD
}

const defaultRechargeFilterModelProps: AppliedCouponFilterModelProps = {
    appliedOn: '',                 // Default status value
    fromDate: null,
    toDate: null,
};

export class AppliedCouponFilterModel {
    appliedOn?: string;
    fromDate?: string | Date | null;
    toDate?: string | Date | null;

    constructor(data: AppliedCouponFilterModelProps = defaultRechargeFilterModelProps) {
        this.appliedOn = data.appliedOn || '';
        this.fromDate = data.fromDate || null;
        this.toDate = data.toDate || null;
    }
}
