import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import Text from "component/_common/forms/text";
import { generateAutocompleteOptions } from "functions/helper";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment } from "react";
import {
  ALLOWED_PROMOTION_TEXT_TYPE_POSITION,
  ALLOWED_PROMOTION_UI_TEXT_SIZE,
  ALLOWED_PROMOTION_UI_TEXT_TYPE,
  PromotionUiTextTypeProps,
} from "_interfaces/functions/http-requests/promotion";
import { FormInputProps } from "_interfaces/_common/forms";
import { PromotionModel } from "_models/data/promotion/data.promotion.model";
import { PromotionErrorModel } from "_models/error/promotion/error.promotion.model";
import { validatePromotionTextTypes } from "./formValidator";

interface InputListProps extends FormInputProps {
  name:
    | "UI_textType"
    | "UI_text"
    | "UI_textSize"
    | "UI_textColor"
    | "UI_textPosition"
    | "UI_textLinePosition"
    | "UI_textShow";
}

interface PromotionTextTypesFormInterface {
  onClose: () => void;
  state: PromotionModel;
  setState: React.Dispatch<React.SetStateAction<PromotionModel>>;
  errors: PromotionErrorModel;
  setErrors: React.Dispatch<React.SetStateAction<PromotionErrorModel>>;
}

const PromotionTextTypesForm: React.FC<PromotionTextTypesFormInterface> = ({
  onClose,
  state,
  setState,
  errors,
  setErrors,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validatePromotionTextTypes(state);

    if (ValidateStep?.valid) {
      const textType = state.UI_textType as PromotionUiTextTypeProps;
      setState((prev) => ({
        ...prev,
        UI: {
          ...(prev.UI || {}),
          [textType]: {
            ...(prev.UI?.[textType] || {}),
            text: state?.UI_text || prev.UI?.[textType]?.text || "",
            size: state?.UI_textSize || prev.UI?.[textType]?.size || "",
            color: state?.UI_textColor || prev.UI?.[textType]?.color || "",
            position:
              state?.UI_textPosition || prev.UI?.[textType]?.position || "",
            linePosition:
              state?.UI_textLinePosition ||
              prev.UI?.[textType]?.linePosition ||
              "",
            show:
              state?.UI_textShow ?? prev.UI?.[textType]?.linePosition ?? true,
          },
        },
        UI_textType: "",
        UI_text: "",
        UI_textSize: "",
        UI_textColor: "",
        UI_textPosition: "",
        UI_textLinePosition: "",
        UI_textShow: true,
      }));
      onClose();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      UI_textType: "",
      UI_text: "",
      UI_textSize: "",
      UI_textColor: "",
      UI_textPosition: "",
      UI_textLinePosition: "",
      UI_textShow: true,
    }));
    onClose();
  };

  const uiKeys = state?.UI ? Object.keys(state?.UI) : [];

  const inputList: InputListProps[] = [
    {
      type: "autoComplete",
      name: "UI_textType",
      label: "Text Type",
      placeholder: "Select text type",
      options: generateAutocompleteOptions(ALLOWED_PROMOTION_UI_TEXT_TYPE).map(
        (item) => {
          if (uiKeys.includes(item?.id)) {
            return {
              ...item,
              isActive: false,
            };
          }
          return item;
        }
      ),
      multiple: false,
      enabled: true,
      disabled: !!(state?.UI_textType && uiKeys.includes(state?.UI_textType)),
      grid: 6,
    },
    {
      type: "autoComplete",
      name: "UI_textSize",
      label: "Text Size",
      placeholder: "Select text size",
      options: generateAutocompleteOptions(ALLOWED_PROMOTION_UI_TEXT_SIZE),
      multiple: false,
      enabled: true,
      grid: 6,
    },
    {
      type: "text",
      name: "UI_text",
      label: "Text",
      placeholder: "Enter text",
      multiline: true,
      enabled: true,
      grid: 12,
    },
    {
      type: "text",
      name: "UI_textColor",
      label: "Text color",
      placeholder: "Enter text color",
      multiline: false,
      enabled: true,
      grid: 12,
    },
    {
      type: "autoComplete",
      name: "UI_textPosition",
      label: "Text Position",
      placeholder: "Select text position",
      options: generateAutocompleteOptions(
        ALLOWED_PROMOTION_TEXT_TYPE_POSITION
      ),
      multiple: false,
      enabled: true,
      grid: 6,
    },
    {
      type: "number",
      name: "UI_textLinePosition",
      label: "Line Position",
      placeholder: "Enter line position",
      enabled: true,
      grid: 6,
    },
    {
      type: "checkbox",
      name: "UI_textShow",
      label: "Show Text type",
      enabled: true,
      grid: 12,
    },
  ];

  return (
    <>
      <Box
        component="form"
        id="add_promotion_text_types_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                multiline,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "text" || type === "email" || type === "number" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof PromotionModel]}
                        errorText={errors[name as keyof PromotionErrorModel]}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                        InputProps={InputProps}
                        multiline={multiline}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options.length &&
                          state?.[name as keyof PromotionModel]
                        }
                        errorText={errors[name as keyof PromotionErrorModel]}
                        onFocus={handleFocus}
                        multiple={multiple}
                        disabled={disabled}
                      />
                    </Grid>
                  ) : type === "checkbox" ? (
                    <Grid item xs={12} md={grid || 12}>
                      <InputCheckbox
                        name={name}
                        label={label}
                        value={state?.[name as keyof PromotionModel] as boolean}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize"
            >
              Save Changes
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleClose}
              fullWidth
              variant="text"
              disableElevation
              color="inherit"
              className="tw-capitalize"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PromotionTextTypesForm;
