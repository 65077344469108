import moment from "moment";
import { PromotionModel } from "_models/data/promotion/data.promotion.model";

export const validatePromotion = (
  DATA: PromotionModel,
  isEditing?: boolean
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.promotionType?.trim()) {
    valid = false;
    errors.push({
      name: "promotionType",
      error: "Promotion type is required.",
    });
  }

  if (!DATA?.promotionConsumer?.length) {
    valid = false;
    errors.push({
      name: "promotionConsumer",
      error: "Promotion consumer is required.",
    });
  }

  if (!DATA?.amount?.trim()) {
    valid = false;
    errors.push({
      name: "amount",
      error: "Amount is required.",
    });
  }

  if (DATA?.amount?.trim() && Number(DATA.amount) < 0) {
    valid = false;
    errors.push({
      name: "amount",
      error: "Invalid Amount",
    });
  }

  if (!DATA?.promotionApplyOn?.trim()) {
    valid = false;
    errors.push({
      name: "promotionApplyOn",
      error: "Promotion apply on is required.",
    });
  }

  if (!DATA?.assignDate) {
    valid = false;
    errors.push({
      name: "assignDate",
      error: "Assign date is required.",
    });
  }

  if (!DATA?.expiryDate) {
    valid = false;
    errors.push({
      name: "expiryDate",
      error: "Expiry date is required.",
    });
  }

  const today = moment().startOf("day");

  if (!isEditing && DATA?.assignDate) {
    const assignDate = moment(DATA.assignDate).startOf("day");

    if (assignDate.isBefore(today)) {
      valid = false;
      errors.push({
        name: "assignDate",
        error: "Assign date cannot be in the past.",
      });
    }
  }

  if (DATA?.assignDate && DATA?.expiryDate) {
    const assignDate = moment(DATA.assignDate).startOf("day");
    const expiryDate = moment(DATA.expiryDate).startOf("day");

    if (assignDate.isSame(expiryDate)) {
      valid = false;
      errors.push({
        name: "expiryDate",
        error: "Assign date and expiry date cannot be the same.",
      });
    } else if (!assignDate.isBefore(expiryDate)) {
      valid = false;
      errors.push({
        name: "assignDate",
        error: "Assign date must be before expiry date.",
      });
    }
  }

  return { valid, errors };
};

export const validatePromotionTextTypes = (DATA: PromotionModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.UI_textType?.trim()) {
    valid = false;
    errors.push({
      name: "UI_textType",
      error: "Text type is required.",
    });
  }

  if (!DATA?.UI_text?.trim()) {
    valid = false;
    errors.push({
      name: "UI_text",
      error: "Text is required.",
    });
  }

  return { valid, errors };
};
