import { generateAutocompleteOptions } from "functions/helper";
import React, { useMemo } from "react";
import { FormInputProps } from "_interfaces/_common/forms";
import { SideDrawerProps } from "_interfaces/_common/sideDrawer";
import { CustomFilter } from "parts/customFilter";
import { WalletPaymentFilterModel } from "_models/data/walletPayment/data.wallet-payment-filter.model";

interface InputListProps extends FormInputProps {
  name: "status" | 'paymentType' | 'dateRange' | 'fromAmount' | 'toAmount'
}
interface Props extends SideDrawerProps {
  filters: WalletPaymentFilterModel;
  setFilters: React.Dispatch<
    React.SetStateAction<WalletPaymentFilterModel>
  >;
  setOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const WalletPaymentFilter: React.FC<Props> = ({
  open,
  filters,
  setFilters,
  setOpen
}) => {

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "autoComplete",
        name: "status",
        label: "Filter by Status",
        placeholder: "Select Status",
        options: generateAutocompleteOptions([
          'idle' , 'pending' , 'inprogress' , 'success' , 'rejected' 
        ]),
        multiple: false,
      },
      {
        type: "autoComplete",
        name: "paymentType",
        label: "Filter by Payment Type",
        placeholder: "Select Payment Type",
        options: generateAutocompleteOptions(['credit', 'debit']),
        multiple: false,
      },
      {
        type: "dateRange",
        name: "dateRange",
        label: "Select Date range",
        placeholder: "Select Date range",
      },
      {
        type: "number",
        name: "toAmount",
        label: "Select To Amount",
        placeholder: "Select To Amount",
      },
      {
        type: "number",
        name: "fromAmount",
        label: "Select From Amount",
        placeholder: "Select From Amount",
      },
    ],
    []
  );

  return (
    <>
      <CustomFilter
        filters={filters}
        setFilters={setFilters}
        filterList={inputList}
        open={open as boolean}
        setOpen={setOpen}
      />
    </>
  );
};

export default WalletPaymentFilter;
