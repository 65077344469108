import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetAllInfluencerProps } from "_interfaces/functions/http-requests/influencer";
import InfluencerTableInterface from "_interfaces/influencer/influencerTable";
import { InfluencerFilterModel } from "_models/data/influencer/data.influencer-filter.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetAllInfluencer } from "functions/http-requests/influencer";
import CustomDrawer from "parts/customDialog/customDrawer";
import FilterButton from "parts/filterButton";
import SectionSearch from "parts/sectionSearch";
import { useEffect, useRef, useState } from "react";
import InfluencerFilter from "./influencerFilter";
import InfluencerForm from "./influencerForm";
import InfluencerTable from "./influencerTable";

const InfluencerLayout = () => {
  const isInitialRender = useRef(true);

  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [data, setData] = useState<InfluencerTableInterface["data"]>([]);

  const [openForm, setOpenForm] = useState<boolean>(false); 

  const [refresh, setRefresh] = useState<boolean>(false);

  const [searchKey, setSearchKey] = useState<string>("");

  const [count, setCount] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [filters, setFilters] = useState<InfluencerFilterModel>(
    new InfluencerFilterModel()
  );

  const toggleForm = () => {
    setOpenForm(!openForm);
  };

  const toggleFilter = () => {
    setOpenFilter(!openFilter);
  };

  const handleRefresh = () => setRefresh(!refresh);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
  };

  const handleClearSearch = () => setSearchKey("");

  const handleSubmitSearch = () => {
    isInitialRender.current = false;
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    handleRefresh();
  };

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      setDataLoading(true);
      let PAYLOAD_DATA: GetAllInfluencerProps["DATA"] = {
        searchKey: searchKey,
        category: filters.category || undefined,
        pageNumber: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      };

      let FILTERED_PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      GetAllInfluencer({
        DATA: FILTERED_PAYLOAD_DATA,
      })
        .then((res) => {
          const data = res?.data;

          if (data?.statusCode === AppStatusCode.api_success) {
            let count = res?.data?.meta?.count;
            if (count && count > 1) {
              if (count > 1) setCount(count);
            } else setCount(0);

            let DATA: any = res?.data?.data;
            DATA = DATA?.map((item: any) => ({
              id: item?._id,
              fullName:
                item?.firstName || item?.middleName || item?.lastName
                  ? [item?.firstName, item?.middleName, item?.lastName]
                      .filter(Boolean)
                      .join(" ")
                      .trim()
                  : "",
              email: item?.email || "",
              phoneNumber: item?.phoneNumber || "",
              category: item?.category || "",
              isActive: item?.isActive || "",
            }));
            setData(DATA);
          } else {
            setData([]);
            setCount(0);
          }
        })
        .catch(() => {
          setData([]);
          setCount(0);
        })
        .finally(() => {
          setDataLoading(false);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [paginationModel, refresh, filters]);

  useEffect(() => {
    if (!isInitialRender.current) {
      if (!searchKey) handleRefresh();
    }
  }, [searchKey]);

  return (
    <>
      <Box pt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
            <Box sx={{ flex: "1 1 auto" }}>
              <SectionSearch
                name="search_influencer"
                value={searchKey}
                onChange={handleSearch}
                onClear={handleClearSearch}
                onSubmit={handleSubmitSearch}
              />
            </Box>
            <FilterButton onClick={() => setOpenFilter(true)} />
          </Grid>
          <Grid item xs={12} sm={6} className="tw-flex tw-justify-end">
            <Button
              variant="outlined"
              className="tw-mr-[12px]"
              onClick={toggleForm}
            >
              Add Influencer
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box pt={3}>
        <InfluencerTable
          data={data}
          loading={dataLoading}
          count={count}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
        />
      </Box>

      <InfluencerFilter
        open={openFilter}
        onClose={toggleFilter}
        filters={filters}
        setFilters={setFilters}
      />

      <CustomDrawer open={openForm} onClose={toggleForm} title="Add Influencer">
        <InfluencerForm handleRefresh={handleRefresh} onClose={toggleForm} />
      </CustomDrawer>
    </>
  );
};

export default InfluencerLayout;
