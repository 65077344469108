import Visibility from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import MsisdnManagementTableInterface from "_interfaces/msisdn-management/msisdnManagementTable";
import TaskTableInterface from "_interfaces/tasks";
import { camelCaseToFormattedString } from "functions/helper";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import CustomDataGrid from "parts/customDataGrid";
import { IsActiveCell } from "parts/table/customCell";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TaskTable: React.FC<TaskTableInterface> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
  setSelectedRows
}) => {

  const columns: GridColDef[] = [
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      maxWidth: 280,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.value}</Box>
      ),
    },
    {
      field: "assignedTeam",
      headerName: "Assigned Team",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => (
        <Box className="tw-capitalize">{!params?.value ? "-" : params?.value}</Box>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.formattedValue}</Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 180,
      renderCell: (params) => (
        <Box className="tw-capitalize">{params?.value}</Box>
      ),
    },
    {
      field: "assignDate",
      headerName: "Assigned Date",
      flex: 1,
      maxWidth: 170,
      renderCell: (params) => (
        <Box className="tw-capitalize">{!params?.value ? "-" : moment(params?.value).format("Do MMM, YYYY")}</Box>
      ),
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Update Date",
      minWidth: 150,
      renderCell: (params) => (
        <Box className="tw-capitalize">{!params?.value ? "-" : moment(params?.value).format("Do MMM, YYYY")}</Box>  
      ),
    },
  ];


  return (
    <>
      <CustomDataGrid
        loading={loading}
        columns={columns}
        rows={data || []}
        count={count}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        checkboxSelection={true}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default TaskTable;
