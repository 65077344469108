import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CartonListByDistributor } from "_interfaces/regional-distributors/details/regionalDistributorDetailsTable";
import moment from "moment";
import CustomDataGrid from "parts/customDataGrid";

interface Props {
    DATA: CartonListByDistributor[]
    loading: boolean,
    count?: number,
}

const CartonsTable: React.FC<Props> = ({ DATA, loading }) => {

    const columns: GridColDef[] = [
        {
            field: "firstICCID",
            headerName: "First ICCID",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "lastICCID",
            headerName: "Last ICCID",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "cartonNo",
            headerName: "Carton No.",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "batchNo",
            headerName: "Batch No.",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "SIM_type",
            headerName: "Sim Type",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "serviceType",
            headerName: "Service Type",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "date",
            headerName: "Created On",
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => moment(params.value).format("Do MMM, YYYY"),
            renderCell: (params) => (
                <Box className="tw-capitalize">{params?.formattedValue}</Box>
            ),
        },
    ];

    return (
        <>
            <CustomDataGrid
                loading={loading}
                columns={columns}
                rows={DATA || []}
                hideFooter={true}
            />
        </>
    )
}

export default CartonsTable;