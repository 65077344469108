import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import {
  filterNonNullValues,
  generateAutocompleteOptions,
} from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import {
  CreatePackageBundle,
  GetPackageBundleById,
  UpdatePackageBundle,
} from "functions/http-requests/package-bundle";
import {
  isValidString,
  renderInvalidStringErrorText,
} from "functions/validators";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import {
  ALLOWED_PACKAGE_BUNDLE_FIELDS_category,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_discountFor,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor,
  ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType,
  CreatePackageBundleProps,
  UpdatePackageBundleProps,
} from "_interfaces/functions/http-requests/package-bundle";
import { FormInputProps } from "_interfaces/_common/forms";
import { PackageBundleModel } from "_models/data/package-bundle/data.package-bundle.model";
import { packageBundleErrorModel } from "_models/error/package-bundle/error.package-bundle.model";
import { validatePackageBundle } from "./formValidator";

interface InputListProps extends FormInputProps {
  name:
    | "packageName"
    | "validityGroup"
    | "isValidityUnlimited"
    | "validity"
    | "internationalCallMinutes"
    | "packageFor"
    | "packageID"
    | "retailPrice"
    | "description"
    | "category"
    | "discountFor"
    | "discountPercent"
    | "packageType"
    | "vat"
    | "allNetMinutesGroup"
    | "allNetMinutes"
    | "allNetMinutes_isUnlimited"
    | "onNetMinutesGroup"
    | "onNetMinutes"
    | "onNetMinutes_isUnlimited"
    | "offNetMinutesGroup"
    | "offNetMinutes"
    | "offNetMinutes_isUnlimited"
    | "allNetSMSGroup"
    | "allNetSMS"
    | "allNetSMS_isUnlimited"
    | "onNetSMSGroup"
    | "onNetSMS"
    | "onNetSMS_isUnlimited"
    | "offNetSMSGroup"
    | "offNetSMS"
    | "offNetSMS_isUnlimited"
    | "dataVolume_isUnlimited"
    | "dataVolume_quantity"
    | "dataVolume_dataUnit"
    | "dataPerDay_isUnlimited"
    | "dataPerDay_quantity"
    | "dataPerDay_dataUnit"
    | "dataVolume"
    | "dataPerDay";
}

interface InputListNestedProps extends InputListProps {
  groupItems?: InputListProps[];
}

interface PackageBundleFormInterface {
  editId?: string | null;
  handleRefresh: () => void;
  onClose: () => void;
}

const PackageBundleForm: React.FC<PackageBundleFormInterface> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<PackageBundleModel>(
    new PackageBundleModel()
  );
  const [errors, setErrors] = useState<packageBundleErrorModel>(
    new packageBundleErrorModel()
  );

  const [editRefresh, setEditRefresh] = useState<boolean>(false);

  const handleEditRefresh = (REFRESH: boolean) => setEditRefresh(REFRESH);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "isValidityUnlimited" && errors?.validity) {
        setErrors((prev) => ({ ...prev, validity: "" }));
      }
      if (
        name === "dataVolume_isUnlimited" &&
        (errors?.dataVolume_dataUnit || errors?.dataVolume_quantity)
      ) {
        setErrors((prev) => ({
          ...prev,
          dataVolume_dataUnit: "",
          dataVolume_quantity: "",
        }));
      }
      if (
        name === "dataPerDay_isUnlimited" &&
        (errors?.dataPerDay_dataUnit || errors?.dataPerDay_quantity)
      ) {
        setErrors((prev) => ({
          ...prev,
          dataPerDay_dataUnit: "",
          dataPerDay_quantity: "",
        }));
      }
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      if (name === "name" && value) {
        if (!isValidString(value)) {
          return setErrors((prev) => ({
            ...prev,
            [name]: renderInvalidStringErrorText(),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "",
          }));
        }
      }
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: CreatePackageBundleProps) => {
    Dispatch(toggleLoading(true));
    CreatePackageBundle({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA }: UpdatePackageBundleProps) => {
    Dispatch(toggleLoading(true));
    UpdatePackageBundle({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validatePackageBundle(state);

    if (ValidateStep?.valid) {
      let DATA: CreatePackageBundleProps["DATA"] = {
        packageName: state?.packageName || undefined,
        isValidityUnlimited: state?.isValidityUnlimited || false,
        validity: state?.validity || undefined,
        dataVolume: {
          isUnlimited: state?.dataVolume_isUnlimited || false,
        },
        dataPerDay: {
          isUnlimited: state?.dataPerDay_isUnlimited || false,
        },
        allNetMinutes: state?.allNetMinutes || undefined,
        onNetMinutes: state?.onNetMinutes || undefined,
        offNetMinutes: state?.offNetMinutes || undefined,
        allNetSMS: state?.allNetSMS || undefined,
        onNetSMS: state?.onNetSMS || undefined,
        offNetSMS: state?.offNetSMS || undefined,
        internationalCallMinutes: state?.internationalCallMinutes || undefined,
        packageFor: state?.packageFor || undefined,
        packageID: state?.packageID || undefined,
        retailPrice: state?.retailPrice || undefined,
        description: state?.description || undefined,
        category: state?.category || undefined,
        discountFor: state?.discountFor || undefined,
        discountPercent: state?.discountPercent,
        packageType: state?.packageType || undefined,
        vat: state?.vat || undefined,
      };

      if (!state.dataVolume_isUnlimited) {
        DATA["dataVolume"] = {
          isUnlimited: state?.dataVolume_isUnlimited || false,
          quantity: state?.dataVolume_quantity || null,
          dataUnit: state?.dataVolume_dataUnit || null,
        };
      }

      if (!state.dataPerDay_isUnlimited) {
        DATA["dataPerDay"] = {
          isUnlimited: state?.dataPerDay_isUnlimited || false,
          quantity: state?.dataPerDay_quantity || null,
          dataUnit: state?.dataPerDay_dataUnit || null,
        };
      }

      if (state.allNetMinutes_isUnlimited) {
        DATA["allNetMinutes"] = "unlimited";
      }

      if (state.onNetMinutes_isUnlimited) {
        DATA["onNetMinutes"] = "unlimited";
      }

      if (state.offNetMinutes_isUnlimited) {
        DATA["offNetMinutes"] = "unlimited";
      }

      if (state.allNetSMS_isUnlimited) {
        DATA["allNetSMS"] = "unlimited";
      }

      if (state.onNetSMS_isUnlimited) {
        DATA["onNetSMS"] = "unlimited";
      }

      if (state.offNetSMS_isUnlimited) {
        DATA["offNetSMS"] = "unlimited";
      }

      DATA = filterNonNullValues(DATA);

      if (state.isValidityUnlimited) {
        DATA["validity"] = "";
      }

      if (editId) {
        const EDIT_DATA: UpdatePackageBundleProps["DATA"] = {
          ...DATA,
          id: state?.id,
          isActive: state?.isActive,
        };

        handleUpdate({ DATA: EDIT_DATA });
      } else handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetPackageBundleById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;
          if (editRefresh) {
            setState((prev) => ({
              ...prev,
              isActive: DATA.isActive,
            }));
            handleEditRefresh(false);
          } else {
            setState({
              id: DATA?._id || "",
              packageName: DATA?.packageName || "",

              isValidityUnlimited: DATA?.isValidityUnlimited,
              validity: DATA?.validity ? DATA?.validity?.toString() : "",

              dataVolume_isUnlimited: DATA?.dataVolume?.isUnlimited,
              dataVolume_quantity: DATA?.dataVolume?.quantity?.toString() || "",
              dataVolume_dataUnit: DATA?.dataVolume?.dataUnit || "",

              dataPerDay_isUnlimited: DATA?.dataPerDay?.isUnlimited,
              dataPerDay_quantity: DATA?.dataPerDay?.quantity?.toString() || "",
              dataPerDay_dataUnit: DATA?.dataPerDay?.dataUnit || "",

              allNetMinutes:
                DATA?.allNetMinutes && DATA?.allNetMinutes !== "unlimited"
                  ? DATA.allNetMinutes?.toString()
                  : "",
              allNetMinutes_isUnlimited:
                DATA?.allNetMinutes && DATA?.allNetMinutes === "unlimited"
                  ? true
                  : false,

              onNetMinutes:
                DATA?.onNetMinutes && DATA?.onNetMinutes !== "unlimited"
                  ? DATA.onNetMinutes?.toString()
                  : "",
              onNetMinutes_isUnlimited:
                DATA?.onNetMinutes && DATA?.onNetMinutes === "unlimited"
                  ? true
                  : false,

              offNetMinutes:
                DATA?.offNetMinutes && DATA?.offNetMinutes !== "unlimited"
                  ? DATA.offNetMinutes?.toString()
                  : "",
              offNetMinutes_isUnlimited:
                DATA?.offNetMinutes && DATA?.offNetMinutes === "unlimited"
                  ? true
                  : false,

              allNetSMS:
                DATA?.allNetSMS && DATA?.allNetSMS !== "unlimited"
                  ? DATA.allNetSMS?.toString()
                  : "",
              allNetSMS_isUnlimited:
                DATA?.allNetSMS && DATA?.allNetSMS === "unlimited"
                  ? true
                  : false,

              onNetSMS:
                DATA?.onNetSMS && DATA?.onNetSMS !== "unlimited"
                  ? DATA.onNetSMS?.toString()
                  : "",
              onNetSMS_isUnlimited:
                DATA?.onNetSMS && DATA?.onNetSMS === "unlimited" ? true : false,

              offNetSMS:
                DATA?.offNetSMS && DATA?.offNetSMS !== "unlimited"
                  ? DATA.offNetSMS?.toString()
                  : "",
              offNetSMS_isUnlimited:
                DATA?.offNetSMS && DATA?.offNetSMS === "unlimited"
                  ? true
                  : false,

              internationalCallMinutes: DATA?.internationalCallMinutes
                ? DATA?.internationalCallMinutes?.toString()
                : "",
              packageFor: DATA?.packageFor || "",
              packageID: DATA?.packageID || "",
              retailPrice: DATA?.retailPrice
                ? DATA?.retailPrice?.toString()
                : "",
              description: DATA?.description || "",
              category: DATA?.category || "",
              discountFor: DATA?.discountFor || "",
              discountPercent: DATA?.discountPercent
                ? DATA?.discountPercent?.toString()
                : "",
              packageType: DATA?.packageType || [],
              vat: DATA?.vat ? DATA?.vat?.toString() : "",
              isActive: DATA?.isActive || false,
            });
          }
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new PackageBundleModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  useEffect(() => {
    if (editId && editRefresh) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId, editRefresh]);

  const inputList: InputListNestedProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "packageName",
        label: "Package Name",
        placeholder: "Enter Package Name",
        grid: 6,
      },

      {
        type: "text",
        name: "packageID",
        label: "package ID",
        placeholder: "Enter package ID",
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "packageType",
        label: "Package Type",
        placeholder: "Select Package Type",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType
        ),
        multiple: true,
        grid: 12,
      },
      {
        type: "autoComplete",
        name: "packageFor",
        label: "Package For",
        placeholder: "Select Package For",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor
        ),
        multiple: false,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_category
        ),
        multiple: false,
        grid: 6,
      },
      {
        type: "number",
        name: "internationalCallMinutes",
        label: "International Call Minutes",
        placeholder: "Enter International Call Minutes",
        InputProps: { inputProps: { min: 0 } },
        grid: 6,
      },
      {
        name: "validityGroup",
        label: "Validity",
        groupItems: [
          {
            type: "checkbox",
            name: "isValidityUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "validity",
            label: "Validity",
            placeholder: "Enter validity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.isValidityUnlimited,
            enabled: !state.isValidityUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "allNetMinutesGroup",
        label: "All Net Minutes",
        groupItems: [
          {
            type: "checkbox",
            name: "allNetMinutes_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "allNetMinutes",
            label: "Quantity",
            placeholder: "Enter quantity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.allNetMinutes_isUnlimited,
            enabled: !state.allNetMinutes_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "onNetMinutesGroup",
        label: "On Net Minutes",
        groupItems: [
          {
            type: "checkbox",
            name: "onNetMinutes_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "onNetMinutes",
            label: "Quantity",
            placeholder: "Enter quantity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.onNetMinutes_isUnlimited,
            enabled: !state.onNetMinutes_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "offNetMinutesGroup",
        label: "Off Net Minutes",
        groupItems: [
          {
            type: "checkbox",
            name: "offNetMinutes_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "offNetMinutes",
            label: "Quantity",
            placeholder: "Enter quantity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.offNetMinutes_isUnlimited,
            enabled: !state.offNetMinutes_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "allNetSMSGroup",
        label: "All Net SMS",
        groupItems: [
          {
            type: "checkbox",
            name: "allNetSMS_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "allNetSMS",
            label: "Quantity",
            placeholder: "Enter quantity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.allNetSMS_isUnlimited,
            enabled: !state.allNetSMS_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "onNetSMSGroup",
        label: "On Net SMS",
        groupItems: [
          {
            type: "checkbox",
            name: "onNetSMS_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "onNetSMS",
            label: "Quantity",
            placeholder: "Enter quantity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.onNetSMS_isUnlimited,
            enabled: !state.onNetSMS_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "offNetSMSGroup",
        label: "Off Net SMS",
        groupItems: [
          {
            type: "checkbox",
            name: "offNetSMS_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "offNetSMS",
            label: "Quantity",
            placeholder: "Enter quantity",
            InputProps: { inputProps: { min: 0 } },
            disabled: state.offNetSMS_isUnlimited,
            enabled: !state.offNetSMS_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "dataVolume",
        label: "DATA Volume",
        groupItems: [
          {
            type: "checkbox",
            name: "dataVolume_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "dataVolume_quantity",
            label: "Quantity",
            placeholder: "Enter Quantity",
            InputProps: { inputProps: { min: 0 } },
            enabled: !state.dataVolume_isUnlimited,
            disabled: state.dataVolume_isUnlimited,
            grid: 6,
          },
          {
            type: "autoComplete",
            name: "dataVolume_dataUnit",
            label: "Data Unit",
            placeholder: "Select Data Unit",
            options: [
              { id: "MB", value: "MB", title: "MB", isActive: true },
              { id: "GB", value: "GB", title: "GB", isActive: true },
            ],
            multiple: false,
            enabled: !state.dataVolume_isUnlimited,
            disabled: state.dataVolume_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        name: "dataPerDay",
        label: "DATA Per Day",
        groupItems: [
          {
            type: "checkbox",
            name: "dataPerDay_isUnlimited",
            label: "Unlimited",
            enabled: true,
            grid: 6,
          },
          {
            type: "number",
            name: "dataPerDay_quantity",
            label: "Quantity",
            placeholder: "Enter Quantity",
            InputProps: { inputProps: { min: 0 } },
            enabled: !state.dataPerDay_isUnlimited,
            disabled: state.dataPerDay_isUnlimited,
            grid: 6,
          },
          {
            type: "autoComplete",
            name: "dataPerDay_dataUnit",
            label: "Data Unit",
            placeholder: "Select Data Unit",
            options: [
              { id: "MB", value: "MB", title: "MB", isActive: true },
              { id: "GB", value: "GB", title: "GB", isActive: true },
            ],
            multiple: false,
            enabled: !state.dataPerDay_isUnlimited,
            disabled: state.dataPerDay_isUnlimited,
            grid: 6,
          },
        ],
      },
      {
        type: "text",
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
        grid: 12,
      },
      {
        type: "number",
        name: "retailPrice",
        label: "Retail Price",
        placeholder: "Enter Retail Price",
        InputProps: { inputProps: { min: 0 } },
        grid: 6,
      },
      {
        type: "number",
        name: "vat",
        label: "VAT",
        placeholder: "Enter VAT",
        InputProps: { inputProps: { min: 0 } },
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "discountFor",
        label: "Discount For",
        placeholder: "Select Discount For",
        options: generateAutocompleteOptions(
          ALLOWED_PACKAGE_BUNDLE_FIELDS_discountFor
        ),
        multiple: false,
        grid: 6,
      },
      {
        type: "number",
        name: "discountPercent",
        label: "Discount percent",
        placeholder: "Enter Discount percent",
        InputProps: { inputProps: { min: 0 } },
        grid: 6,
      },
    ],
    [
      ALLOWED_PACKAGE_BUNDLE_FIELDS_packageFor,
      ALLOWED_PACKAGE_BUNDLE_FIELDS_category,
      ALLOWED_PACKAGE_BUNDLE_FIELDS_discountFor,
      ALLOWED_PACKAGE_BUNDLE_FIELDS_packageType,
      state,
    ]
  );

  return (
    <>
      <Box
        component="form"
        id="crete_edit_package_bundle_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                groupItems,
              },
              index
            ) => (
              <Fragment key={index}>
                <>
                  {type === "text" || type === "email" || type === "number" ? (
                    <Grid item xs={12} sm={6} md={grid || 12}>
                      <Text
                        type={type}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        autoComplete={autoComplete || "off"}
                        value={state[name as keyof PackageBundleModel]}
                        errorText={
                          errors[name as keyof packageBundleErrorModel]
                        }
                        onChange={handleChange}
                        onFocus={handleFocus}
                        disabled={disabled}
                        InputProps={InputProps}
                      />
                    </Grid>
                  ) : type === "autoComplete" ? (
                    <Grid item xs={12} sm={6} md={grid || 12}>
                      <InputAutoComplete
                        name={name}
                        options={options || []}
                        label={label}
                        placeholder={placeholder}
                        onChange={(e, v, m) =>
                          handleAutoComplete(e, v, name, m)
                        }
                        value={
                          options &&
                          options.length &&
                          state?.[name as keyof PackageBundleModel]
                        }
                        errorText={
                          errors[name as keyof packageBundleErrorModel]
                        }
                        onFocus={handleFocus}
                        multiple={multiple}
                      />
                    </Grid>
                  ) : type === "checkbox" ? (
                    <Grid item xs={12} sm={6} md={grid || 12}>
                      <InputCheckbox
                        name={name}
                        label={label}
                        value={
                          state?.[name as keyof PackageBundleModel] as boolean
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : name === "dataVolume" ||
                    name === "dataPerDay" ||
                    name === "validityGroup" ||
                    name === "allNetMinutesGroup" ||
                    name === "onNetMinutesGroup" ||
                    name === "offNetMinutesGroup" ||
                    name === "allNetSMSGroup" ||
                    name === "onNetSMSGroup" ||
                    name === "offNetSMSGroup" ? (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                          p: 2,
                          background: "rgba(0, 0, 0, 0.02)",
                        }}
                      >
                        <Box>
                          <Grid
                            container
                            rowSpacing={{ xs: 2, md: 0 }}
                            columnSpacing={2}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Box color="primary.main">{label}</Box>
                            </Grid>
                            {groupItems?.map((groupItem, groupIndex) => (
                              <Fragment key={groupIndex}>
                                {groupItem?.enabled ? (
                                  <>
                                    {groupItem?.type === "text" ||
                                    groupItem?.type === "email" ||
                                    groupItem?.type === "number" ? (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={groupItem?.grid || 12}
                                      >
                                        <Text
                                          type={groupItem?.type}
                                          name={groupItem?.name}
                                          label={groupItem?.label}
                                          placeholder={groupItem?.placeholder}
                                          autoComplete={
                                            groupItem?.autoComplete || "off"
                                          }
                                          value={
                                            state[
                                              groupItem?.name as keyof PackageBundleModel
                                            ]
                                          }
                                          errorText={
                                            errors[
                                              groupItem?.name as keyof packageBundleErrorModel
                                            ]
                                          }
                                          onChange={handleChange}
                                          onFocus={handleFocus}
                                          disabled={groupItem?.disabled}
                                          InputProps={groupItem?.InputProps}
                                        />
                                      </Grid>
                                    ) : groupItem?.type === "autoComplete" ? (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={groupItem?.grid || 12}
                                      >
                                        <InputAutoComplete
                                          name={groupItem?.name}
                                          options={groupItem?.options || []}
                                          label={groupItem?.label}
                                          placeholder={groupItem?.placeholder}
                                          onChange={(e, v, m) =>
                                            handleAutoComplete(
                                              e,
                                              v,
                                              groupItem?.name,
                                              m
                                            )
                                          }
                                          value={
                                            groupItem?.options &&
                                            groupItem?.options.length &&
                                            state?.[
                                              groupItem?.name as keyof PackageBundleModel
                                            ]
                                          }
                                          errorText={
                                            errors[
                                              groupItem?.name as keyof packageBundleErrorModel
                                            ]
                                          }
                                          onFocus={handleFocus}
                                          multiple={groupItem?.multiple}
                                          disabled={groupItem?.disabled}
                                        />
                                      </Grid>
                                    ) : groupItem?.type === "checkbox" ? (
                                      <Grid
                                        item
                                        xs={12}
                                        md={groupItem?.grid || 12}
                                        pt={0}
                                      >
                                        <InputCheckbox
                                          name={groupItem?.name}
                                          label={groupItem?.label}
                                          value={
                                            state?.[
                                              groupItem?.name as keyof PackageBundleModel
                                            ] as boolean
                                          }
                                          onChange={handleChange}
                                        />
                                      </Grid>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Fragment>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New Package"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PackageBundleForm;
