import { HTTP } from "functions/http";

import {
  CouponCreateProps,
  CouponUpdateProps,
  GetAllCouponProps,
} from "_interfaces/functions/http-requests/coupon";
import {
  CREATE_COUPON,
  GET_ALL_COUPON,
  GET_APPLIED_COUPON_LIST_BY_COUPONID,
  GET_COUPON_BY_ID,
  UPDATE_COUPON,
} from "config/endpoint";
import { AppliedCouponListProps } from "_interfaces/functions/http-requests/influencer";

export const CreateCoupon = ({ DATA }: CouponCreateProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_COUPON,
    Data: DATA,
  });
};

export const UpdateCoupon = ({ DATA, PARAMS }: CouponUpdateProps) => {
  return HTTP({
    Method: "POST",
    Url: UPDATE_COUPON(PARAMS.id),
    Data: DATA,
  });
};

export const GetAllCoupon = ({ DATA }: GetAllCouponProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_ALL_COUPON,
    Data: DATA,
  });
};

export const GetCouponById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_COUPON_BY_ID(ID),
  });
};

export const GetAppliedCouponListByCouponId = (ID: string, DATA: AppliedCouponListProps["DATA"]) => {
  return HTTP({
    Method: "POST",
    Url: GET_APPLIED_COUPON_LIST_BY_COUPONID(ID),
    Data: DATA
  });
};

