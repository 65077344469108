import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import CustomerManagementDetailsInterface from "_interfaces/customer-management/details";
import { BuildBundleInterface, PhoneBooking } from "_interfaces/functions/http-requests/bookings";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import { AirtelPayload, MomoPayload, PaymentDetailsInterface } from "_interfaces/payment";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";

interface Props {
  data: PaymentDetailsInterface | null;
}

const PaymentDetailsTable: React.FC<Props> = ({ data }) => {
  const [openApiResponses, setOpenApiResponses] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleToggleApiResponses = () => setOpenApiResponses(!openApiResponses);

  const rows: {
    field: keyof NonNullable<
      PaymentDetailsInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "paymentType", headerName: "Payment Type", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "MSISDN", headerName: "MSISDN", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "paymentMethod", headerName: "Payment Method", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "paymentBy", headerName: "Payment By", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "totalAmount", headerName: "Total Amount" },
      { field: "paymentID", headerName: "Payment ID" },
      { field: "failedReason", headerName: "Failed Reason" },
      {
        field: "paymentFor",
        headerName: "Payment For",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box>
      },
      {
        field: "status",
        headerName: "Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box>,
      },
      {
        field: "transactionId",
        headerName: "Transaction",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.TRANSACTIONS}/${data?.transactionId}`)} > View Transaction Details</Button >
      },
      {
        field: "wallet",
        headerName: "Wallet",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.WALLETTDETAILS}/${data?.wallet}`)} > View Wallet Details</Button >
      },
      {
        field: "walletPaymentId",
        headerName: "Wallet Payment",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.WALLETPAYMENTS}/${data?.walletPaymentId}`)} > View Wallet Payment Details</Button >
      },
      {
        field: "paidTime",
        headerName: "Paid Time",
        renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
      },
      {
        field: "momoPayload",
        headerName: "Momo Payload Amount",
        renderCell: (v: MomoPayload) => v.amount,
      },
      {
        field: "momoPayload",
        headerName: "Momo Payload External ID",
        renderCell: (v: MomoPayload) => v.externalId,
      },
      {
        field: "momoPayload",
        headerName: "Momo Payload Financial Transaction ID",
        renderCell: (v: MomoPayload) => v.financialTransactionId,
      },
      {
        field: "momoPayload",
        headerName: "Momo Payload",
        renderCell(v) {
          return data?.momoPayload ? (
            <Button
              onClick={handleToggleApiResponses}
              variant="text"
              size="small"
              sx={{ fontSize: "inherit" }}
            >
              View Momo Payload
            </Button>
          ) : (
            <Box>N/A</Box>
          );
        },
      },
      {
        field: "airtelPayload",
        headerName: "Airtel Payload",
        renderCell(v) {
          return data?.airtelPayload ? (
            <Button
              onClick={handleToggleApiResponses}
              variant="text"
              size="small"
              sx={{ fontSize: "inherit" }}
            >
              View Airtel Payload
            </Button>
          ) : (
            <Box>N/A</Box>
          );
        },
      },
    ];

  const momoPayloadeRows: {
    field: keyof NonNullable<MomoPayload>;
    headerName: string;
    renderCell?: (v: MomoPayload) => void;
  }[] = [
      {
        field: "amount",
        headerName: "Amount",
      },
      { field: "externalId", headerName: "External ID" },
      { field: "partyId", headerName: "Party ID" },
      {
        field: "payerMessage",
        headerName: "Payer Message",
      },
      {
        field: "partyId",
        headerName: "Party ID",
      },
      {
        field: "payeeNote",
        headerName: "Payee Note",
      },
      { field: "referenceId", headerName: "Reference ID" },
      { field: "financialTransactionId", headerName: "Financial Transaction ID" },
    ];
  const airtelPayloadeRows: {
    field: keyof NonNullable<AirtelPayload>;
    headerName: string;
    renderCell?: (v: AirtelPayload) => void;
  }[] = [
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "amount",
        headerName: "Amount",
      },
      { field: "reference", headerName: "Reference" },
      { field: "msisdn", headerName: "MSISDN" },
      { field: "airtel_money_id", headerName: "Airtel Payment Money ID" },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
      <CustomDrawer
        title={data?.momoPayload ? `Momo Payloads` : `Airtel Payloads`}
        open={openApiResponses}
        onClose={handleToggleApiResponses}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data?.momoPayload && <DetailsBox data={data?.momoPayload} rows={momoPayloadeRows} fullWidth />}
            {data?.airtelPayload && <DetailsBox data={data?.airtelPayload} rows={airtelPayloadeRows} fullWidth />}
          </Grid>
        </Grid>
      </CustomDrawer>
    </>
  );
};

export default PaymentDetailsTable;
