import { GridPaginationModel } from "@mui/x-data-grid";
import { SimSwapFilterProps } from "_interfaces/functions/http-requests/sim-swap";
import PaymentTableInterface from "_models/data/payment/data.payment.model";
import { SimSwapFilterModel } from "_models/data/sim-swap/data.sim-swap-filter.model";
import SimSwapTableInterface from "_models/data/sim-swap/data.sim-swap.model";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { GetSimSwapList } from "functions/http-requests/sim-swap";
import { useState, useEffect, useRef } from "react";
import SimSwapTable from "./simSwapTable";
import SimSwapFilter from "./simSwapFilter";
import { Grid, Box } from "@mui/material";
import SectionSearch from "parts/sectionSearch";

export const SimSwapLayout = () => {
    const isInitialRender = useRef(true);
    const [searchKey, setSearchKey] = useState<string>("");
    const [refresh, setRefresh] = useState<boolean>(false);
    const handleRefresh = () => setRefresh(!refresh);
    const handleClearSearch = () => setSearchKey("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [data, setData] = useState<SimSwapTableInterface["data"]>(
        []
    );
    const [count, setCount] = useState<number>(0);
    const [filters, setFilters] = useState<SimSwapFilterModel>(
        new SimSwapFilterModel()
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchKey(value);
    };

    const handleSubmitSearch = () => {
        isInitialRender.current = false;
        setPaginationModel((prev) => ({ ...prev, page: 0 }));
        handleRefresh();
    };

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    };

    useEffect(() => {
        let fetchList: (() => void) | null = () => {
            setDataLoading(true);

            let PAYLOAD_DATA: SimSwapFilterProps["DATA"] = {
                searchKey: searchKey,
                pageNumber: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                status: filters.status || "",
                fromDate: filters.fromDate,
                toDate: filters.toDate,
            };

            PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

            GetSimSwapList({ DATA: PAYLOAD_DATA })
                .then((res) => {
                    const data = res?.data;

                    if (data?.statusCode === AppStatusCode.api_success) {
                        let count = res?.data?.meta?.count[0]?.count;;
                        if (count && count > 1) {
                            if (count > 1) setCount(count);
                        } else setCount(0);

                        let DATA: any = res?.data?.data;
                        DATA = DATA?.map((item: any) => ({
                            id: item?._id,
                            oldICCID: item?.oldICCID,
                            MSISDN: item?.MSISDN,
                            ICCID: item?.ICCID,
                            swapKyc: item?.swapKyc,
                        }));
                        setData(DATA);
                    } else {
                        setData([]);
                        setCount(0);
                    }
                })
                .catch(() => {
                    setData([]);
                    setCount(0);
                })
                .finally(() => {
                    setDataLoading(false);
                });
        };
        fetchList();
        return () => {
            fetchList = null;
        };
    }, [refresh, paginationModel, filters, searchKey]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} className="tw-flex tw-items-center">
                    <Box sx={{ flex: "1 1 auto" }}>
                        <SectionSearch
                            name="search_regional_distributor"
                            value={searchKey}
                            onChange={handleSearch}
                            onClear={handleClearSearch}
                            onSubmit={handleSubmitSearch}
                        />
                    </Box>
                    <SimSwapFilter
                        open={openFilter}
                        onClose={toggleFilter}
                        filters={filters}
                        setFilters={setFilters}
                        setOpen={setOpenFilter}
                    />
                </Grid>
            </Grid>
            <SimSwapTable
                data={data}
                loading={dataLoading}
                count={count}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
            />
        </>
    )
}