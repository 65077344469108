import { TransactionFilterProps } from "_interfaces/functions/http-requests/transactions";
import { TRANSACTION_DETAILS, TRANSACTION_LIST } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetTransactionList = ({ DATA }: TransactionFilterProps) => {
    return HTTP({
        Method: "POST",
        Url: TRANSACTION_LIST,
        Data: DATA
    });
};
export const GetTransactionDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: TRANSACTION_DETAILS(ID),
    });
};