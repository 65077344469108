import { Box, Button, Card, Grid, Typography } from "@mui/material";
import CustomDrawer from "parts/customDialog/customDrawer";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import { GetAppVersion } from "functions/http-requests/apps";
import { AppStatusCode } from "config/appStatusCode";
import { LatestAppVersionData } from "_interfaces/apps";
import moment from "moment";
import CurrentVersionForm from "./appCurrentVersionForm";
import CurrentVersionDetails from "./currentVersionDetails";

const CurrentVersionLayout = () => {
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const Dispatch = useDispatch();
    const [appVersionKYCData, setAppVersionKYCData] = useState<LatestAppVersionData | null>(null);
    const [appVersionYOData, setAppVersionYOData] = useState<LatestAppVersionData | null>(null);

    const toggleForm = () => {
        setOpenForm(!openForm);
    };

    const handleRefresh = () => setRefresh(!refresh);

    const fetchAppversionKYC = () => {
        Dispatch(toggleLoading(true));
        GetAppVersion("KYC")
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    setAppVersionKYCData(DATA);
                }
            })
            .catch((error) => {
                setAppVersionKYCData(null)
            })
            .finally(() => Dispatch(toggleLoading(false)));
    }
    const fetchAppversionYO = () => {
        Dispatch(toggleLoading(true));
        GetAppVersion("YO")
            .then((res) => {
                const data = res?.data;
                if (data?.statusCode === AppStatusCode.api_success) {
                    let DATA: any = data?.data;
                    setAppVersionYOData(DATA);
                }
            })
            .catch((error) => {
                setAppVersionYOData(null)
            })
            .finally(() => Dispatch(toggleLoading(false)));
    }
    const fetchAllData = () => {
        fetchAppversionKYC();
        fetchAppversionYO();
    }
    useEffect(() => {
        fetchAppversionKYC();
        fetchAppversionYO();
    }, [])

    return (
        <>
            <Box className="tw-flex tw-justify-end tw-items-center">
                <Button
                    onClick={toggleForm}
                    variant="outlined"
                    startIcon={<AddIcon />}
                    disableElevation
                >
                    <Box component="span" className="tw-line-clamp-1">
                        Add New Version
                    </Box>
                </Button>
            </Box>
            <Box className="grid">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CurrentVersionDetails data={appVersionKYCData} heading={"KYC Version"} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CurrentVersionDetails data={appVersionYOData} heading={"YO Version"} />
                    </Grid>
                </Grid>
            </Box>
            <CustomDrawer
                open={openForm ? true : false}
                onClose={toggleForm}
                title={"Add New Version"}
            >
                <CurrentVersionForm handleRefresh={handleRefresh} onClose={toggleForm} fetchAllData={fetchAllData} />
            </CustomDrawer>
        </>
    )
}

export default CurrentVersionLayout;