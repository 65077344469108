import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { GridColDef } from "@mui/x-data-grid";
import CustomDataGrid from "parts/customDataGrid";
import React, { } from "react";
import moment from "moment";
import { RouteConstant } from "navigation/constant";
import { useNavigate } from "react-router-dom";
import TransactionTableInterface from "_models/data/transactions/data.transaction.model";
import { Button } from "@mui/material";

const WalletPaymentTable: React.FC<
  TransactionTableInterface
> = ({
  loading,
  data,
  count,
  paginationModel,
  setPaginationModel,
}) => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const columns: GridColDef[] = [
      {
        field: "walletID",
        headerName: "Wallet ID",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
      },
      { field: "amount", headerName: "Amount", flex: 1, minWidth: 100 },
      {
        field: "paymentId", headerName: "Payment", flex: 1, minWidth: 200,
        renderCell: (params) => {
          return params?.value ?
            (
              <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => navigate(`${RouteConstant.PAYMENTS}/${params?.value}`)}>See Payment Details</Button>
            ) :
            (
              <></>
            );
        }
      },
      {
        field: "status", headerName: "Status", flex: 1, minWidth: 100,
        renderCell: (params) => <Box className="tw-capitalize">{params.value}</Box>
      },
      {
        field: "paidTime",
        headerName: "Paid Time",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => moment(params.value).format("MMM D YYYY h:mm A"),
      },
      {
        field: "action",
        headerName: "Action",
        minWidth: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return (
            <>
              <Box className="tw-flex tw-justify-center tw-items-center">
                <IconButton
                  size="small"
                  onClick={() =>
                    params?.row?.id &&
                    navigate(
                      `${RouteConstant.WALLETPAYMENTS}/${params?.row?.id}`
                    )
                  }
                  sx={{ color: palette.secondary.main }}
                >
                  <Tooltip title="View Details">
                    <VisibilityIcon color="inherit" fontSize="small" />
                  </Tooltip>
                </IconButton>
              </Box>
            </>
          );
        },
      },
    ];
    return (
      <>
        <Box mt={2}>
          <CustomDataGrid
            loading={loading}
            columns={columns}
            rows={data || []}
            count={count}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Box>
      </>
    );
  };

export default WalletPaymentTable;
