export type InfluencerCategoryProps =
  | "all"
  | "tourist_SIM_seller"
  | "citizen_SIM_seller";

export const ALLOWED_INFLUENCER_CATEGORY = [
  "all",
  "tourist_SIM_seller",
  "citizen_SIM_seller",
];

export type InfluencerCreateProps = {
  DATA: {
    email?: string; //*
    phoneNumber?: string; //*
    category?: InfluencerCategoryProps; //*
    couponId?: string; //*
    firstName?: string; //*
    middleName?: string;
    lastName?: string; // *
    region?: string;
    district?: string;
    county?: string;
    subCounty?: string;
    parish?: string;
  };
};

export type InfluencerUpdateProps = {
  DATA: Omit<InfluencerCreateProps["DATA"], "couponId">;
  PARAMS: {
    id: string;
  };
};

export type GetAllInfluencerProps = {
  DATA: {
    searchKey?: string;
    category?: InfluencerCategoryProps;
    pageSize: number;
    pageNumber: number;
  };
};

export type InfluencerGenerateCouponLinkProps = {
  DATA: {
    expiryDate?: Date;
  };
  PARAMS: {
    id: string;
  };
};

export interface AppliedCouponListProps {
  DATA: {
    appliedOn: string;
    fromDate?: string | Date | null;  // YYYY-MM-DD format for date filters
    toDate?: string | Date | null;
    pageNumber: number,
    pageSize: number
  }
}
