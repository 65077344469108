import { SimSwapDetailsInterface } from "_interfaces/sim-swap";
import { AppStatusCode } from "config/appStatusCode";
import { GetSimSwapDetails } from "functions/http-requests/sim-swap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import SimSwapDetailsTable from "./detailsTable";

interface Props {
    id: string
}

export const SimSwapDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<SimSwapDetailsInterface | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetSimSwapDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        id: DATA?._id,
                        MSISDN: DATA?.MSISDN,
                        status: DATA?.status,
                        ICCID: DATA?.ICCID,
                        oldICCID: DATA?.oldICCID,
                        swapKyc: DATA?.swapKyc,
                        isNiraVerified: DATA?.isNiraVerified,
                        isUCCVerified: DATA?.isUCCVerified,
                        SIM: DATA?.SIM,
                        customer: DATA?.customer
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])
    return (
        <>
            <SimSwapDetailsTable data={details} />
        </>
    )
}