import { PaymentFilterProps } from "_interfaces/functions/http-requests/payment";
import { PAYMENT_DETAILS, PAYMENT_LIST } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetPaymentList = ({ DATA }: PaymentFilterProps,) => {
    return HTTP({
        Method: "POST",
        Url: PAYMENT_LIST,
        Data: DATA
    });
};
export const GetPaymentDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: PAYMENT_DETAILS(ID),
    });
};