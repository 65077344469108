import {
  CouponApplyOnProps,
  CouponCategoryProps,
  CouponDiscountTypeProps,
  CouponStatusProps,
} from "_interfaces/functions/http-requests/coupon";

interface CouponModelProps {
  category?: CouponCategoryProps | "";
  applyOn?: CouponApplyOnProps | "";
  couponCode?: string;
  validFrom?: Date | null;
  validUntil?: Date | null;
  maxUse?: string;
  discountType?: CouponDiscountTypeProps | "";
  discountValue?: string;
  minPurchaseAmount?: string;
  maxDiscountAmount?: string;
  isOpen?: boolean;
  info?: string;
  title?: string;
  status?: string;
}

export class CouponModel {
  id?:string;
  category?: CouponCategoryProps | "";
  applyOn?: CouponApplyOnProps | "";
  couponCode?: string;
  validFrom?: Date | null;
  validUntil?: Date | null;
  maxUse?: string;
  discountType?: CouponDiscountTypeProps | "";
  discountValue?: string;
  minPurchaseAmount?: string;
  maxDiscountAmount?: string;
  isOpen?: boolean;
  info?: string;
  title?: string;
  status?: string;

  constructor(data: CouponModelProps = {}) {
    this.category = data.category || "";
    this.applyOn = data.applyOn || "";
    this.couponCode = data.couponCode || "";
    this.validFrom = data.validFrom || null;
    this.validUntil = data.validUntil || null;
    this.maxUse = data.maxUse || "";
    this.discountType = data.discountType || "";
    this.discountValue = data.discountValue || "";
    this.minPurchaseAmount = data.minPurchaseAmount || "";
    this.maxDiscountAmount = data.maxDiscountAmount || "";
    this.isOpen = data.isOpen || false;
    this.info = data.info || "";
    this.title = data.title || "";
    this.status = data.status || "";
  }
}

interface CouponFilterProps {
  status?: CouponStatusProps;
  category?: CouponCategoryProps;
  applyOn?: CouponApplyOnProps;
  discountType?: CouponDiscountTypeProps;
  maxUse?: string;
}
export class CouponFilterModel {
  status?: CouponStatusProps | "";
  category?: CouponCategoryProps | "";
  applyOn?: CouponApplyOnProps | "";
  discountType?: CouponDiscountTypeProps | "";
  maxUse?: string;

  constructor(data: CouponFilterProps = {}) {
    this.status = data.status || "";
    this.category = data.category || "";
    this.applyOn = data.applyOn || "";
    this.discountType = data.discountType || "";
    this.maxUse = data.maxUse || "";
  }
}
