import { WalletTransactionDetailsLayout } from "component/walletTransaction/details"
import { RouteConstant } from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useParams, Navigate } from "react-router-dom";

export const WalletTransactionDetails = () => {
    const params = useParams();

    if (!params?.id) return <Navigate to={RouteConstant.WALLETTRANSACTION} />;

    return (
        <MainContainer>
            <WalletTransactionDetailsLayout id={params.id} />
        </MainContainer>
    );
}