import { ApproveDeRegProps, DeRegistrationFilterProps } from "_interfaces/functions/http-requests/de-registration";
import { APPROVE_DE_REG, DE_REGISTRATION_DETAILS, DE_REGISTRATION_LIST, GET_DE_REG_DOC } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetDeRegistrationList = ({ DATA }: DeRegistrationFilterProps,) => {
    return HTTP({
        Method: "POST",
        Url: DE_REGISTRATION_LIST,
        Data: DATA
    });
};
export const GetDeRegistrationDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: DE_REGISTRATION_DETAILS(ID),
    });
};
export const GetDeRegistrationDoc = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: GET_DE_REG_DOC(ID),
    });
};
export const ApproveDEReg = ({ DATA }: ApproveDeRegProps) => {
    return HTTP({
        Method: "POST",
        Url: APPROVE_DE_REG,
        Data: DATA
    });
};