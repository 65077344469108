import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Box, Card } from "@mui/material";
import { IsActiveCell } from "parts/table/customCell";
import { CouponModel } from "_models/data/coupon/data.coupon.model";

interface Props {
  data: CouponModel | null;
}

const CouponDetailsTable: React.FC<Props> = ({ data }) => {

  const rows: {
    field: keyof NonNullable<CouponModel>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "applyOn", headerName: "Apply On" },
      {
        field: "title", headerName: "Title",
        renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
      },
      {
        field: "couponCode", headerName: "Coupone Code",
      },
      {
        field: "discountType", headerName: "Discount Type",
      },
      { field: "discountValue", headerName: "Discount Value" },
      {
        field: "info",
        headerName: "Information",
      },
      {
        field: "category",
        headerName: "Category",
        renderCell: (v: string) => <Box className="tw-capitalize">{v}</Box>,
      },
      { field: "maxDiscountAmount", headerName: "Max Discount Amount", },
      { field: "minPurchaseAmount", headerName: "Max Purchase Amount", },
      {
        field: "maxUse", headerName: "Max Use",
        renderCell: (v: string) => <Box className="tw-capitalize">{v} Times</Box>,
      },
      {
        field: "isOpen", headerName: "Open Status",
        renderCell: (params) => <Box>{params?.row?.isOpen ? "Open" : "Close"}</Box>
      },
      {
        field: "validFrom", headerName: "Validity",
        renderCell: (params) => (
          <Box>{moment(params?.row?.validForm).format("Do MMM, YY HH:MM")} to {moment(params?.row?.validUntil).format("Do MMM, YY HH:MM")}</Box>
        ),
      },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default CouponDetailsTable;
