import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React from "react";
import { Box, Button, Card } from "@mui/material";
import { MomoResponse, TransactionDetailsInterface } from "_interfaces/transactions";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import { WalletPaymentDetailsInterface } from "_interfaces/walletPayments";
import { WalletTransactionDetailsInterface } from "_interfaces/walletTransaction";

interface Props {
  data: WalletTransactionDetailsInterface | null;
}

const WalletTransactionDetailsTable: React.FC<Props> = ({ data }) => {
  const NAVIGATE = useNavigate();

  const rows: {
    field: keyof NonNullable<
      WalletTransactionDetailsInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "paymentType", headerName: "Payment Type", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "amount", headerName: "Amount" },
      {
        field: "reason", headerName: "Reason",
        renderCell: (v: string) =>
          <Box className='tw-capitalize' >{v}</Box>
      },
      {
        field: "status", headerName: "Status",
        renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box>,
      },
      {
        field: "paymentId", headerName: "Payment", renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => NAVIGATE(`${RouteConstant.PAYMENTS}/${data?.paymentId}`)}>View Payment Details</Button>
      },
      {
        field: "walletPayment", headerName: "Wallet Payment", renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => NAVIGATE(`${RouteConstant.WALLETPAYMENTS}/${data?.walletPayment}`)}>View Wallet Payment Details</Button>
      },
      {
        field: "walletID",
        headerName: "Wallet ID",
      },
      {
        field: "globalActivityId", headerName: "Global Activity", renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => NAVIGATE(`${RouteConstant.GLOBAL_ACTIVITY_LOGS}/${data?.globalActivityId}`)}>View Global Activity Details</Button>
      },
      {
        field: "wallet", headerName: "Wallet", renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => NAVIGATE(`${RouteConstant.WALLETTDETAILS}/${data?.wallet}`)}>View Wallet Details</Button>
      },
      {
        field: "PIN_try",
        headerName: "PIN Try",
      },
      {
        field: "walletTransactionId",
        headerName: "Wallet Transaction ID",
      },
      {
        field: "paidTime",
        headerName: "Paid Time",
        renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
      },
      {
        field: "message",
        headerName: "Message",
      },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default WalletTransactionDetailsTable;
