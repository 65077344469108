import { Box, Card, Typography } from "@mui/material";
import InfluencerDetailsInterface from "_interfaces/influencer/details";
import { toTitleCase } from "functions/helper";
import { IsActiveCell } from "parts/table/customCell";
import DetailsBox from "parts/table/detailsBox";
import React from "react";

interface Props {
  DATA: InfluencerDetailsInterface["influencerDetails"];
}

const InfluencerDetailsTable: React.FC<Props> = ({ DATA }) => {
  const rows: {
    field: keyof NonNullable<InfluencerDetailsInterface["influencerDetails"]>;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
    {
      field: "firstName",
      headerName: "First Name",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "middleName",
      headerName: "Middle Name",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    { field: "influencerID", headerName: "Influencer ID" },
    { field: "email", headerName: "Email" },
    { field: "phoneNumber", headerName: "Phone Number" },
    {
      field: "category",
      headerName: "category",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "region",
      headerName: "Region",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "district",
      headerName: "District",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "county",
      headerName: "County",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "subCounty",
      headerName: "Sub County",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "parish",
      headerName: "Parish",
      renderCell(v) {
        return toTitleCase(v);
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      renderCell(v) {
        return <IsActiveCell status={v ? "Active" : "InActive"} />;
      },
    },
  ];

  return (
    <Card
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box className="tw-flex tw-justify-between tw-items-center">
        <Typography
          color="text.primary"
          className="tw-text-[18px]"
          sx={{ fontWeight: "bold" }}
        >
          {"Influencer Details"}
        </Typography>
      </Box>
      <DetailsBox data={DATA} rows={rows} />
    </Card>
  );
};

export default InfluencerDetailsTable;
