interface BuildBundleProps {
  onNetMinutes: number;
  offNetMinutes: number;
  dataUsageMb: number;
  smsCount: number;
  isApplied: boolean;
}

interface BookingDetailsProps {
  _id?: string;
  MSISDN_id?: string;
  MSISDN?: string;
  phoneNumberSelectedTime?: string;   // ISO 8601 format date string
  status?: 'booked' | 'cancelled' | 'registered' | 'expired';
  name?: string;
  email?: string;
  phoneNumber?: string;
  buildBundle?: BuildBundleProps;
  bookingID?: string;
  bookingBy?: 'self' | 'whole_seller' | 'agent';
  tempBookingId?: string;
  identityType?: 'citizen' | 'tourist' | 'refugee';
  passportNumber?: string;   // Optional
  createdAt?: string;        // ISO 8601 format date string
  bill?: string;
  payment?: string;
  bookingTime?: string;      // ISO 8601 format date string
}

const defaultBookingDetailsProps: BookingDetailsProps = {
  _id: "",
  MSISDN_id: "",
  MSISDN: "",
  phoneNumberSelectedTime: "",
  status: "booked",
  name: "",
  email: "",
  phoneNumber: "",
  buildBundle: {
    onNetMinutes: 0,
    offNetMinutes: 0,
    dataUsageMb: 0,
    smsCount: 0,
    isApplied: false,
  },
  bookingID: "",
  bookingBy: "self",
  tempBookingId: "",
  identityType: "citizen",
  passportNumber: "",
  createdAt: "",
  bill: "",
  payment: "",
  bookingTime: "",
};

export class BookingDetailsModel {
  _id?: string;
  MSISDN_id?: string;
  MSISDN?: string;
  phoneNumberSelectedTime?: string;
  status?: 'booked' | 'cancelled' | 'registered' | 'expired';
  name?: string;
  email?: string;
  phoneNumber?: string;
  buildBundle?: BuildBundleProps;
  bookingID?: string;
  bookingBy?: 'self' | 'whole_seller' | 'agent';
  tempBookingId?: string;
  identityType?: 'citizen' | 'tourist' | 'refugee';
  passportNumber?: string;
  createdAt?: string;
  updatedAt?: string;
  bill?: string;
  payment?: string;
  bookingTime?: string;

  constructor(data: BookingDetailsProps = defaultBookingDetailsProps) {
    this._id = data._id || "";
    this.MSISDN_id = data.MSISDN_id || "";
    this.MSISDN = data.MSISDN || "";
    this.phoneNumberSelectedTime = data.phoneNumberSelectedTime || "";
    this.status = data.status || "booked";
    this.name = data.name || "";
    this.email = data.email || "";
    this.phoneNumber = data.phoneNumber || "";
    this.buildBundle = data.buildBundle || {
      onNetMinutes: 0,
      offNetMinutes: 0,
      dataUsageMb: 0,
      smsCount: 0,
      isApplied: false,
    };
    this.bookingID = data.bookingID || "";
    this.bookingBy = data.bookingBy || "self";
    this.tempBookingId = data.tempBookingId || "";
    this.identityType = data.identityType || "citizen";
    this.passportNumber = data.passportNumber || "";
    this.createdAt = data.createdAt || "";
    this.bill = data.bill || "";
    this.payment = data.payment || "";
    this.bookingTime = data.bookingTime || "";
  }
}

export class UpdateBookingModel {
  name?: string;
  email?: string;
  nationalIdIdNumber?: string;
  passportNumber?: string;
  refugeeDocumentNumber?: string;

  constructor(data: Partial<UpdateBookingModel> = defaultUpdateBookingProps) {
    this.name = data.name || "";
    this.email = data.email || "";
    this.nationalIdIdNumber = data.nationalIdIdNumber || "";
    this.passportNumber = data.passportNumber || "";
    this.refugeeDocumentNumber = data.refugeeDocumentNumber || "";
  }
}

const defaultUpdateBookingProps: UpdateBookingModel = {
  name: "",
  email: "",
  nationalIdIdNumber: "",
  passportNumber: "",
  refugeeDocumentNumber: "",
};

