import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert"; // Adjust import as needed

const useCopyToClipboard = (): {
  copied: boolean;
  copy: (text: string) => Promise<void>;
} => {
  const dispatch = useDispatch(); // Correct casing for `dispatch`
  const [copied, setCopied] = useState<boolean>(false);

  const copy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);

      // Dispatch success alert
      dispatch(setAlert({ message: "Copied to clipboard", type: "success" }));

      // Reset the copied state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);

      // Dispatch error alert
      dispatch(setAlert({ message: "Failed to copy", type: "error" }));
    }
  };

  return { copied, copy };
};

export default useCopyToClipboard;
