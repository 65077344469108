import moment from "moment";
import DetailsBox from "parts/table/detailsBox";
import React, { useState } from "react";
import { Box, Button, Card, Grid } from "@mui/material";
import { AirtelResponse, MomoResponse, TransactionDetailsInterface } from "_interfaces/transactions";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import CustomDrawer from "parts/customDialog/customDrawer";

interface Props {
  data: TransactionDetailsInterface | null;
}

const TransactionDetailsTable: React.FC<Props> = ({ data }) => {
  const NAVIGATE = useNavigate();
  const [openApiResponses, setOpenApiResponses] = useState<boolean>(false);
  const handleToggleApiResponses = () => setOpenApiResponses(!openApiResponses);

  const rows: {
    field: keyof NonNullable<
      TransactionDetailsInterface
    >;
    headerName: string;
    renderCell?: (v: any) => void;
  }[] = [
      { field: "paymentType", headerName: "Payment Type", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      { field: "paymentMethod", headerName: "Payment Method", renderCell: (v: string) => <Box className='tw-capitalize' >{v}</Box> },
      {
        field: "paymentBy", headerName: "Payment By",
        renderCell: (v: string) =>
          <Box className='tw-capitalize' >{v}</Box>
      },
      { field: "totalAmount", headerName: "Total Amount", },
      {
        field: "paymentId", headerName: "Payment", renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={() => NAVIGATE(`${RouteConstant.PAYMENTS}/${data?.paymentId}`)}>View Payment Details</Button>
      },
      {
        field: "momoResponse",
        headerName: "Momo Response",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={handleToggleApiResponses}>View Momo Response</Button>
      },
      {
        field: "airtelResponse",
        headerName: "Airtel Response",
        renderCell: (v: string) =>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: "inherit" }}
            onClick={handleToggleApiResponses}>View Airtel Response</Button>
      },
      {
        field: "transactionId",
        headerName: "Transaction Id",
      },
      {
        field: "paidTime",
        headerName: "Paid Time",
        renderCell: (v: string) => moment(v).format("Do MMM, YYYY h:mm A"),
      },
    ];

  const momoResRows: {
    field: keyof NonNullable<MomoResponse>;
    headerName: string;
    renderCell?: (v: MomoResponse) => void;
  }[] = [
      {
        field: "financialTransactionId",
        headerName: "Financial Transaction ID",
      },
      { field: "referenceId", headerName: "Reference ID" },
      { field: "status", headerName: "Status" }
    ];
  const airtelResRows: {
    field: keyof NonNullable<AirtelResponse>;
    headerName: string;
    renderCell?: (v: AirtelResponse) => void;
  }[] = [
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "airtel_money_id",
        headerName: "Airtel Money ID",
      },
      { field: "message", headerName: "Message" },
      { field: "status", headerName: "Status" },
    ];

  return (
    <>
      <Card sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "20px" }}>
        {data?.id ? (
          <>
            <DetailsBox data={data} rows={rows} />
          </>
        ) : (
          <></>
        )}
      </Card>
      <CustomDrawer
        title={data?.momoResponse ? `Momo Payloads` : `Airtel Payloads`}
        open={openApiResponses}
        onClose={handleToggleApiResponses}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data?.momoResponse && <DetailsBox data={data?.momoResponse} rows={momoResRows} fullWidth />}
            {data?.airtelResponse && <DetailsBox data={data?.airtelResponse} rows={airtelResRows} fullWidth />}
          </Grid>
        </Grid>
      </CustomDrawer>
    </>
  );
};

export default TransactionDetailsTable;
