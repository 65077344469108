import DataGridTableInterface from "_interfaces/_common/dataGridTable";

export interface PaymentFilterModelProps {
  status?: 'pending' | 'paid' | 'failed' | 'cancelled' | '';
  paymentMethod?: 'mobileMoney' | 'creditCard' | '';
  paymentBy?: 'momo' | 'airtelMoney' | 'interSwitch' | 'wallet' | '';
  paymentType?: 'credit' | 'debit' | 'refund' | '';
  paymentFor?: 'customer_registration' | 'airtime_recharge' | 'bundle_recharge' | 'book_phone_number' | 'wallet_recharge' | '';
  fromAmount?: string | null;  // Amount range filters
  toAmount?: string | null;
  fromDate?: string | Date | null;  // YYYY-MM-DD format for date filters
  toDate?: string | Date | null;    // YYYY-MM-DD
}

const defaultRechargeFilterModelProps: PaymentFilterModelProps = {
  status: '',                 // Default status value
  paymentMethod: '',           // Default payment method
  paymentBy: '',               // Default payment by
  paymentType: '',             // Default payment type
  paymentFor: '',              // Default payment purpose
  fromAmount: null,
  toAmount: null,
  fromDate: null,
  toDate: null,
};

export class PaymentFilterModel {
  status?: 'pending' | 'paid' | 'failed' | 'cancelled' | '';
  paymentMethod?: 'mobileMoney' | 'creditCard' | '';
  paymentBy?: 'momo' | 'airtelMoney' | 'interSwitch' | 'wallet' | '';
  paymentType?: 'credit' | 'debit' | 'refund' | '';
  paymentFor?: 'customer_registration' | 'airtime_recharge' | 'bundle_recharge' | 'book_phone_number' | 'wallet_recharge' | '';
  fromAmount?: string | null;
  toAmount?: string | null;
  fromDate?: string | Date | null;
  toDate?: string | Date | null;

  constructor(data: PaymentFilterModelProps = defaultRechargeFilterModelProps) {
    this.status = data.status || '';
    this.paymentMethod = data.paymentMethod || '';
    this.paymentBy = data.paymentBy || '';
    this.paymentType = data.paymentType || '';
    this.paymentFor = data.paymentFor || '';
    this.fromAmount = data.fromAmount || null;
    this.toAmount = data.toAmount || null;
    this.fromDate = data.fromDate || null;
    this.toDate = data.toDate || null;
  }
}
