import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import {
  ALLOWED_INFLUENCER_CATEGORY,
} from "_interfaces/functions/http-requests/influencer";
import axios from "axios";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import {
  filterNonNullValues,
} from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import {
  GetAllCountyByDistrictName,
  GetAllDistrictByRegionName,
  GetAllRegions,
  GetAllSubCountyByCountyName,
} from "functions/http-requests/address";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "navigation/constant";
import { validateTeam } from "./formValidator";
import { CreateTeamErrorModel, CreateTeamModel, CreateTeamModelProps } from "_models/data/team/data.createteam.model";
import { CreateTeam } from "functions/http-requests/teams";
import { CreateTeamProps } from "_interfaces/functions/http-requests/teams";
import Password from "component/_common/forms/password";

interface InputListProps extends FormInputProps {
  name:
  | "teamName"
  | "teamLocation"
  | "managerName"
  | "email"
  | "managerPhone"
  | "password"
  | "region"
  | "district"
  | "county"
  | "subCounty"
  | "parish"
  | "acknowledgementForm";
}

const CreateTeamForm = () => {
  const emptyTeam: CreateTeamModelProps = {
    teamName: "",
    teamLocation: "",
    managerName: "",
    email: "",
    managerPhone: "",
    password: "",
    region: "",
    district: "",
    county: "",
    subCounty: "",
    parish: "",
    acknowledgementForm: "",
  }
  const Dispatch = useDispatch();

  const [state, setState] = useState<CreateTeamModel>(emptyTeam);
  const [errors, setErrors] = useState<CreateTeamErrorModel>(
    new CreateTeamErrorModel()
  );

  const [allRegions, setAllRegions] = useState<AutoCompleteOptionsProps[]>([]);
  const [allDistricts, setAllDistricts] = useState<AutoCompleteOptionsProps[]>(
    []
  );
  const [allCounty, setAllCounty] = useState<AutoCompleteOptionsProps[]>([]);
  const [allSubCounty, setAllSubCounty] = useState<AutoCompleteOptionsProps[]>(
    []
  );
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
          (e: { value: string | number; id: string | number }) =>
            e?.value || e?.id
        )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: CreateTeamProps) => {
    Dispatch(toggleLoading(true));
    CreateTeam({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          navigate(RouteConstant.TEAM);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };


  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateTeam(state);

    if (ValidateStep?.valid) {
      let DATA: CreateTeamProps["DATA"] = {
        teamName: state?.teamName || undefined,
        teamLocation: state?.teamLocation || undefined,
        managerName: state?.managerName || undefined,
        managerEmail: state?.email || undefined,
        managerPhone: state?.managerPhone || undefined,
        managerPassword: state?.password || "",
        region: state?.region || undefined,
        district: state?.district || undefined,
        county: state?.county || undefined,
        subCounty: state?.subCounty || undefined,
        parish: state?.parish || undefined,
        acknowledgementForm: state?.acknowledgementForm || undefined
      };
      DATA = filterNonNullValues(DATA);
      handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };


  // REGIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      GetAllRegions()
        .then((res) => {
          let DATA: any = res?.data?.data;
          let options: AutoCompleteOptionsProps[] = DATA?.map((item: any) => ({
            id: item?.name,
            value: item?.name,
            title: item?.name,
            isActive: true,
          }));
          setAllRegions(options);
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  // DISTRICTS
  useEffect(() => {
    if (state?.region) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.region) {
          GetAllDistrictByRegionName(state.region)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllDistricts(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.region]);

  // COUNTY
  useEffect(() => {
    if (state?.district) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.district) {
          GetAllCountyByDistrictName(state.district)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllCounty(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.district]);

  // SUB COUNTY
  useEffect(() => {
    if (state?.county) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.county) {
          GetAllSubCountyByCountyName(state.county)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllSubCounty(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.county]);


  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "teamName",
        label: "Team Name",
        placeholder: "Enter Team Name",
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "teamLocation",
        label: "Team Location",
        placeholder: "Enter Team Location",
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "managerName",
        label: "Manager Name",
        placeholder: "Enter Manager Name",
        enabled: true,
        grid: 6,
      },
      {
        type: "email",
        name: "email",
        label: "Manager Email",
        placeholder: "Enter Manager Email",
        enabled: true,
        grid: 6,
      },
      {
        type: "number",
        name: "managerPhone",
        label: "Manager Phone Number",
        placeholder: "Enter Manager Phone Number",
        enabled: true,
        grid: 6,
      },
      {
        type: "password",
        name: "password",
        label: "Manager Password",
        placeholder: "Enter Manager Password",
        autoComplete: "new-password",
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "region",
        label: "Region",
        placeholder: "Select Region",
        options: allRegions,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "district",
        label: "District",
        placeholder: "Select District",
        options: allDistricts,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "county",
        label: "County",
        placeholder: "Enter County",
        options: allCounty,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "subCounty",
        label: "Sub County",
        placeholder: "Enter Sub County",
        options: allSubCounty,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "parish",
        label: "Parish",
        placeholder: "Enter Parish",
        enabled: true,
        grid: 6,
      },
      {
        type: "file",
        name: "acknowledgementForm",
        label: "Chhose an acknowledgement file",
        minRows: 5,
        enabled: true,
      },
    ],
    [
      allRegions,
      allDistricts,
      allCounty,
      allSubCounty,
    ]
  );
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      managerEmail: "",
      managerPassword: "",
    }));
  }, []);

  return (
    <>
      <Box
        component="form"
        id="crete_edit_influencer_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                multiline,
                enabled,
                helperText
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                      type === "email" ||
                      type === "number" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof CreateTeamModel]}
                          errorText={errors[name as keyof CreateTeamErrorModel]}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          InputProps={InputProps}
                          multiline={multiline}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof CreateTeamModel]
                          }
                          errorText={errors[name as keyof CreateTeamErrorModel]}
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : type === "password" ? (
                      <Grid item xs={12} md={6} lg={grid || 6}>
                        <Password
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof CreateTeamModel]}
                          errorText={
                            errors[name as keyof CreateTeamErrorModel]
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          isHideShow
                        />
                      </Grid>
                    ) : type === "file" ? (
                      <Grid item xs={12} sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        <TextField
                          name={name}
                          sx={{ display: "none" }}
                          label={label}
                          id={`file-upload-${name}`}
                          type={type}
                          inputProps={{
                            accept: ".jpg, .pdf", // Restrict to JPG files only
                          }}
                          onChange={(e: any) => {
                            const file = e.target.files?.[0];
                            if (file) {
                              setState((prev) => ({
                                ...prev,
                                [name]: file,
                              }));
                            }
                            setErrors((prev) => ({
                              ...prev,
                              [name]: "",
                            }));
                          }}
                          disabled={disabled}
                          helperText={helperText}
                        />
                        <label htmlFor={`file-upload-${name}`}>
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            sx={{ width: "18rem" }}
                          >
                            Select Acknowledgement File
                          </Button>
                          {
                            <Typography sx={{ color: "#c60606", fontSize: "14.2px" }}>
                              {errors[
                                name as keyof CreateTeamErrorModel
                              ]}
                            </Typography>
                          }
                        </label>
                        {state?.acknowledgementForm && (
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary"
                              sx={{
                                width: "25.6rem",
                                wordBreak: "break-word"
                              }}>
                              Selected file: {state?.acknowledgementForm?.name}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              Create Team
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CreateTeamForm;
