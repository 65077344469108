import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import {
  ALLOWED_INFLUENCER_CATEGORY,
  InfluencerCreateProps,
  InfluencerUpdateProps,
} from "_interfaces/functions/http-requests/influencer";
import { InfluencerModel } from "_models/data/influencer/data.influencer.model";
import { InfluencerErrorModel } from "_models/error/influencer/error.influencer.model";
import axios from "axios";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import Text from "component/_common/forms/text";
import { AppStatusCode } from "config/appStatusCode";
import {
  filterNonNullValues,
  generateAutocompleteOptions,
} from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import {
  GetAllCountyByDistrictName,
  GetAllDistrictByRegionName,
  GetAllRegions,
  GetAllSubCountyByCountyName,
} from "functions/http-requests/address";
import { GetAllCoupon } from "functions/http-requests/coupon";
import {
  CreateInfluencer,
  GetInfluencerById,
  UpdateInfluencer,
} from "functions/http-requests/influencer";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/reducers/alert";
import { toggleLoading } from "state/reducers/loading";
import { validateInfluencer } from "./formValidator";

interface InputListProps extends FormInputProps {
  name:
    | "email"
    | "phoneNumber"
    | "category"
    | "couponId"
    | "firstName"
    | "middleName"
    | "lastName"
    | "region"
    | "district"
    | "county"
    | "subCounty"
    | "parish";
}

interface Props {
  editId?: string | null;
  handleRefresh: () => void;
  onClose: () => void;
}

const InfluencerForm: React.FC<Props> = ({
  editId,
  handleRefresh,
  onClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<InfluencerModel>(new InfluencerModel());
  const [errors, setErrors] = useState<InfluencerErrorModel>(
    new InfluencerErrorModel()
  );

  const [allCoupon, setAllCoupon] = useState<AutoCompleteOptionsProps[]>([]);

  const [allRegions, setAllRegions] = useState<AutoCompleteOptionsProps[]>([]);
  const [allDistricts, setAllDistricts] = useState<AutoCompleteOptionsProps[]>(
    []
  );
  const [allCounty, setAllCounty] = useState<AutoCompleteOptionsProps[]>([]);
  const [allSubCounty, setAllSubCounty] = useState<AutoCompleteOptionsProps[]>(
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleCreate = ({ DATA }: InfluencerCreateProps) => {
    Dispatch(toggleLoading(true));
    CreateInfluencer({ DATA })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_created ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleUpdate = ({ DATA, PARAMS }: InfluencerUpdateProps) => {
    Dispatch(toggleLoading(true));
    UpdateInfluencer({ DATA, PARAMS })
      .then((res) => {
        const data = res?.data;
        Dispatch(setAlert({ type: data?.level, message: data?.message }));
        if (
          data?.statusCode === AppStatusCode.api_updated ||
          data?.statusCode === AppStatusCode.api_success
        ) {
          onClose();
          handleRefresh();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateInfluencer(state, !!editId);

    if (ValidateStep?.valid) {
      let DATA: InfluencerCreateProps["DATA"] = {
        email: state?.email || undefined,
        phoneNumber: state?.phoneNumber || undefined,
        category: state?.category || undefined,
        couponId: !editId && state?.couponId ? state?.couponId : undefined,
        firstName: state?.firstName || undefined,
        middleName: state?.middleName || undefined,
        lastName: state?.lastName || undefined,
        region: state?.region || undefined,
        district: state?.district || undefined,
        county: state?.county || undefined,
        subCounty: state?.subCounty || undefined,
        parish: state?.parish || undefined,
      };
      DATA = filterNonNullValues(DATA);
      if (editId) {
        handleUpdate({
          DATA: DATA,
          PARAMS: {
            id: editId,
          },
        });
      } else handleCreate({ DATA });
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  // GET BY ID
  const handleFetchById = (ID: string) => {
    Dispatch(toggleLoading(true));
    GetInfluencerById(ID)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          let DATA: any = data?.data;

          setState({
            email: DATA?.email || "",
            phoneNumber: DATA?.phoneNumber || "",
            category: DATA?.category || "",
            firstName: DATA?.firstName || "",
            middleName: DATA?.middleName || "",
            lastName: DATA?.lastName || "",
            region: DATA?.region || "",
            district: DATA?.district || "",
            county: DATA?.county || "",
            subCounty: DATA?.subCounty || "",
            parish: DATA?.parish || "",
          });
        } else {
          Dispatch(setAlert({ type: "error", message: data?.message }));
          setState(new InfluencerModel());
          onClose();
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  useEffect(() => {
    if (editId) {
      let fetchById: (() => void) | null = () => {
        handleFetchById(editId);
      };
      fetchById();
      return () => {
        fetchById = null;
      };
    }
  }, [editId]);

  // COUPONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      GetAllCoupon({ DATA: { pageNumber: 1, pageSize: 999 } })
        .then((res) => {
          let DATA: any = res?.data?.data;
          let options: AutoCompleteOptionsProps[] = DATA?.filter(
            (item: any) => !item?.influencerDetails
          )?.map((item: any) => ({
            id: item?._id,
            value: item?._id,
            title: item?.couponCode,
            isActive: true,
          }));
          setAllCoupon(options);
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  // REGIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      GetAllRegions()
        .then((res) => {
          let DATA: any = res?.data?.data;
          let options: AutoCompleteOptionsProps[] = DATA?.map((item: any) => ({
            id: item?.name,
            value: item?.name,
            title: item?.name,
            isActive: true,
          }));
          setAllRegions(options);
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  // DISTRICTS
  useEffect(() => {
    if (state?.region) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.region) {
          GetAllDistrictByRegionName(state.region)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllDistricts(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.region]);

  // COUNTY
  useEffect(() => {
    if (state?.district) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.district) {
          GetAllCountyByDistrictName(state.district)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllCounty(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.district]);

  // SUB COUNTY
  useEffect(() => {
    if (state?.county) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));

        if (state.county) {
          GetAllSubCountyByCountyName(state.county)
            .then((res) => {
              let DATA: any = res?.data?.data;
              let options: AutoCompleteOptionsProps[] = DATA?.map(
                (item: any) => ({
                  id: item?.name,
                  value: item?.name,
                  title: item?.name,
                  isActive: true,
                })
              );
              setAllSubCounty(options);
            })
            .catch((error) => {
              if (!axios.isCancel(error))
                Dispatch(
                  setAlert({ type: "error", message: HTTP_ERROR(error) })
                );
            })
            .finally(() => Dispatch(toggleLoading(false)));
        }
      };
      fetchList();
      return () => {
        fetchList = null;
      };
    }
  }, [Dispatch, state.county]);

  const inputList: InputListProps[] = useMemo(
    () => [
      {
        type: "text",
        name: "firstName",
        label: "First Name",
        placeholder: "Enter First Name",
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "middleName",
        label: "Middle Name",
        placeholder: "Enter Middle Name",
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "lastName",
        label: "Last Name",
        placeholder: "Enter Last Name",
        enabled: true,
        grid: 6,
      },
      {
        type: "email",
        name: "email",
        label: "Email",
        placeholder: "Enter Email",
        enabled: true,
        grid: 6,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Phone Number",
        placeholder: "Enter Phone Number",
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "category",
        label: "Category",
        placeholder: "Select Category",
        options: generateAutocompleteOptions(ALLOWED_INFLUENCER_CATEGORY),
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "couponId",
        label: "Coupon",
        placeholder: "Select Coupon",
        options: allCoupon,
        multiple: false,
        enabled: !editId,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "region",
        label: "Region",
        placeholder: "Select Region",
        options: allRegions,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "district",
        label: "District",
        placeholder: "Select District",
        options: allDistricts,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "county",
        label: "County",
        placeholder: "Enter County",
        options: allCounty,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "autoComplete",
        name: "subCounty",
        label: "Sub County",
        placeholder: "Enter Sub County",
        options: allSubCounty,
        multiple: false,
        enabled: true,
        grid: 6,
      },
      {
        type: "text",
        name: "parish",
        label: "Parish",
        placeholder: "Enter Parish",
        enabled: true,
        grid: 6,
      },
    ],
    [
      editId,
      ALLOWED_INFLUENCER_CATEGORY,
      allCoupon,
      allRegions,
      allDistricts,
      allCounty,
      allSubCounty,
    ]
  );

  return (
    <>
      <Box
        component="form"
        id="crete_edit_influencer_form"
        onSubmit={handleSubmit}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          {inputList.map(
            (
              {
                type,
                name,
                label,
                placeholder,
                grid,
                autoComplete,
                disabled,
                options,
                multiple,
                InputProps,
                multiline,
                enabled,
              },
              index
            ) => (
              <Fragment key={index}>
                {enabled ? (
                  <>
                    {type === "text" ||
                    type === "email" ||
                    type === "number" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <Text
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          autoComplete={autoComplete || "off"}
                          value={state[name as keyof InfluencerModel]}
                          errorText={errors[name as keyof InfluencerErrorModel]}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          disabled={disabled}
                          InputProps={InputProps}
                          multiline={multiline}
                        />
                      </Grid>
                    ) : type === "autoComplete" ? (
                      <Grid item xs={12} sm={6} md={grid || 12}>
                        <InputAutoComplete
                          name={name}
                          options={options || []}
                          label={label}
                          placeholder={placeholder}
                          onChange={(e, v, m) =>
                            handleAutoComplete(e, v, name, m)
                          }
                          value={
                            options &&
                            options.length &&
                            state?.[name as keyof InfluencerModel]
                          }
                          errorText={errors[name as keyof InfluencerErrorModel]}
                          onFocus={handleFocus}
                          multiple={multiple}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          )}

          <Grid item xs={12} order={7}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
              className="tw-capitalize tw-py-[12px]"
            >
              {editId ? "Save Changes" : "Add New Influencer"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InfluencerForm;
