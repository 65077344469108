import { ThirdPartyLogDetailsData } from "_interfaces/log_alerts";
import { AppStatusCode } from "config/appStatusCode";
import { GetThirdPartyApiAlertDetails } from "functions/http-requests/log_alerts";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/reducers/loading";
import ThirdPartyLogAlertDetailsTable from "./third_party_log_alert_details_table";

interface Props {
    id: string;
}

const ThirdPartyApiAlertDetailsLayout: React.FC<Props> = ({ id }) => {
    const [details, setDetails] = useState<ThirdPartyLogDetailsData | null>(null);
    const Dispatch = useDispatch();

    let handleFetchById = (ID: string) => {
        Dispatch(toggleLoading(true));
        GetThirdPartyApiAlertDetails(ID)
            .then((res) => {
                const data = res?.data;
                if (
                    data?.statusCode === AppStatusCode.api_success ||
                    data?.statusCode === AppStatusCode.api_updated
                ) {
                    const DATA = data?.data;
                    setDetails({
                        activity: DATA?.activity,
                        activityId: DATA?.activityId,
                        alertLevel: DATA?.alertLevel,
                        level: DATA?.level,
                        message: DATA?.message,
                        moduleName: DATA?.apiModule,
                        createdAt: DATA?.createdAt,
                        error: DATA?.error,
                        id: DATA?._id,
                        signature:DATA?.signature,
                        responses:DATA?.responses,
                        otherInfo:DATA?.otherInfo,
                        actionNeeded:DATA?.actionNeeded
                    })
                } else {
                    setDetails(null)
                }
            })
            .catch((error) => {
                setDetails(null);
            })
            .finally(() => {
                Dispatch(toggleLoading(false));
            });
    };

    useEffect(() => {
        handleFetchById(id);
    }, [])

    return (
        <>
            <ThirdPartyLogAlertDetailsTable data={details} />
        </>
    )
}

export default ThirdPartyApiAlertDetailsLayout;