import { WalletPaymentFilterProps } from "_interfaces/functions/http-requests/walletPayments";
import { WALLET_PAYMENT_DETAILS, WALLET_PAYMENT_LIST } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetWalletPaymentList = ({ DATA }: WalletPaymentFilterProps) => {
    return HTTP({
        Method: "POST",
        Url: WALLET_PAYMENT_LIST,
        Data: DATA
    });
};
export const GetWalletPaymentDetails = (ID: string) => {
    return HTTP({
        Method: "GET",
        Url: WALLET_PAYMENT_DETAILS(ID),
    });
};